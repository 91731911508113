@charset "UTF-8";
/*
Template Name: Shreyu - Responsive Bootstrap 4 Admin Dashboard
Author: CoderThemes
Version: 1.0.0
Website: https://coderthemes.com/
Contact: support@coderthemes.com
File: Main Css File
*/
/* =======================
    TABLE OF CSS
    1. Fonts 
    2. General 
    3. Left Menu 
    4. Topbar 
    5. Page Head 
    6. Footer 
    7. Horizontal Nav 
    8. Right Sidebar 
    9. Layouts 
    10. Helper 
    11. Social 
    12. Widgets 
    13. Custom Checkbox 
    14. Custom Radio 
    15. Print 
    16. Preloader 
    17. Plugins 
    18. Components Demo 
    19. Tasks 
    20. Email 
    21. Timeline 
    22. Extra Pages 
    23. Authentication
    24. Icons 
   ======================= */
/* 1. Fonts */
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900&display=swap");
/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/bootstrap/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
@import '~@fullcalendar/list/main.css';
@import '~vue-multiselect/dist/vue-multiselect.min.css';
@import '~flatpickr/dist/flatpickr.css';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
:root {
  --blue: #5369f8;
  --indigo: #564ab1;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #ea4335;
  --orange: #f77e53;
  --yellow: #ffbe0b;
  --green: #43d39e;
  --teal: #1ce1ac;
  --cyan: #25c2e3;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #4284f4;
  --secondary: #34a853;
  --success: #34a853;
  --info: #25c2e3;
  --warning: #ffbe0b;
  --danger: #ea4335;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Nunito Sans", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4b4b5a;
  text-align: left;
  background-color: #edeff4;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #4284f4;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0d5add;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.1875rem;
}

h2, .h2 {
  font-size: 1.75rem;
}

h3, .h3 {
  font-size: 1.53125rem;
}

h4, .h4 {
  font-size: 1.3125rem;
}

h5, .h5 {
  font-size: 1.09375rem;
}

h6, .h6 {
  font-size: 0.875rem;
}

.lead {
  font-size: 1.09375rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #edeff4;
  border: 1px solid #e2e7f1;
  border-radius: 0.3rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #4b4b5a;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #f6f6f7;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #f6f6f7;
}
.table tbody + tbody {
  border-top: 2px solid #f6f6f7;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #f6f6f7;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #f6f6f7;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f6f6f7;
}

.table-hover tbody tr:hover {
  color: #4b4b5a;
  background-color: #f6f6f7;
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #caddfc;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #9dbff9;
}

.table-hover .table-primary:hover {
  background-color: #b2cefb;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #b2cefb;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #c6e7cf;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #95d2a6;
}

.table-hover .table-secondary:hover {
  background-color: #b4dfc0;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #b4dfc0;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c6e7cf;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #95d2a6;
}

.table-hover .table-success:hover {
  background-color: #b4dfc0;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b4dfc0;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #c2eef7;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #8edff0;
}

.table-hover .table-info:hover {
  background-color: #abe8f4;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abe8f4;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffedbb;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdd80;
}

.table-hover .table-warning:hover {
  background-color: #ffe6a2;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe6a2;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f9cac6;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f49d96;
}

.table-hover .table-danger:hover {
  background-color: #f7b5af;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f7b5af;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: #f6f6f7;
}

.table-hover .table-active:hover {
  background-color: #e9e9eb;
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: #e9e9eb;
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #4b4b5a;
  background-color: #f8f9fa;
  border-color: #f6f6f7;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc( 1.5em + 1rem + 2px );
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4b4b5a;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e2e7f1;
  border-radius: 0.3rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #4b4b5a;
}
.form-control:focus {
  color: #4b4b5a;
  background-color: #fff;
  border-color: #4284f4;
  outline: 0;
  box-shadow: none;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #fff;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #4b4b5a;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.09375rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.765625rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.5rem 0;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #4b4b5a;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc( 1.5em + 0.5rem + 2px );
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc( 1.5em + 1rem + 2px );
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.5rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #34a853;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.3rem 0.6rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(52, 168, 83, 0.9);
  border-radius: 0.3rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #34a853;
  padding-right: calc( 1.5em + 1rem );
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2334a853' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc( 0.375em + 0.25rem ) center;
  background-size: calc( 0.75em + 0.5rem ) calc( 0.75em + 0.5rem );
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #34a853;
  box-shadow: 0 0 0 0.15rem rgba(52, 168, 83, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc( 1.5em + 1rem );
  background-position: top calc( 0.375em + 0.25rem ) right calc( 0.375em + 0.25rem );
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #34a853;
  padding-right: calc( (1em + 1rem) * 3 / 4 + 1.75rem );
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2334a853' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc( 0.75em + 0.5rem ) calc( 0.75em + 0.5rem ) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #34a853;
  box-shadow: 0 0 0 0.15rem rgba(52, 168, 83, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #34a853;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #34a853;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #34a853;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #48c76a;
  background-color: #48c76a;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.15rem rgba(52, 168, 83, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #34a853;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #34a853;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #34a853;
  box-shadow: 0 0 0 0.15rem rgba(52, 168, 83, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #ea4335;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.3rem 0.6rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(234, 67, 53, 0.9);
  border-radius: 0.3rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #ea4335;
  padding-right: calc( 1.5em + 1rem );
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ea4335' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23ea4335' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: right calc( 0.375em + 0.25rem ) center;
  background-size: calc( 0.75em + 0.5rem ) calc( 0.75em + 0.5rem );
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #ea4335;
  box-shadow: 0 0 0 0.15rem rgba(234, 67, 53, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc( 1.5em + 1rem );
  background-position: top calc( 0.375em + 0.25rem ) right calc( 0.375em + 0.25rem );
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #ea4335;
  padding-right: calc( (1em + 1rem) * 3 / 4 + 1.75rem );
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ea4335' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23ea4335' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") center right 1.75rem/calc( 0.75em + 0.5rem ) calc( 0.75em + 0.5rem ) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #ea4335;
  box-shadow: 0 0 0 0.15rem rgba(234, 67, 53, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #ea4335;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #ea4335;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #ea4335;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ef6e63;
  background-color: #ef6e63;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.15rem rgba(234, 67, 53, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ea4335;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #ea4335;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #ea4335;
  box-shadow: 0 0 0 0.15rem rgba(234, 67, 53, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #4b4b5a;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.3rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #4b4b5a;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.15rem rgba(66, 132, 244, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #4284f4;
  border-color: #4284f4;
}
.btn-primary:hover {
  color: #fff;
  background-color: #1e6cf2;
  border-color: #1265f1;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #1e6cf2;
  border-color: #1265f1;
  box-shadow: 0 0 0 0.15rem rgba(94, 150, 246, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #4284f4;
  border-color: #4284f4;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #1265f1;
  border-color: #0e5fe9;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(94, 150, 246, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #34a853;
  border-color: #34a853;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #2b8b45;
  border-color: #288140;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #2b8b45;
  border-color: #288140;
  box-shadow: 0 0 0 0.15rem rgba(82, 181, 109, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #34a853;
  border-color: #34a853;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #288140;
  border-color: #25773b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(82, 181, 109, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #34a853;
  border-color: #34a853;
}
.btn-success:hover {
  color: #fff;
  background-color: #2b8b45;
  border-color: #288140;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #2b8b45;
  border-color: #288140;
  box-shadow: 0 0 0 0.15rem rgba(82, 181, 109, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #34a853;
  border-color: #34a853;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #288140;
  border-color: #25773b;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(82, 181, 109, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #25c2e3;
  border-color: #25c2e3;
}
.btn-info:hover {
  color: #fff;
  background-color: #1aaac8;
  border-color: #18a0bd;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #1aaac8;
  border-color: #18a0bd;
  box-shadow: 0 0 0 0.15rem rgba(70, 203, 231, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #25c2e3;
  border-color: #25c2e3;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #18a0bd;
  border-color: #1797b1;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(70, 203, 231, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffbe0b;
  border-color: #ffbe0b;
}
.btn-warning:hover {
  color: #fff;
  background-color: #e4a700;
  border-color: #d79e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #fff;
  background-color: #e4a700;
  border-color: #d79e00;
  box-shadow: 0 0 0 0.15rem rgba(222, 167, 16, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffbe0b;
  border-color: #ffbe0b;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #d79e00;
  border-color: #ca9400;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(222, 167, 16, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #ea4335;
  border-color: #ea4335;
}
.btn-danger:hover {
  color: #fff;
  background-color: #e12717;
  border-color: #d62516;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #e12717;
  border-color: #d62516;
  box-shadow: 0 0 0 0.15rem rgba(237, 95, 83, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #ea4335;
  border-color: #ea4335;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #d62516;
  border-color: #ca2315;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(237, 95, 83, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.15rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.15rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #4284f4;
  border-color: #4284f4;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #4284f4;
  border-color: #4284f4;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.15rem rgba(66, 132, 244, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #4284f4;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #4284f4;
  border-color: #4284f4;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(66, 132, 244, 0.5);
}

.btn-outline-secondary {
  color: #34a853;
  border-color: #34a853;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #34a853;
  border-color: #34a853;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.15rem rgba(52, 168, 83, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #34a853;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #34a853;
  border-color: #34a853;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(52, 168, 83, 0.5);
}

.btn-outline-success {
  color: #34a853;
  border-color: #34a853;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #34a853;
  border-color: #34a853;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.15rem rgba(52, 168, 83, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #34a853;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #34a853;
  border-color: #34a853;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(52, 168, 83, 0.5);
}

.btn-outline-info {
  color: #25c2e3;
  border-color: #25c2e3;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #25c2e3;
  border-color: #25c2e3;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.15rem rgba(37, 194, 227, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #25c2e3;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #25c2e3;
  border-color: #25c2e3;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(37, 194, 227, 0.5);
}

.btn-outline-warning {
  color: #ffbe0b;
  border-color: #ffbe0b;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffbe0b;
  border-color: #ffbe0b;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.15rem rgba(255, 190, 11, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffbe0b;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffbe0b;
  border-color: #ffbe0b;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(255, 190, 11, 0.5);
}

.btn-outline-danger {
  color: #ea4335;
  border-color: #ea4335;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #ea4335;
  border-color: #ea4335;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.15rem rgba(234, 67, 53, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #ea4335;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #ea4335;
  border-color: #ea4335;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(234, 67, 53, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.15rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.15rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #4284f4;
  text-decoration: none;
}
.btn-link:hover {
  color: #0d5add;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.5rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #4b4b5a;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0.2rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #f6f6f7;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.35rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active, .dropdown-item:active {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.765625rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.35rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4b4b5a;
  text-align: center;
  white-space: nowrap;
  background-color: #f6f6f7;
  border: 1px solid #e2e7f1;
  border-radius: 0.3rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc( 1.5em + 1rem + 2px );
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.5rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc( 1.5em + 0.5rem + 2px );
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.3125rem;
  padding-left: 2.5rem;
  color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 2rem;
  height: 1.65625rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #4284f4;
  background-color: #4284f4;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #4284f4;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #ebf2fe;
  border-color: #ebf2fe;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #fff;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: -0.34375rem;
  left: -2.5rem;
  display: block;
  width: 2rem;
  height: 2rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: -0.34375rem;
  left: -2.5rem;
  display: block;
  width: 2rem;
  height: 2rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.3rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #4284f4;
  background-color: #4284f4;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(66, 132, 244, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(66, 132, 244, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(66, 132, 244, 0.5);
}

.custom-switch {
  padding-left: 4rem;
}
.custom-switch .custom-control-label::before {
  left: -4rem;
  width: 3.5rem;
  pointer-events: all;
  border-radius: 1rem;
}
.custom-switch .custom-control-label::after {
  top: calc(-0.34375rem + 2px);
  left: calc(-4rem + 2px);
  width: calc( 2rem - 4px );
  height: calc( 2rem - 4px );
  background-color: #adb5bd;
  border-radius: 1rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(1.5rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(66, 132, 244, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc( 1.5em + 1rem + 2px );
  padding: 0.5rem 1.75rem 0.5rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4b4b5a;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #e2e7f1;
  border-radius: 0.3rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #4284f4;
  outline: 0;
  box-shadow: 0 0 0 0.15rem rgba(66, 132, 244, 0.25);
}
.custom-select:focus::-ms-value {
  color: #4b4b5a;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #f6f6f7;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #4b4b5a;
}

.custom-select-sm {
  height: calc( 1.5em + 0.5rem + 2px );
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.765625rem;
}

.custom-select-lg {
  height: calc( 1.5em + 1rem + 2px );
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.09375rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc( 1.5em + 1rem + 2px );
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc( 1.5em + 1rem + 2px );
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #4284f4;
  box-shadow: none;
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #fff;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc( 1.5em + 1rem + 2px );
  padding: 0.5rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #4b4b5a;
  background-color: #fff;
  border: 1px solid #e2e7f1;
  border-radius: 0.3rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc( 1.5em + 1rem );
  padding: 0.5rem 0.75rem;
  line-height: 1.5;
  color: #4b4b5a;
  content: "Browse";
  background-color: #f6f6f7;
  border-left: inherit;
  border-radius: 0 0.3rem 0.3rem 0;
}

.custom-range {
  width: 100%;
  height: 1.3rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #edeff4, none;
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #edeff4, none;
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #edeff4, none;
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #4284f4;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #ebf2fe;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e2e7f1;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #4284f4;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #ebf2fe;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e2e7f1;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.15rem;
  margin-left: 0.15rem;
  background-color: #4284f4;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #ebf2fe;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #e2e7f1;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #e2e7f1;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid "transparent";
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.3rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #4284f4;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3359375rem;
  padding-bottom: 0.3359375rem;
  margin-right: 1rem;
  font-size: 1.09375rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.09375rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.3rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.5rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.5rem - 0);
  border-top-right-radius: calc(0.5rem - 0);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.5rem - 0);
  border-bottom-left-radius: calc(0.5rem - 0);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #f6f6f7;
  border-bottom: 0 solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.5rem - 0) calc(0.5rem - 0) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: #f6f6f7;
  border-top: 0 solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.5rem - 0) calc(0.5rem - 0);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.5rem - 0);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.5rem - 0);
  border-top-right-radius: calc(0.5rem - 0);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.5rem - 0);
  border-bottom-left-radius: calc(0.5rem - 0);
}

.card-deck .card {
  margin-bottom: 12px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -12px;
    margin-left: -12px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 12px;
    margin-bottom: 0;
    margin-left: 12px;
  }
}

.card-group > .card {
  margin-bottom: 12px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #f6f6f7;
  border-radius: 0.3rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.3rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.3rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.3rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #4284f4;
  background-color: #fff;
  border: 1px solid #e2e7f1;
}
.page-link:hover {
  z-index: 2;
  color: #0d5add;
  text-decoration: none;
  background-color: #f6f6f7;
  border-color: #e2e7f1;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.15rem rgba(66, 132, 244, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4284f4;
  border-color: #4284f4;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #e2e7f1;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.09375rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.3em 0.5em;
  font-size: 82%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.2rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #4284f4;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #1265f1;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.15rem rgba(66, 132, 244, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #34a853;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #288140;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.15rem rgba(52, 168, 83, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #34a853;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #288140;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.15rem rgba(52, 168, 83, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #25c2e3;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #18a0bd;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.15rem rgba(37, 194, 227, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffbe0b;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d79e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.15rem rgba(255, 190, 11, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #ea4335;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #d62516;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.15rem rgba(234, 67, 53, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.15rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.15rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #f6f6f7;
  border-radius: 0.5rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.3rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3.8125rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #22457f;
  background-color: #4284f4;
  border-color: #4284f4;
}
.alert-primary hr {
  border-top-color: #2a74f3;
}
.alert-primary .alert-link {
  color: #172f57;
}

.alert-secondary {
  color: #1b572b;
  background-color: #34a853;
  border-color: #34a853;
}
.alert-secondary hr {
  border-top-color: #2e9549;
}
.alert-secondary .alert-link {
  color: #0f3018;
}

.alert-success {
  color: #1b572b;
  background-color: #34a853;
  border-color: #34a853;
}
.alert-success hr {
  border-top-color: #2e9549;
}
.alert-success .alert-link {
  color: #0f3018;
}

.alert-info {
  color: #136576;
  background-color: #25c2e3;
  border-color: #25c2e3;
}
.alert-info hr {
  border-top-color: #1bb3d3;
}
.alert-info .alert-link {
  color: #0c3f4a;
}

.alert-warning {
  color: #856306;
  background-color: #ffbe0b;
  border-color: #ffbe0b;
}
.alert-warning hr {
  border-top-color: #f1b000;
}
.alert-warning .alert-link {
  color: #543f04;
}

.alert-danger {
  color: #7a231c;
  background-color: #ea4335;
  border-color: #ea4335;
}
.alert-danger hr {
  border-top-color: #e82e1e;
}
.alert-danger .alert-link {
  color: #511712;
}

.alert-light {
  color: #818182;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.alert-light hr {
  border-top-color: #e9ecef;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #343a40;
  border-color: #343a40;
}
.alert-dark hr {
  border-top-color: #292d32;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 0.75rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 0.75rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.65625rem;
  background-color: #f6f6f7;
  border-radius: 0.3rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #4284f4;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 0.75rem 0.75rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.3rem;
}

.list-group-item-action {
  width: 100%;
  color: #4b4b5a;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #4b4b5a;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #4b4b5a;
  background-color: #f6f6f7;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #4284f4;
  border-color: #4284f4;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.3rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.3rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.3rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.3rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.3rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.3rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.3rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.3rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.3rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.3rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #22457f;
  background-color: #caddfc;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #22457f;
  background-color: #b2cefb;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #22457f;
  border-color: #22457f;
}

.list-group-item-secondary {
  color: #1b572b;
  background-color: #c6e7cf;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #1b572b;
  background-color: #b4dfc0;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #1b572b;
  border-color: #1b572b;
}

.list-group-item-success {
  color: #1b572b;
  background-color: #c6e7cf;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #1b572b;
  background-color: #b4dfc0;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #1b572b;
  border-color: #1b572b;
}

.list-group-item-info {
  color: #136576;
  background-color: #c2eef7;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #136576;
  background-color: #abe8f4;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #136576;
  border-color: #136576;
}

.list-group-item-warning {
  color: #856306;
  background-color: #ffedbb;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856306;
  background-color: #ffe6a2;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856306;
  border-color: #856306;
}

.list-group-item-danger {
  color: #7a231c;
  background-color: #f9cac6;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #7a231c;
  background-color: #f7b5af;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #7a231c;
  border-color: #7a231c;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #f6f6f7;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #f6f6f7;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=top], .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=top], .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=top], .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=top], .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=top], .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=top], .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=top], .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=top], .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=top] .arrow, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=top] .arrow, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=top] .arrow, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=top] .arrow, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=top] .arrow, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=top] .arrow, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=top] .arrow, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=top] .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=right], .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=right], .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=right], .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=right], .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=right], .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=right], .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=right], .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=right], .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=right] .arrow, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=right] .arrow, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=right] .arrow, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=right] .arrow, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=right] .arrow, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=right] .arrow, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=right] .arrow, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=right] .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=bottom], .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=bottom], .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=bottom], .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=bottom], .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=bottom], .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=bottom], .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=bottom], .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=bottom], .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=bottom] .arrow, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=bottom] .arrow, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=bottom] .arrow, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=bottom] .arrow, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=bottom] .arrow, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=bottom] .arrow, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=bottom] .arrow, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=bottom] .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=left], .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=left], .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=left], .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=left], .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=left], .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=left], .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=left], .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=left], .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=left] .arrow, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=left] .arrow, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=left] .arrow, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=left] .arrow, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=left] .arrow, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=left] .arrow, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=left] .arrow, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=left] .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=left] .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=left] .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=left] .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=left] .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=left] .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=left] .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=left] .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=left] .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.3rem 0.6rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.3rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #f6f6f7;
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .b-popover-dark.bs-popover-auto[x-placement^=top], .b-popover-light.bs-popover-auto[x-placement^=top], .b-popover-danger.bs-popover-auto[x-placement^=top], .b-popover-warning.bs-popover-auto[x-placement^=top], .b-popover-info.bs-popover-auto[x-placement^=top], .b-popover-success.bs-popover-auto[x-placement^=top], .b-popover-secondary.bs-popover-auto[x-placement^=top], .b-popover-primary.bs-popover-auto[x-placement^=top], .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #f6f6f7;
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .b-popover-dark.bs-popover-auto[x-placement^=right], .b-popover-light.bs-popover-auto[x-placement^=right], .b-popover-danger.bs-popover-auto[x-placement^=right], .b-popover-warning.bs-popover-auto[x-placement^=right], .b-popover-info.bs-popover-auto[x-placement^=right], .b-popover-success.bs-popover-auto[x-placement^=right], .b-popover-secondary.bs-popover-auto[x-placement^=right], .b-popover-primary.bs-popover-auto[x-placement^=right], .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #f6f6f7;
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .b-popover-dark.bs-popover-auto[x-placement^=bottom], .b-popover-light.bs-popover-auto[x-placement^=bottom], .b-popover-danger.bs-popover-auto[x-placement^=bottom], .b-popover-warning.bs-popover-auto[x-placement^=bottom], .b-popover-info.bs-popover-auto[x-placement^=bottom], .b-popover-success.bs-popover-auto[x-placement^=bottom], .b-popover-secondary.bs-popover-auto[x-placement^=bottom], .b-popover-primary.bs-popover-auto[x-placement^=bottom], .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #f6f6f7;
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .b-popover-dark.bs-popover-auto[x-placement^=left], .b-popover-light.bs-popover-auto[x-placement^=left], .b-popover-danger.bs-popover-auto[x-placement^=left], .b-popover-warning.bs-popover-auto[x-placement^=left], .b-popover-info.bs-popover-auto[x-placement^=left], .b-popover-success.bs-popover-auto[x-placement^=left], .b-popover-secondary.bs-popover-auto[x-placement^=left], .b-popover-primary.bs-popover-auto[x-placement^=left], .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #f6f6f7;
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #4b4b5a;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #4284f4 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #1265f1 !important;
}

.bg-secondary {
  background-color: #34a853 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #288140 !important;
}

.bg-success {
  background-color: #34a853 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #288140 !important;
}

.bg-info {
  background-color: #25c2e3 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #18a0bd !important;
}

.bg-warning {
  background-color: #ffbe0b !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d79e00 !important;
}

.bg-danger {
  background-color: #ea4335 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #d62516 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #f6f6f7 !important;
}

.border-top {
  border-top: 1px solid #f6f6f7 !important;
}

.border-right {
  border-right: 1px solid #f6f6f7 !important;
}

.border-bottom {
  border-bottom: 1px solid #f6f6f7 !important;
}

.border-left {
  border-left: 1px solid #f6f6f7 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #4284f4 !important;
}

.border-secondary {
  border-color: #34a853 !important;
}

.border-success {
  border-color: #34a853 !important;
}

.border-info {
  border-color: #25c2e3 !important;
}

.border-warning {
  border-color: #ffbe0b !important;
}

.border-danger {
  border-color: #ea4335 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.3rem !important;
}

.rounded-top {
  border-top-left-radius: 0.3rem !important;
  border-top-right-radius: 0.3rem !important;
}

.rounded-right {
  border-top-right-radius: 0.3rem !important;
  border-bottom-right-radius: 0.3rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.3rem !important;
  border-bottom-left-radius: 0.3rem !important;
}

.rounded-left {
  border-top-left-radius: 0.3rem !important;
  border-bottom-left-radius: 0.3rem !important;
}

.rounded-lg {
  border-radius: 0.5rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.05rem 0.01rem rgba(75, 75, 90, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #4284f4 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0d5add !important;
}

.text-secondary {
  color: #34a853 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #226e36 !important;
}

.text-success {
  color: #34a853 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #226e36 !important;
}

.text-info {
  color: #25c2e3 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #158da6 !important;
}

.text-warning {
  color: #ffbe0b !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #be8b00 !important;
}

.text-danger {
  color: #ea4335 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #bf2114 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #4b4b5a !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #e2e7f1 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #f6f6f7;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #f6f6f7;
  }
}
/*
Template Name: Shreyu - Responsive Bootstrap 4 Admin Dashboard
Author: CoderThemes
Version: 1.0.0
Website: https://coderthemes.com/
Contact: support@coderthemes.com
File: Custom Bootstrap Css File
*/
/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #5369f8;
  --indigo: #564ab1;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #ea4335;
  --orange: #f77e53;
  --yellow: #ffbe0b;
  --green: #43d39e;
  --teal: #1ce1ac;
  --cyan: #25c2e3;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #4284f4;
  --secondary: #34a853;
  --success: #34a853;
  --info: #25c2e3;
  --warning: #ffbe0b;
  --danger: #ea4335;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Nunito Sans", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4b4b5a;
  text-align: left;
  background-color: #edeff4;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #4284f4;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0d5add;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.1875rem;
}

h2, .h2 {
  font-size: 1.75rem;
}

h3, .h3 {
  font-size: 1.53125rem;
}

h4, .h4 {
  font-size: 1.3125rem;
}

h5, .h5 {
  font-size: 1.09375rem;
}

h6, .h6 {
  font-size: 0.875rem;
}

.lead {
  font-size: 1.09375rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #edeff4;
  border: 1px solid #e2e7f1;
  border-radius: 0.3rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #4b4b5a;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #f6f6f7;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #f6f6f7;
}
.table tbody + tbody {
  border-top: 2px solid #f6f6f7;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #f6f6f7;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #f6f6f7;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f6f6f7;
}

.table-hover tbody tr:hover {
  color: #4b4b5a;
  background-color: #f6f6f7;
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #caddfc;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #9dbff9;
}

.table-hover .table-primary:hover {
  background-color: #b2cefb;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #b2cefb;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #c6e7cf;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #95d2a6;
}

.table-hover .table-secondary:hover {
  background-color: #b4dfc0;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #b4dfc0;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c6e7cf;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #95d2a6;
}

.table-hover .table-success:hover {
  background-color: #b4dfc0;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b4dfc0;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #c2eef7;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #8edff0;
}

.table-hover .table-info:hover {
  background-color: #abe8f4;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abe8f4;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffedbb;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdd80;
}

.table-hover .table-warning:hover {
  background-color: #ffe6a2;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe6a2;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f9cac6;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f49d96;
}

.table-hover .table-danger:hover {
  background-color: #f7b5af;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f7b5af;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: #f6f6f7;
}

.table-hover .table-active:hover {
  background-color: #e9e9eb;
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: #e9e9eb;
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #4b4b5a;
  background-color: #f8f9fa;
  border-color: #f6f6f7;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc( 1.5em + 1rem + 2px );
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4b4b5a;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e2e7f1;
  border-radius: 0.3rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #4b4b5a;
}
.form-control:focus {
  color: #4b4b5a;
  background-color: #fff;
  border-color: #4284f4;
  outline: 0;
  box-shadow: none;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #fff;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #4b4b5a;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.09375rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.765625rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.5rem 0;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #4b4b5a;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc( 1.5em + 0.5rem + 2px );
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc( 1.5em + 1rem + 2px );
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.5rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #34a853;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.3rem 0.6rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(52, 168, 83, 0.9);
  border-radius: 0.3rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #34a853;
  padding-right: calc( 1.5em + 1rem );
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2334a853' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc( 0.375em + 0.25rem ) center;
  background-size: calc( 0.75em + 0.5rem ) calc( 0.75em + 0.5rem );
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #34a853;
  box-shadow: 0 0 0 0.15rem rgba(52, 168, 83, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc( 1.5em + 1rem );
  background-position: top calc( 0.375em + 0.25rem ) right calc( 0.375em + 0.25rem );
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #34a853;
  padding-right: calc( (1em + 1rem) * 3 / 4 + 1.75rem );
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2334a853' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc( 0.75em + 0.5rem ) calc( 0.75em + 0.5rem ) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #34a853;
  box-shadow: 0 0 0 0.15rem rgba(52, 168, 83, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #34a853;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #34a853;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #34a853;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #48c76a;
  background-color: #48c76a;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.15rem rgba(52, 168, 83, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #34a853;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #34a853;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #34a853;
  box-shadow: 0 0 0 0.15rem rgba(52, 168, 83, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #ea4335;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.3rem 0.6rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(234, 67, 53, 0.9);
  border-radius: 0.3rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #ea4335;
  padding-right: calc( 1.5em + 1rem );
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ea4335' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23ea4335' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: right calc( 0.375em + 0.25rem ) center;
  background-size: calc( 0.75em + 0.5rem ) calc( 0.75em + 0.5rem );
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #ea4335;
  box-shadow: 0 0 0 0.15rem rgba(234, 67, 53, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc( 1.5em + 1rem );
  background-position: top calc( 0.375em + 0.25rem ) right calc( 0.375em + 0.25rem );
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #ea4335;
  padding-right: calc( (1em + 1rem) * 3 / 4 + 1.75rem );
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ea4335' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23ea4335' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") center right 1.75rem/calc( 0.75em + 0.5rem ) calc( 0.75em + 0.5rem ) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #ea4335;
  box-shadow: 0 0 0 0.15rem rgba(234, 67, 53, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #ea4335;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #ea4335;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #ea4335;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ef6e63;
  background-color: #ef6e63;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.15rem rgba(234, 67, 53, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ea4335;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #ea4335;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #ea4335;
  box-shadow: 0 0 0 0.15rem rgba(234, 67, 53, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #4b4b5a;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.3rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #4b4b5a;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.15rem rgba(66, 132, 244, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #4284f4;
  border-color: #4284f4;
}
.btn-primary:hover {
  color: #fff;
  background-color: #1e6cf2;
  border-color: #1265f1;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #1e6cf2;
  border-color: #1265f1;
  box-shadow: 0 0 0 0.15rem rgba(94, 150, 246, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #4284f4;
  border-color: #4284f4;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #1265f1;
  border-color: #0e5fe9;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(94, 150, 246, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #34a853;
  border-color: #34a853;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #2b8b45;
  border-color: #288140;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #2b8b45;
  border-color: #288140;
  box-shadow: 0 0 0 0.15rem rgba(82, 181, 109, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #34a853;
  border-color: #34a853;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #288140;
  border-color: #25773b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(82, 181, 109, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #34a853;
  border-color: #34a853;
}
.btn-success:hover {
  color: #fff;
  background-color: #2b8b45;
  border-color: #288140;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #2b8b45;
  border-color: #288140;
  box-shadow: 0 0 0 0.15rem rgba(82, 181, 109, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #34a853;
  border-color: #34a853;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #288140;
  border-color: #25773b;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(82, 181, 109, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #25c2e3;
  border-color: #25c2e3;
}
.btn-info:hover {
  color: #fff;
  background-color: #1aaac8;
  border-color: #18a0bd;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #1aaac8;
  border-color: #18a0bd;
  box-shadow: 0 0 0 0.15rem rgba(70, 203, 231, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #25c2e3;
  border-color: #25c2e3;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #18a0bd;
  border-color: #1797b1;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(70, 203, 231, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffbe0b;
  border-color: #ffbe0b;
}
.btn-warning:hover {
  color: #fff;
  background-color: #e4a700;
  border-color: #d79e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #fff;
  background-color: #e4a700;
  border-color: #d79e00;
  box-shadow: 0 0 0 0.15rem rgba(222, 167, 16, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffbe0b;
  border-color: #ffbe0b;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #d79e00;
  border-color: #ca9400;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(222, 167, 16, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #ea4335;
  border-color: #ea4335;
}
.btn-danger:hover {
  color: #fff;
  background-color: #e12717;
  border-color: #d62516;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #e12717;
  border-color: #d62516;
  box-shadow: 0 0 0 0.15rem rgba(237, 95, 83, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #ea4335;
  border-color: #ea4335;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #d62516;
  border-color: #ca2315;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(237, 95, 83, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.15rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.15rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #4284f4;
  border-color: #4284f4;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #4284f4;
  border-color: #4284f4;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.15rem rgba(66, 132, 244, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #4284f4;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #4284f4;
  border-color: #4284f4;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(66, 132, 244, 0.5);
}

.btn-outline-secondary {
  color: #34a853;
  border-color: #34a853;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #34a853;
  border-color: #34a853;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.15rem rgba(52, 168, 83, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #34a853;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #34a853;
  border-color: #34a853;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(52, 168, 83, 0.5);
}

.btn-outline-success {
  color: #34a853;
  border-color: #34a853;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #34a853;
  border-color: #34a853;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.15rem rgba(52, 168, 83, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #34a853;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #34a853;
  border-color: #34a853;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(52, 168, 83, 0.5);
}

.btn-outline-info {
  color: #25c2e3;
  border-color: #25c2e3;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #25c2e3;
  border-color: #25c2e3;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.15rem rgba(37, 194, 227, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #25c2e3;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #25c2e3;
  border-color: #25c2e3;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(37, 194, 227, 0.5);
}

.btn-outline-warning {
  color: #ffbe0b;
  border-color: #ffbe0b;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffbe0b;
  border-color: #ffbe0b;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.15rem rgba(255, 190, 11, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffbe0b;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffbe0b;
  border-color: #ffbe0b;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(255, 190, 11, 0.5);
}

.btn-outline-danger {
  color: #ea4335;
  border-color: #ea4335;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #ea4335;
  border-color: #ea4335;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.15rem rgba(234, 67, 53, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #ea4335;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #ea4335;
  border-color: #ea4335;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(234, 67, 53, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.15rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.15rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #4284f4;
  text-decoration: none;
}
.btn-link:hover {
  color: #0d5add;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.5rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #4b4b5a;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0.2rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #f6f6f7;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.35rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active, .dropdown-item:active {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.765625rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.35rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4b4b5a;
  text-align: center;
  white-space: nowrap;
  background-color: #f6f6f7;
  border: 1px solid #e2e7f1;
  border-radius: 0.3rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc( 1.5em + 1rem + 2px );
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.5rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc( 1.5em + 0.5rem + 2px );
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.3125rem;
  padding-left: 2.5rem;
  color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 2rem;
  height: 1.65625rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #4284f4;
  background-color: #4284f4;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #4284f4;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #ebf2fe;
  border-color: #ebf2fe;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #fff;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: -0.34375rem;
  left: -2.5rem;
  display: block;
  width: 2rem;
  height: 2rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: -0.34375rem;
  left: -2.5rem;
  display: block;
  width: 2rem;
  height: 2rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.3rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #4284f4;
  background-color: #4284f4;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(66, 132, 244, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(66, 132, 244, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(66, 132, 244, 0.5);
}

.custom-switch {
  padding-left: 4rem;
}
.custom-switch .custom-control-label::before {
  left: -4rem;
  width: 3.5rem;
  pointer-events: all;
  border-radius: 1rem;
}
.custom-switch .custom-control-label::after {
  top: calc(-0.34375rem + 2px);
  left: calc(-4rem + 2px);
  width: calc( 2rem - 4px );
  height: calc( 2rem - 4px );
  background-color: #adb5bd;
  border-radius: 1rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(1.5rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(66, 132, 244, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc( 1.5em + 1rem + 2px );
  padding: 0.5rem 1.75rem 0.5rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4b4b5a;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #e2e7f1;
  border-radius: 0.3rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #4284f4;
  outline: 0;
  box-shadow: 0 0 0 0.15rem rgba(66, 132, 244, 0.25);
}
.custom-select:focus::-ms-value {
  color: #4b4b5a;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #f6f6f7;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #4b4b5a;
}

.custom-select-sm {
  height: calc( 1.5em + 0.5rem + 2px );
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.765625rem;
}

.custom-select-lg {
  height: calc( 1.5em + 1rem + 2px );
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.09375rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc( 1.5em + 1rem + 2px );
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc( 1.5em + 1rem + 2px );
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #4284f4;
  box-shadow: none;
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #fff;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc( 1.5em + 1rem + 2px );
  padding: 0.5rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #4b4b5a;
  background-color: #fff;
  border: 1px solid #e2e7f1;
  border-radius: 0.3rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc( 1.5em + 1rem );
  padding: 0.5rem 0.75rem;
  line-height: 1.5;
  color: #4b4b5a;
  content: "Browse";
  background-color: #f6f6f7;
  border-left: inherit;
  border-radius: 0 0.3rem 0.3rem 0;
}

.custom-range {
  width: 100%;
  height: 1.3rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #edeff4, none;
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #edeff4, none;
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #edeff4, none;
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #4284f4;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #ebf2fe;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e2e7f1;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #4284f4;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #ebf2fe;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e2e7f1;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.15rem;
  margin-left: 0.15rem;
  background-color: #4284f4;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #ebf2fe;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #e2e7f1;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #e2e7f1;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid "transparent";
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.3rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #4284f4;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3359375rem;
  padding-bottom: 0.3359375rem;
  margin-right: 1rem;
  font-size: 1.09375rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.09375rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.3rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.5rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.5rem - 0);
  border-top-right-radius: calc(0.5rem - 0);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.5rem - 0);
  border-bottom-left-radius: calc(0.5rem - 0);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #f6f6f7;
  border-bottom: 0 solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.5rem - 0) calc(0.5rem - 0) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: #f6f6f7;
  border-top: 0 solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.5rem - 0) calc(0.5rem - 0);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.5rem - 0);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.5rem - 0);
  border-top-right-radius: calc(0.5rem - 0);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.5rem - 0);
  border-bottom-left-radius: calc(0.5rem - 0);
}

.card-deck .card {
  margin-bottom: 12px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -12px;
    margin-left: -12px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 12px;
    margin-bottom: 0;
    margin-left: 12px;
  }
}

.card-group > .card {
  margin-bottom: 12px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #f6f6f7;
  border-radius: 0.3rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.3rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.3rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.3rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #4284f4;
  background-color: #fff;
  border: 1px solid #e2e7f1;
}
.page-link:hover {
  z-index: 2;
  color: #0d5add;
  text-decoration: none;
  background-color: #f6f6f7;
  border-color: #e2e7f1;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.15rem rgba(66, 132, 244, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4284f4;
  border-color: #4284f4;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #e2e7f1;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.09375rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.3em 0.5em;
  font-size: 82%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.2rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #4284f4;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #1265f1;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.15rem rgba(66, 132, 244, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #34a853;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #288140;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.15rem rgba(52, 168, 83, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #34a853;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #288140;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.15rem rgba(52, 168, 83, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #25c2e3;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #18a0bd;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.15rem rgba(37, 194, 227, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffbe0b;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d79e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.15rem rgba(255, 190, 11, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #ea4335;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #d62516;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.15rem rgba(234, 67, 53, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.15rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.15rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #f6f6f7;
  border-radius: 0.5rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.3rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3.8125rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #22457f;
  background-color: #4284f4;
  border-color: #4284f4;
}
.alert-primary hr {
  border-top-color: #2a74f3;
}
.alert-primary .alert-link {
  color: #172f57;
}

.alert-secondary {
  color: #1b572b;
  background-color: #34a853;
  border-color: #34a853;
}
.alert-secondary hr {
  border-top-color: #2e9549;
}
.alert-secondary .alert-link {
  color: #0f3018;
}

.alert-success {
  color: #1b572b;
  background-color: #34a853;
  border-color: #34a853;
}
.alert-success hr {
  border-top-color: #2e9549;
}
.alert-success .alert-link {
  color: #0f3018;
}

.alert-info {
  color: #136576;
  background-color: #25c2e3;
  border-color: #25c2e3;
}
.alert-info hr {
  border-top-color: #1bb3d3;
}
.alert-info .alert-link {
  color: #0c3f4a;
}

.alert-warning {
  color: #856306;
  background-color: #ffbe0b;
  border-color: #ffbe0b;
}
.alert-warning hr {
  border-top-color: #f1b000;
}
.alert-warning .alert-link {
  color: #543f04;
}

.alert-danger {
  color: #7a231c;
  background-color: #ea4335;
  border-color: #ea4335;
}
.alert-danger hr {
  border-top-color: #e82e1e;
}
.alert-danger .alert-link {
  color: #511712;
}

.alert-light {
  color: #818182;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.alert-light hr {
  border-top-color: #e9ecef;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #343a40;
  border-color: #343a40;
}
.alert-dark hr {
  border-top-color: #292d32;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 0.75rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 0.75rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.65625rem;
  background-color: #f6f6f7;
  border-radius: 0.3rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #4284f4;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 0.75rem 0.75rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.3rem;
}

.list-group-item-action {
  width: 100%;
  color: #4b4b5a;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #4b4b5a;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #4b4b5a;
  background-color: #f6f6f7;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #4284f4;
  border-color: #4284f4;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.3rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.3rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.3rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.3rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.3rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.3rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.3rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.3rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.3rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.3rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #22457f;
  background-color: #caddfc;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #22457f;
  background-color: #b2cefb;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #22457f;
  border-color: #22457f;
}

.list-group-item-secondary {
  color: #1b572b;
  background-color: #c6e7cf;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #1b572b;
  background-color: #b4dfc0;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #1b572b;
  border-color: #1b572b;
}

.list-group-item-success {
  color: #1b572b;
  background-color: #c6e7cf;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #1b572b;
  background-color: #b4dfc0;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #1b572b;
  border-color: #1b572b;
}

.list-group-item-info {
  color: #136576;
  background-color: #c2eef7;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #136576;
  background-color: #abe8f4;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #136576;
  border-color: #136576;
}

.list-group-item-warning {
  color: #856306;
  background-color: #ffedbb;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856306;
  background-color: #ffe6a2;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856306;
  border-color: #856306;
}

.list-group-item-danger {
  color: #7a231c;
  background-color: #f9cac6;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #7a231c;
  background-color: #f7b5af;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #7a231c;
  border-color: #7a231c;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #f6f6f7;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #f6f6f7;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=top], .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=top], .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=top], .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=top], .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=top], .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=top], .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=top], .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=top], .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=top] .arrow, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=top] .arrow, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=top] .arrow, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=top] .arrow, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=top] .arrow, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=top] .arrow, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=top] .arrow, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=top] .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=right], .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=right], .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=right], .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=right], .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=right], .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=right], .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=right], .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=right], .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=right] .arrow, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=right] .arrow, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=right] .arrow, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=right] .arrow, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=right] .arrow, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=right] .arrow, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=right] .arrow, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=right] .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=bottom], .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=bottom], .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=bottom], .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=bottom], .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=bottom], .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=bottom], .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=bottom], .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=bottom], .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=bottom] .arrow, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=bottom] .arrow, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=bottom] .arrow, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=bottom] .arrow, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=bottom] .arrow, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=bottom] .arrow, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=bottom] .arrow, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=bottom] .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=left], .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=left], .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=left], .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=left], .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=left], .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=left], .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=left], .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=left], .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=left] .arrow, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=left] .arrow, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=left] .arrow, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=left] .arrow, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=left] .arrow, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=left] .arrow, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=left] .arrow, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=left] .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=left] .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=left] .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=left] .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=left] .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=left] .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=left] .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=left] .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=left] .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.3rem 0.6rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.3rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #f6f6f7;
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .b-popover-dark.bs-popover-auto[x-placement^=top], .b-popover-light.bs-popover-auto[x-placement^=top], .b-popover-danger.bs-popover-auto[x-placement^=top], .b-popover-warning.bs-popover-auto[x-placement^=top], .b-popover-info.bs-popover-auto[x-placement^=top], .b-popover-success.bs-popover-auto[x-placement^=top], .b-popover-secondary.bs-popover-auto[x-placement^=top], .b-popover-primary.bs-popover-auto[x-placement^=top], .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #f6f6f7;
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .b-popover-dark.bs-popover-auto[x-placement^=right], .b-popover-light.bs-popover-auto[x-placement^=right], .b-popover-danger.bs-popover-auto[x-placement^=right], .b-popover-warning.bs-popover-auto[x-placement^=right], .b-popover-info.bs-popover-auto[x-placement^=right], .b-popover-success.bs-popover-auto[x-placement^=right], .b-popover-secondary.bs-popover-auto[x-placement^=right], .b-popover-primary.bs-popover-auto[x-placement^=right], .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #f6f6f7;
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .b-popover-dark.bs-popover-auto[x-placement^=bottom], .b-popover-light.bs-popover-auto[x-placement^=bottom], .b-popover-danger.bs-popover-auto[x-placement^=bottom], .b-popover-warning.bs-popover-auto[x-placement^=bottom], .b-popover-info.bs-popover-auto[x-placement^=bottom], .b-popover-success.bs-popover-auto[x-placement^=bottom], .b-popover-secondary.bs-popover-auto[x-placement^=bottom], .b-popover-primary.bs-popover-auto[x-placement^=bottom], .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #f6f6f7;
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .b-popover-dark.bs-popover-auto[x-placement^=left], .b-popover-light.bs-popover-auto[x-placement^=left], .b-popover-danger.bs-popover-auto[x-placement^=left], .b-popover-warning.bs-popover-auto[x-placement^=left], .b-popover-info.bs-popover-auto[x-placement^=left], .b-popover-success.bs-popover-auto[x-placement^=left], .b-popover-secondary.bs-popover-auto[x-placement^=left], .b-popover-primary.bs-popover-auto[x-placement^=left], .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #f6f6f7;
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #4b4b5a;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #4284f4 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #1265f1 !important;
}

.bg-secondary {
  background-color: #34a853 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #288140 !important;
}

.bg-success {
  background-color: #34a853 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #288140 !important;
}

.bg-info {
  background-color: #25c2e3 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #18a0bd !important;
}

.bg-warning {
  background-color: #ffbe0b !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d79e00 !important;
}

.bg-danger {
  background-color: #ea4335 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #d62516 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #f6f6f7 !important;
}

.border-top {
  border-top: 1px solid #f6f6f7 !important;
}

.border-right {
  border-right: 1px solid #f6f6f7 !important;
}

.border-bottom {
  border-bottom: 1px solid #f6f6f7 !important;
}

.border-left {
  border-left: 1px solid #f6f6f7 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #4284f4 !important;
}

.border-secondary {
  border-color: #34a853 !important;
}

.border-success {
  border-color: #34a853 !important;
}

.border-info {
  border-color: #25c2e3 !important;
}

.border-warning {
  border-color: #ffbe0b !important;
}

.border-danger {
  border-color: #ea4335 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.3rem !important;
}

.rounded-top {
  border-top-left-radius: 0.3rem !important;
  border-top-right-radius: 0.3rem !important;
}

.rounded-right {
  border-top-right-radius: 0.3rem !important;
  border-bottom-right-radius: 0.3rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.3rem !important;
  border-bottom-left-radius: 0.3rem !important;
}

.rounded-left {
  border-top-left-radius: 0.3rem !important;
  border-bottom-left-radius: 0.3rem !important;
}

.rounded-lg {
  border-radius: 0.5rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.05rem 0.01rem rgba(75, 75, 90, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #4284f4 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0d5add !important;
}

.text-secondary {
  color: #34a853 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #226e36 !important;
}

.text-success {
  color: #34a853 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #226e36 !important;
}

.text-info {
  color: #25c2e3 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #158da6 !important;
}

.text-warning {
  color: #ffbe0b !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #be8b00 !important;
}

.text-danger {
  color: #ea4335 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #bf2114 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #4b4b5a !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #e2e7f1 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #f6f6f7;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #f6f6f7;
  }
}
.custom-accordionwitharrow .card + .card {
  margin-top: 0.5rem;
}
.custom-accordionwitharrow .card .card-header {
  background-color: transparent;
}
.custom-accordionwitharrow .accordion-arrow {
  font-size: 1.2rem;
}
.custom-accordionwitharrow a.collapsed i.accordion-arrow::before {
  content: "";
}

.custom-accordion .card {
  padding-left: 40px;
  overflow: visible;
  border-color: transparent !important;
  border-left: 2px dashed #e2e7f1 !important;
  box-shadow: none;
}
.custom-accordion .card .card-header {
  border-radius: 7px !important;
}
.custom-accordion .card .card-header::before {
  position: absolute;
  top: 22px;
  left: 6px;
  width: 42px;
  height: 2px;
  content: "";
  background: #e2e7f1;
}
.custom-accordion .card .icon {
  position: absolute;
  top: -2px;
  left: -16px;
}

.alert-primary {
  color: #fff;
  background-color: #4284f4;
  border-color: #4284f4;
}
.alert-primary hr {
  border-top-color: #2a74f3;
}
.alert-primary .alert-link {
  color: #e6e6e6;
}
.alert-primary .close,
.alert-primary .alert-link {
  color: #fff;
}

.alert-secondary {
  color: #fff;
  background-color: #34a853;
  border-color: #34a853;
}
.alert-secondary hr {
  border-top-color: #2e9549;
}
.alert-secondary .alert-link {
  color: #e6e6e6;
}
.alert-secondary .close,
.alert-secondary .alert-link {
  color: #fff;
}

.alert-success {
  color: #fff;
  background-color: #34a853;
  border-color: #34a853;
}
.alert-success hr {
  border-top-color: #2e9549;
}
.alert-success .alert-link {
  color: #e6e6e6;
}
.alert-success .close,
.alert-success .alert-link {
  color: #fff;
}

.alert-info {
  color: #fff;
  background-color: #25c2e3;
  border-color: #25c2e3;
}
.alert-info hr {
  border-top-color: #1bb3d3;
}
.alert-info .alert-link {
  color: #e6e6e6;
}
.alert-info .close,
.alert-info .alert-link {
  color: #fff;
}

.alert-warning {
  color: #212529;
  background-color: #ffbe0b;
  border-color: #ffbe0b;
}
.alert-warning hr {
  border-top-color: #f1b000;
}
.alert-warning .alert-link {
  color: #0a0c0d;
}
.alert-warning .close,
.alert-warning .alert-link {
  color: #212529;
}

.alert-danger {
  color: #fff;
  background-color: #ea4335;
  border-color: #ea4335;
}
.alert-danger hr {
  border-top-color: #e82e1e;
}
.alert-danger .alert-link {
  color: #e6e6e6;
}
.alert-danger .close,
.alert-danger .alert-link {
  color: #fff;
}

.alert-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.alert-light hr {
  border-top-color: #e9ecef;
}
.alert-light .alert-link {
  color: #0a0c0d;
}
.alert-light .close,
.alert-light .alert-link {
  color: #212529;
}

.alert-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.alert-dark hr {
  border-top-color: #292d32;
}
.alert-dark .alert-link {
  color: #e6e6e6;
}
.alert-dark .close,
.alert-dark .alert-link {
  color: #fff;
}

.badge-soft {
  color: #343a40;
}

.badge-soft-primary {
  color: #4284f4 !important;
  background-color: rgba(66, 132, 244, 0.2);
}

.badge-soft-secondary {
  color: #34a853 !important;
  background-color: rgba(52, 168, 83, 0.2);
}

.badge-soft-success {
  color: #34a853 !important;
  background-color: rgba(52, 168, 83, 0.2);
}

.badge-soft-info {
  color: #25c2e3 !important;
  background-color: rgba(37, 194, 227, 0.2);
}

.badge-soft-warning {
  color: #ffbe0b !important;
  background-color: rgba(255, 190, 11, 0.2);
}

.badge-soft-danger {
  color: #ea4335 !important;
  background-color: rgba(234, 67, 53, 0.2);
}

.badge-soft-light {
  color: #f8f9fa !important;
  background-color: rgba(248, 249, 250, 0.2);
}

.badge-soft-dark {
  color: #343a40 !important;
  background-color: rgba(52, 58, 64, 0.2);
}

.bg-soft-primary {
  background-color: rgba(66, 132, 244, 0.25) !important;
}

.bg-soft-secondary {
  background-color: rgba(52, 168, 83, 0.25) !important;
}

.bg-soft-success {
  background-color: rgba(52, 168, 83, 0.25) !important;
}

.bg-soft-info {
  background-color: rgba(37, 194, 227, 0.25) !important;
}

.bg-soft-warning {
  background-color: rgba(255, 190, 11, 0.25) !important;
}

.bg-soft-danger {
  background-color: rgba(234, 67, 53, 0.25) !important;
}

.bg-soft-light {
  background-color: rgba(248, 249, 250, 0.25) !important;
}

.bg-soft-dark {
  background-color: rgba(52, 58, 64, 0.25) !important;
}

.bg-gradient {
  background: linear-gradient(to bottom, #564ab1, #746dad);
}

.breadcrumb-item + .breadcrumb-item::before {
  font-family: "unicons";
}

.btn {
  color: #fff;
}
.btn:focus {
  box-shadow: none;
}
.btn .icon svg {
  width: 1rem;
  height: 1rem;
  margin-top: -2px;
  margin-left: 0.1rem;
}

.btn-rounded {
  border-radius: 2em;
}

.btn-light,
.btn-white {
  color: #212529;
}

.btn-white {
  border-color: #e2e7f1;
}
.btn-white:hover, .btn-white:focus {
  background-color: #e2e7f1;
  border-color: #ced4da;
}

.btn-link {
  font-weight: 400;
  color: #4284f4;
  background-color: transparent;
}
.btn-link:hover {
  color: #0d5add;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-xs {
  padding: 0.2rem 0.6rem;
  font-size: 0.75rem;
  border-radius: 0.15rem;
}

.btn-soft-primary {
  color: #4284f4;
  background-color: rgba(66, 132, 244, 0.15);
  border-color: rgba(66, 132, 244, 0.15);
}
.btn-soft-primary:focus {
  box-shadow: none;
}
.btn-soft-primary:hover, .btn-soft-primary:focus {
  color: #4284f4;
  background-color: rgba(66, 132, 244, 0.2);
}

.btn-soft-secondary {
  color: #34a853;
  background-color: rgba(52, 168, 83, 0.15);
  border-color: rgba(52, 168, 83, 0.15);
}
.btn-soft-secondary:focus {
  box-shadow: none;
}
.btn-soft-secondary:hover, .btn-soft-secondary:focus {
  color: #34a853;
  background-color: rgba(52, 168, 83, 0.2);
}

.btn-soft-success {
  color: #34a853;
  background-color: rgba(52, 168, 83, 0.15);
  border-color: rgba(52, 168, 83, 0.15);
}
.btn-soft-success:focus {
  box-shadow: none;
}
.btn-soft-success:hover, .btn-soft-success:focus {
  color: #34a853;
  background-color: rgba(52, 168, 83, 0.2);
}

.btn-soft-info {
  color: #25c2e3;
  background-color: rgba(37, 194, 227, 0.15);
  border-color: rgba(37, 194, 227, 0.15);
}
.btn-soft-info:focus {
  box-shadow: none;
}
.btn-soft-info:hover, .btn-soft-info:focus {
  color: #25c2e3;
  background-color: rgba(37, 194, 227, 0.2);
}

.btn-soft-warning {
  color: #ffbe0b;
  background-color: rgba(255, 190, 11, 0.15);
  border-color: rgba(255, 190, 11, 0.15);
}
.btn-soft-warning:focus {
  box-shadow: none;
}
.btn-soft-warning:hover, .btn-soft-warning:focus {
  color: #ffbe0b;
  background-color: rgba(255, 190, 11, 0.2);
}

.btn-soft-danger {
  color: #ea4335;
  background-color: rgba(234, 67, 53, 0.15);
  border-color: rgba(234, 67, 53, 0.15);
}
.btn-soft-danger:focus {
  box-shadow: none;
}
.btn-soft-danger:hover, .btn-soft-danger:focus {
  color: #ea4335;
  background-color: rgba(234, 67, 53, 0.2);
}

.btn-soft-light {
  color: #f8f9fa;
  background-color: rgba(248, 249, 250, 0.15);
  border-color: rgba(248, 249, 250, 0.15);
}
.btn-soft-light:focus {
  box-shadow: none;
}
.btn-soft-light:hover, .btn-soft-light:focus {
  color: #f8f9fa;
  background-color: rgba(248, 249, 250, 0.2);
}

.btn-soft-dark {
  color: #343a40;
  background-color: rgba(52, 58, 64, 0.15);
  border-color: rgba(52, 58, 64, 0.15);
}
.btn-soft-dark:focus {
  box-shadow: none;
}
.btn-soft-dark:hover, .btn-soft-dark:focus {
  color: #343a40;
  background-color: rgba(52, 58, 64, 0.2);
}

.btn-outline-primary {
  color: #4284f4;
  border-color: #4284f4;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #4284f4;
  border-color: #4284f4;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.15rem rgba(66, 132, 244, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #4284f4;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #4284f4;
  border-color: #4284f4;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(66, 132, 244, 0.5);
}
.btn-outline-primary:focus {
  box-shadow: none;
}

.btn-outline-secondary {
  color: #34a853;
  border-color: #34a853;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #34a853;
  border-color: #34a853;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.15rem rgba(52, 168, 83, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #34a853;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #34a853;
  border-color: #34a853;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(52, 168, 83, 0.5);
}
.btn-outline-secondary:focus {
  box-shadow: none;
}

.btn-outline-success {
  color: #34a853;
  border-color: #34a853;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #34a853;
  border-color: #34a853;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.15rem rgba(52, 168, 83, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #34a853;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #34a853;
  border-color: #34a853;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(52, 168, 83, 0.5);
}
.btn-outline-success:focus {
  box-shadow: none;
}

.btn-outline-info {
  color: #25c2e3;
  border-color: #25c2e3;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #25c2e3;
  border-color: #25c2e3;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.15rem rgba(37, 194, 227, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #25c2e3;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #25c2e3;
  border-color: #25c2e3;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(37, 194, 227, 0.5);
}
.btn-outline-info:focus {
  box-shadow: none;
}

.btn-outline-warning {
  color: #ffbe0b;
  border-color: #ffbe0b;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffbe0b;
  border-color: #ffbe0b;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.15rem rgba(255, 190, 11, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffbe0b;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffbe0b;
  border-color: #ffbe0b;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(255, 190, 11, 0.5);
}
.btn-outline-warning:focus {
  box-shadow: none;
}

.btn-outline-danger {
  color: #ea4335;
  border-color: #ea4335;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #ea4335;
  border-color: #ea4335;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.15rem rgba(234, 67, 53, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #ea4335;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #ea4335;
  border-color: #ea4335;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(234, 67, 53, 0.5);
}
.btn-outline-danger:focus {
  box-shadow: none;
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.15rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light:focus {
  box-shadow: none;
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.15rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark:focus {
  box-shadow: none;
}

.card {
  margin-bottom: 24px;
  border-radius: 0.25rem;
  box-shadow: 0 0.05rem 0.01rem rgba(75, 75, 90, 0.075);
}

.card-drop {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-top: -6px;
  font-size: 20px;
  line-height: 30px;
  color: inherit;
  text-align: center;
  border-radius: 50%;
}
.card-drop:hover {
  color: #343a40;
  background-color: #f6f6f7;
}

.card-title,
.card-header {
  margin-top: 0;
}

.card-nav {
  margin: -0.5rem 0;
}

.card-box {
  padding: 1.25rem;
  margin-bottom: 24px;
  background-color: #fff;
  box-shadow: 0 0.05rem 0.01rem rgba(75, 75, 90, 0.075);
  border-radius: 0.5rem;
}

.header-title {
  font-size: 1.04rem;
  font-weight: 700;
}

.sub-header {
  margin-bottom: 24px;
  color: #6c757d;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  margin: 0;
  font-size: 0.875rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  animation-name: DropDownSlide;
  animation-duration: 0.3s;
  animation-fill-mode: both;
}
.dropdown-menu.show {
  top: 100% !important;
}

.dropdown-menu-right {
  right: 0 !important;
  left: auto !important;
}

.dropdown-menu[x-placement^=right],
.dropdown-menu[x-placement^=top],
.dropdown-menu[x-placement^=left] {
  top: auto !important;
  animation: none !important;
}

@keyframes DropDownSlide {
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  0% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}
.dropdown-lg {
  width: 320px;
}

@media (max-width: 767.98px) {
  .dropdown-lg {
    width: 200px !important;
  }
}
.drop-ani-none .dropdown-menu {
  top: 0 !important;
  animation: none !important;
}

.form-control[readonly],
.form-control[disabled] {
  background: rgba(208, 208, 208, 0.5);
}

.form-control-light {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

input.form-control[type=color],
input.form-control[type=range] {
  min-height: 39px;
}

.custom-select.is-invalid:focus,
.form-control.is-invalid:focus,
.custom-select:invalid:focus,
.form-control:invalid:focus,
.custom-select.is-valid:focus,
.form-control.is-valid:focus,
.custom-select:valid:focus,
.form-control:valid:focus {
  box-shadow: none !important;
}

.comment-area-box .form-control {
  border-color: #e2e7f1;
  border-radius: 0.3rem 0.3rem 0 0;
}
.comment-area-box .comment-area-btn {
  padding: 10px;
  background-color: #f8f9fa;
  border: 1px solid #e2e7f1;
  border-top: none;
  border-radius: 0 0 0.3rem 0.3rem;
}

input:focus {
  outline: none;
}

.modal-title {
  margin-top: 0;
}

.modal-full {
  width: 92%;
  max-width: none;
}

.modal-demo {
  position: relative;
  display: none;
  width: 600px !important;
  background-color: #fff;
  border-radius: 4px;
}
.modal-demo .close {
  position: absolute;
  top: 15px;
  right: 25px;
  color: #f8f9fa;
}

@media (max-width: 768px) {
  .modal-demo {
    width: 96% !important;
  }
}
.custom-modal-title {
  padding: 15px 25px 15px 25px;
  margin: 0;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
  text-align: left;
  background-color: #343a40;
}

.custom-modal-text {
  padding: 20px;
}

.custombox-modal-wrapper {
  text-align: left;
}

.custombox-modal-flash .close,
.custombox-modal-rotatedown .close {
  top: 20px;
  z-index: 9999;
}

.nav-tabs {
  border-bottom: 2px solid rgba(108, 117, 125, 0.2);
}
.nav-tabs .nav-item {
  margin-bottom: -2px;
}
.nav-tabs li a {
  padding: 0.625rem 1.25rem;
  border-color: transparent !important;
}
.nav-tabs li a.active {
  border-bottom: 2px solid #4284f4 !important;
}

.nav-tabs > li > a,
.nav-pills > li > a {
  font-weight: 700;
  color: #4b4b5a;
}

.nav-pills {
  padding: 6px 2px;
  background: #edeff4;
  border-radius: 0.3rem;
}
.nav-pills .nav-link {
  color: #4b4b5a;
  background-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #4b4b5a;
  background-color: #fff;
  box-shadow: 0 0.05rem 0.01rem rgba(75, 75, 90, 0.075);
}

.navtab-bg li > a {
  margin: 0 5px;
  background-color: #e2e7f1;
}

.tab-content {
  padding: 20px 0 0 0;
}

.pagination-rounded .page-link {
  margin: 0 3px;
  border: none;
  border-radius: 30px !important;
}

.popover-header {
  margin-top: 0;
}

.progress-sm {
  height: 5px;
}

.progress-md {
  height: 8px;
}

.progress-lg {
  height: 12px;
}

.progress-xl {
  height: 15px;
}

.progress-xxl {
  height: 25px;
}

/* Progressbar Vertical */
.progress-vertical {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 250px;
  min-height: 250px;
  margin-right: 20px;
  margin-bottom: 0;
}
.progress-vertical .progress-bar {
  width: 100%;
}
.progress-vertical.progress-xl {
  width: 15px;
}
.progress-vertical.progress-lg {
  width: 12px;
}
.progress-vertical.progress-md {
  width: 8px;
}
.progress-vertical.progress-sm {
  width: 5px;
}

.progress-vertical-bottom {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 250px;
  min-height: 250px;
  margin-right: 20px;
  margin-bottom: 0;
}
.progress-vertical-bottom .progress-bar {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.progress-vertical-bottom.progress-xl {
  width: 15px;
}
.progress-vertical-bottom.progress-lg {
  width: 12px;
}
.progress-vertical-bottom.progress-md {
  width: 8px;
}
.progress-vertical-bottom.progress-sm {
  width: 5px;
}

.progress-w-percent {
  min-height: 20px;
  margin-bottom: 20px;
}
.progress-w-percent .progress {
  float: left;
  width: 80%;
  margin-top: 8px;
}
.progress-w-percent .progress-value {
  float: right;
  width: 20%;
  line-height: 20px;
  text-align: right;
}

a {
  text-decoration: none !important;
}
a:focus {
  outline: none;
}

label {
  font-weight: 600;
}

button:focus {
  outline: none;
}

.blockquote {
  padding: 10px 20px;
  margin-bottom: 20px;
  border-left: 4px solid #f6f6f7;
}
.blockquote.blockquote-reverse {
  text-align: right;
  border-right: 4px solid #f6f6f7;
  border-left: 0;
}

.icon-dual .feather {
  color: #6c757d;
  fill: rgba(108, 117, 125, 0.12);
}

.icon-dual-primary .feather {
  color: #4284f4;
  fill: rgba(66, 132, 244, 0.16);
}

.icon-dual-secondary .feather {
  color: #34a853;
  fill: rgba(52, 168, 83, 0.16);
}

.icon-dual-success .feather {
  color: #34a853;
  fill: rgba(52, 168, 83, 0.16);
}

.icon-dual-info .feather {
  color: #25c2e3;
  fill: rgba(37, 194, 227, 0.16);
}

.icon-dual-warning .feather {
  color: #ffbe0b;
  fill: rgba(255, 190, 11, 0.16);
}

.icon-dual-danger .feather {
  color: #ea4335;
  fill: rgba(234, 67, 53, 0.16);
}

.icon-dual-light .feather {
  color: #f8f9fa;
  fill: rgba(248, 249, 250, 0.16);
}

.icon-dual-dark .feather {
  color: #343a40;
  fill: rgba(52, 58, 64, 0.16);
}

.icon-xs > svg {
  width: 16px !important;
  height: 16px !important;
}

.icon-lg > svg {
  width: 32px !important;
  height: 32px !important;
}

.icon-xl > svg {
  width: 48px !important;
  height: 48px !important;
}

.icon-xxl > svg {
  width: 72px !important;
  height: 72px !important;
}

.table thead th {
  outline: none !important;
}

.table-centered td,
.table-centered th {
  vertical-align: middle !important;
}

.table th {
  font-weight: 700;
}
.table .table-user img {
  width: 30px;
  height: 30px;
}

.action-icon {
  display: inline-block;
  padding: 0 3px;
  font-size: 1.2rem;
  color: #6c757d;
}
.action-icon:hover {
  color: #4b4b5a;
}

.table-nowrap th,
.table-nowrap td {
  white-space: nowrap;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 10px 0;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  color: #4b4b5a;
}

p {
  line-height: 1.6;
}

.font-size-11 {
  font-size: 11px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-17 {
  font-size: 17px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-19 {
  font-size: 19px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

.text-unline-dashed {
  padding-bottom: 1px;
  border-bottom: #ced4da 1px dashed !important;
}

.vue-notification-group .vue-notification.vue-notification-template {
  padding: 0;
  background-color: #fff;
  border-left: 0;
}
.vue-notification-group .vue-notification.vue-notification-template .notification-title {
  padding: 3px 10px;
}
.vue-notification-group .vue-notification.vue-notification-template .notification-content {
  padding: 12px 10px;
}
.vue-notification-group .vue-notification.vue-notification-template.error .notification-title {
  background-color: #ea4335;
  border: 1px solid #ea4335;
}
.vue-notification-group .vue-notification.vue-notification-template.error .notification-content {
  color: #ea4335;
  border: 1px solid #ea4335;
}
.vue-notification-group .vue-notification.vue-notification-template.success .notification-title {
  background-color: #34a853;
  border: 1px solid #34a853;
}
.vue-notification-group .vue-notification.vue-notification-template.success .notification-content {
  color: #34a853;
  border: 1px solid #34a853;
}

.bv-no-focus-ring:focus {
  outline: none;
}

@media (max-width: 575.98px) {
  .bv-d-xs-down-none {
    display: none !important;
  }
}
@media (max-width: 767.98px) {
  .bv-d-sm-down-none {
    display: none !important;
  }
}
@media (max-width: 991.98px) {
  .bv-d-md-down-none {
    display: none !important;
  }
}
@media (max-width: 1199.98px) {
  .bv-d-lg-down-none {
    display: none !important;
  }
}
.bv-d-xl-down-none {
  display: none !important;
}

.form-control.focus {
  color: #4b4b5a;
  background-color: #fff;
  border-color: #4284f4;
  outline: 0;
  box-shadow: none;
}
.form-control.focus.is-valid {
  border-color: #34a853;
  box-shadow: 0 0 0 0.15rem rgba(52, 168, 83, 0.25);
}
.form-control.focus.is-invalid {
  border-color: #ea4335;
  box-shadow: 0 0 0 0.15rem rgba(234, 67, 53, 0.25);
}

.b-avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  flex-shrink: 0;
  width: 2.5rem;
  height: 2.5rem;
  font-size: inherit;
  font-weight: 400;
  line-height: 1;
  max-width: 100%;
  max-height: auto;
  text-align: center;
  overflow: visible;
  position: relative;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.b-avatar:focus {
  outline: 0;
}
.b-avatar.btn, .b-avatar[href] {
  padding: 0;
  border: 0;
}
.b-avatar.btn .b-avatar-img img, .b-avatar[href] .b-avatar-img img {
  transition: transform 0.15s ease-in-out;
}
.b-avatar.btn:not(:disabled):not(.disabled), .b-avatar[href]:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.b-avatar.btn:not(:disabled):not(.disabled):hover .b-avatar-img img, .b-avatar[href]:not(:disabled):not(.disabled):hover .b-avatar-img img {
  transform: scale(1.15);
}
.b-avatar.disabled, .b-avatar:disabled, .b-avatar[disabled] {
  opacity: 0.65;
  pointer-events: none;
}
.b-avatar .b-avatar-custom,
.b-avatar .b-avatar-text,
.b-avatar .b-avatar-img {
  border-radius: inherit;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  mask-image: radial-gradient(white, black);
}
.b-avatar .b-avatar-text {
  text-transform: uppercase;
  white-space: nowrap;
}
.b-avatar[href] {
  text-decoration: none;
}
.b-avatar > .b-icon {
  width: 60%;
  height: auto;
  max-width: 100%;
}
.b-avatar .b-avatar-img img {
  width: 100%;
  height: 100%;
  max-height: auto;
  border-radius: inherit;
  object-fit: cover;
}
.b-avatar .b-avatar-badge {
  position: absolute;
  min-height: 1.5em;
  min-width: 1.5em;
  padding: 0.25em;
  line-height: 1;
  border-radius: 10em;
  font-size: 70%;
  font-weight: 700;
  z-index: 1;
}

.b-avatar-sm {
  width: 1.5rem;
  height: 1.5rem;
}
.b-avatar-sm .b-avatar-text {
  font-size: calc(0.6rem);
}
.b-avatar-sm .b-avatar-badge {
  font-size: calc(0.42rem);
}

.b-avatar-lg {
  width: 3.5rem;
  height: 3.5rem;
}
.b-avatar-lg .b-avatar-text {
  font-size: calc(1.4rem);
}
.b-avatar-lg .b-avatar-badge {
  font-size: calc(0.98rem);
}

.b-avatar-group .b-avatar-group-inner {
  display: flex;
  flex-wrap: wrap;
}
.b-avatar-group .b-avatar {
  border: 1px solid #f6f6f7;
}
.b-avatar-group a.b-avatar:hover:not(.disabled):not(disabled),
.b-avatar-group .btn.b-avatar:hover:not(.disabled):not(disabled) {
  z-index: 1;
}

.b-calendar {
  display: inline-flex;
}
.b-calendar .b-calendar-inner {
  min-width: 250px;
}
.b-calendar .b-calendar-header,
.b-calendar .b-calendar-nav {
  margin-bottom: 0.25rem;
}
.b-calendar .b-calendar-nav .btn {
  padding: 0.25rem;
}
.b-calendar output {
  padding: 0.25rem;
  font-size: 80%;
}
.b-calendar output.readonly {
  background-color: #fff;
  opacity: 1;
}
.b-calendar .b-calendar-footer {
  margin-top: 0.5rem;
}
.b-calendar .b-calendar-grid {
  padding: 0;
  margin: 0;
  overflow: hidden;
}
.b-calendar .b-calendar-grid .row {
  flex-wrap: nowrap;
}
.b-calendar .b-calendar-grid-caption {
  padding: 0.25rem;
}
.b-calendar .b-calendar-grid-body .col[data-date] .btn {
  width: 32px;
  height: 32px;
  font-size: 14px;
  line-height: 1;
  margin: 3px auto;
  padding: 9px 0;
}
.b-calendar .btn:disabled, .b-calendar .btn.disabled, .b-calendar .btn[aria-disabled=true] {
  cursor: default;
  pointer-events: none;
}

.card-img-left {
  border-top-left-radius: calc(0.5rem - 0);
  border-bottom-left-radius: calc(0.5rem - 0);
}

.card-img-right {
  border-top-right-radius: calc(0.5rem - 0);
  border-bottom-right-radius: calc(0.5rem - 0);
}

.dropdown:not(.dropleft) .dropdown-toggle.dropdown-toggle-no-caret::after {
  display: none !important;
}
.dropdown.dropleft .dropdown-toggle.dropdown-toggle-no-caret::before {
  display: none !important;
}
.dropdown .dropdown-menu:focus {
  outline: none;
}

.b-dropdown-form {
  display: inline-block;
  padding: 0.35rem 1.5rem;
  width: 100%;
  clear: both;
  font-weight: 400;
}
.b-dropdown-form:focus {
  outline: 1px dotted !important;
  outline: 5px auto -webkit-focus-ring-color !important;
}
.b-dropdown-form.disabled, .b-dropdown-form:disabled {
  outline: 0 !important;
  color: #6c757d;
  pointer-events: none;
}

.b-dropdown-text {
  display: inline-block;
  padding: 0.35rem 1.5rem;
  margin-bottom: 0;
  width: 100%;
  clear: both;
  font-weight: lighter;
}

.custom-checkbox.b-custom-control-lg,
.input-group-lg .custom-checkbox {
  font-size: 1.09375rem;
  line-height: 1.5;
  padding-left: 3.125rem;
}
.custom-checkbox.b-custom-control-lg .custom-control-label::before,
.input-group-lg .custom-checkbox .custom-control-label::before {
  top: -0.4296875rem;
  left: -3.125rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.5rem;
}
.custom-checkbox.b-custom-control-lg .custom-control-label::after,
.input-group-lg .custom-checkbox .custom-control-label::after {
  top: -0.4296875rem;
  left: -3.125rem;
  width: 2.5rem;
  height: 2.5rem;
  background-size: 50% 50%;
}

.custom-checkbox.b-custom-control-sm,
.input-group-sm .custom-checkbox {
  font-size: 0.765625rem;
  line-height: 1.5;
  padding-left: 2.1875rem;
}
.custom-checkbox.b-custom-control-sm .custom-control-label::before,
.input-group-sm .custom-checkbox .custom-control-label::before {
  top: -0.30078125rem;
  left: -2.1875rem;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 0.2rem;
}
.custom-checkbox.b-custom-control-sm .custom-control-label::after,
.input-group-sm .custom-checkbox .custom-control-label::after {
  top: -0.30078125rem;
  left: -2.1875rem;
  width: 1.75rem;
  height: 1.75rem;
  background-size: 50% 50%;
}

.custom-switch.b-custom-control-lg,
.input-group-lg .custom-switch {
  padding-left: 5rem;
}
.custom-switch.b-custom-control-lg .custom-control-label,
.input-group-lg .custom-switch .custom-control-label {
  font-size: 1.09375rem;
  line-height: 1.5;
}
.custom-switch.b-custom-control-lg .custom-control-label::before,
.input-group-lg .custom-switch .custom-control-label::before {
  top: -0.4296875rem;
  height: 2.5rem;
  left: -5rem;
  width: 4.375rem;
  border-radius: 1.25rem;
}
.custom-switch.b-custom-control-lg .custom-control-label::after,
.input-group-lg .custom-switch .custom-control-label::after {
  top: calc( -0.4296875rem + 2px );
  left: calc( -5rem + 2px );
  width: calc( 2.5rem - 4px );
  height: calc( 2.5rem - 4px );
  border-radius: 1.25rem;
  background-size: 50% 50%;
}
.custom-switch.b-custom-control-lg .custom-control-input:checked ~ .custom-control-label::after,
.input-group-lg .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(1.875rem);
}

.custom-switch.b-custom-control-sm,
.input-group-sm .custom-switch {
  padding-left: 3.5rem;
}
.custom-switch.b-custom-control-sm .custom-control-label,
.input-group-sm .custom-switch .custom-control-label {
  font-size: 0.765625rem;
  line-height: 1.5;
}
.custom-switch.b-custom-control-sm .custom-control-label::before,
.input-group-sm .custom-switch .custom-control-label::before {
  top: -0.30078125rem;
  left: -3.5rem;
  width: 3.0625rem;
  height: 1.75rem;
  border-radius: 0.875rem;
}
.custom-switch.b-custom-control-sm .custom-control-label::after,
.input-group-sm .custom-switch .custom-control-label::after {
  top: calc( -0.30078125rem + 2px );
  left: calc( -3.5rem + 2px );
  width: calc( 1.75rem - 4px );
  height: calc( 1.75rem - 4px );
  border-radius: 0.875rem;
  background-size: 50% 50%;
}
.custom-switch.b-custom-control-sm .custom-control-input:checked ~ .custom-control-label::after,
.input-group-sm .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(1.3125rem);
}

.input-group > .input-group-prepend > .btn-group > .btn,
.input-group > .input-group-append:not(:last-child) > .btn-group > .btn,
.input-group > .input-group-append:last-child > .btn-group:not(:last-child):not(.dropdown-toggle) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .input-group-append > .btn-group > .btn,
.input-group > .input-group-prepend:not(:first-child) > .btn-group > .btn,
.input-group > .input-group-prepend:first-child > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.b-form-btn-label-control.form-control {
  display: flex;
  align-items: stretch;
  height: auto;
  padding: 0;
  background-image: none;
}
.input-group .b-form-btn-label-control.form-control {
  padding: 0;
}

[dir=rtl] .b-form-btn-label-control.form-control, .b-form-btn-label-control.form-control[dir=rtl] {
  flex-direction: row-reverse;
}
[dir=rtl] .b-form-btn-label-control.form-control > label, .b-form-btn-label-control.form-control[dir=rtl] > label {
  text-align: right;
}

.b-form-btn-label-control.form-control > .btn {
  line-height: 1;
  font-size: inherit;
  box-shadow: none !important;
  border: 0;
}
.b-form-btn-label-control.form-control > .btn:disabled {
  pointer-events: none;
}
.b-form-btn-label-control.form-control.is-valid > .btn {
  color: #34a853;
}
.b-form-btn-label-control.form-control.is-invalid > .btn {
  color: #ea4335;
}
.b-form-btn-label-control.form-control > .dropdown-menu {
  padding: 0.5rem;
}
.b-form-btn-label-control.form-control > .form-control {
  height: auto;
  min-height: calc(calc( 1.5em + 1rem + 2px ) - 2px);
  padding-left: 0.25rem;
  margin: 0;
  border: 0;
  outline: 0;
  background: transparent;
  word-break: break-word;
  font-size: inherit;
  white-space: normal;
  cursor: pointer;
}
.b-form-btn-label-control.form-control > .form-control.form-control-sm {
  min-height: calc(calc( 1.5em + 0.5rem + 2px ) - 2px);
}
.b-form-btn-label-control.form-control > .form-control.form-control-lg {
  min-height: calc(calc( 1.5em + 1rem + 2px ) - 2px);
}
.input-group.input-group-sm .b-form-btn-label-control.form-control > .form-control {
  min-height: calc(calc( 1.5em + 0.5rem + 2px ) - 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.input-group.input-group-lg .b-form-btn-label-control.form-control > .form-control {
  min-height: calc(calc( 1.5em + 1rem + 2px ) - 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.b-form-btn-label-control.form-control[aria-disabled=true], .b-form-btn-label-control.form-control[aria-readonly=true] {
  background-color: #fff;
  opacity: 1;
}
.b-form-btn-label-control.form-control[aria-disabled=true] {
  pointer-events: none;
}
.b-form-btn-label-control.form-control[aria-disabled=true] > label {
  cursor: default;
}

.b-form-btn-label-control.btn-group > .dropdown-menu {
  padding: 0.5rem;
}

.custom-file-label {
  white-space: nowrap;
  overflow-x: hidden;
}

.b-custom-control-lg.custom-file,
.b-custom-control-lg .custom-file-input,
.b-custom-control-lg .custom-file-label,
.input-group-lg.custom-file,
.input-group-lg .custom-file-input,
.input-group-lg .custom-file-label {
  font-size: 1.09375rem;
  height: calc( 1.5em + 1rem + 2px );
}
.b-custom-control-lg .custom-file-label,
.b-custom-control-lg .custom-file-label:after,
.input-group-lg .custom-file-label,
.input-group-lg .custom-file-label:after {
  padding: 0.5rem 1rem;
  line-height: 1.5;
}
.b-custom-control-lg .custom-file-label,
.input-group-lg .custom-file-label {
  border-radius: 0.5rem;
}
.b-custom-control-lg .custom-file-label::after,
.input-group-lg .custom-file-label::after {
  font-size: inherit;
  height: calc( 1.5em + 1rem );
  border-radius: 0 0.5rem 0.5rem 0;
}

.b-custom-control-sm.custom-file,
.b-custom-control-sm .custom-file-input,
.b-custom-control-sm .custom-file-label,
.input-group-sm.custom-file,
.input-group-sm .custom-file-input,
.input-group-sm .custom-file-label {
  font-size: 0.765625rem;
  height: calc( 1.5em + 0.5rem + 2px );
}
.b-custom-control-sm .custom-file-label,
.b-custom-control-sm .custom-file-label:after,
.input-group-sm .custom-file-label,
.input-group-sm .custom-file-label:after {
  padding: 0.25rem 0.5rem;
  line-height: 1.5;
}
.b-custom-control-sm .custom-file-label,
.input-group-sm .custom-file-label {
  border-radius: 0.2rem;
}
.b-custom-control-sm .custom-file-label::after,
.input-group-sm .custom-file-label::after {
  font-size: inherit;
  height: calc( 1.5em + 0.5rem );
  border-radius: 0 0.2rem 0.2rem 0;
}

.was-validated .form-control:invalid, .was-validated .form-control:valid, .form-control.is-invalid, .form-control.is-valid {
  background-position: right calc( 0.375em + 0.25rem ) center;
}

input[type=color].form-control {
  height: calc( 1.5em + 1rem + 2px );
  padding: 0.125rem 0.25rem;
}

input[type=color].form-control.form-control-sm,
.input-group-sm input[type=color].form-control {
  height: calc( 1.5em + 0.5rem + 2px );
  padding: 0.125rem 0.25rem;
}

input[type=color].form-control.form-control-lg,
.input-group-lg input[type=color].form-control {
  height: calc( 1.5em + 1rem + 2px );
  padding: 0.125rem 0.25rem;
}

input[type=color].form-control:disabled {
  background-color: #adb5bd;
  opacity: 0.65;
}

.input-group > .custom-range {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group > .custom-range + .form-control,
.input-group > .custom-range + .form-control-plaintext,
.input-group > .custom-range + .custom-select,
.input-group > .custom-range + .custom-range,
.input-group > .custom-range + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control + .custom-range,
.input-group > .form-control-plaintext + .custom-range,
.input-group > .custom-select + .custom-range,
.input-group > .custom-range + .custom-range,
.input-group > .custom-file + .custom-range {
  margin-left: -1px;
}
.input-group > .custom-range:focus {
  z-index: 3;
}
.input-group > .custom-range:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-range:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-range {
  height: calc( 1.5em + 1rem + 2px );
  padding: 0 0.75rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e2e7f1;
  height: calc( 1.5em + 1rem + 2px );
  border-radius: 0.3rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .input-group > .custom-range {
    transition: none;
  }
}
.input-group > .custom-range:focus {
  color: #4b4b5a;
  background-color: #fff;
  border-color: #4284f4;
  outline: 0;
  box-shadow: none;
}
.input-group > .custom-range:disabled, .input-group > .custom-range[readonly] {
  background-color: #fff;
}

.input-group-lg > .custom-range {
  height: calc( 1.5em + 1rem + 2px );
  padding: 0 1rem;
  border-radius: 0.5rem;
}

.input-group-sm > .custom-range {
  height: calc( 1.5em + 0.5rem + 2px );
  padding: 0 0.5rem;
  border-radius: 0.2rem;
}

.was-validated .input-group .custom-range:valid, .input-group .custom-range.is-valid {
  border-color: #34a853;
}
.was-validated .input-group .custom-range:valid:focus, .input-group .custom-range.is-valid:focus {
  border-color: #34a853;
  box-shadow: 0 0 0 0.15rem rgba(52, 168, 83, 0.25);
}

.was-validated .custom-range:valid:focus::-webkit-slider-thumb, .custom-range.is-valid:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #edeff4, 0 0 0 0.15rem #aae5ba;
}
.was-validated .custom-range:valid:focus::-moz-range-thumb, .custom-range.is-valid:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #edeff4, 0 0 0 0.15rem #aae5ba;
}
.was-validated .custom-range:valid:focus::-ms-thumb, .custom-range.is-valid:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #edeff4, 0 0 0 0.15rem #aae5ba;
}
.was-validated .custom-range:valid::-webkit-slider-thumb, .custom-range.is-valid::-webkit-slider-thumb {
  background-color: #34a853;
  background-image: none;
}
.was-validated .custom-range:valid::-webkit-slider-thumb:active, .custom-range.is-valid::-webkit-slider-thumb:active {
  background-color: #aae5ba;
  background-image: none;
}
.was-validated .custom-range:valid::-webkit-slider-runnable-track, .custom-range.is-valid::-webkit-slider-runnable-track {
  background-color: rgba(52, 168, 83, 0.35);
}
.was-validated .custom-range:valid::-moz-range-thumb, .custom-range.is-valid::-moz-range-thumb {
  background-color: #34a853;
  background-image: none;
}
.was-validated .custom-range:valid::-moz-range-thumb:active, .custom-range.is-valid::-moz-range-thumb:active {
  background-color: #aae5ba;
  background-image: none;
}
.was-validated .custom-range:valid::-moz-range-track, .custom-range.is-valid::-moz-range-track {
  background: rgba(52, 168, 83, 0.35);
}
.was-validated .custom-range:valid ~ .valid-feedback,
.was-validated .custom-range:valid ~ .valid-tooltip, .custom-range.is-valid ~ .valid-feedback,
.custom-range.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-range:valid::-ms-thumb, .custom-range.is-valid::-ms-thumb {
  background-color: #34a853;
  background-image: none;
}
.was-validated .custom-range:valid::-ms-thumb:active, .custom-range.is-valid::-ms-thumb:active {
  background-color: #aae5ba;
  background-image: none;
}
.was-validated .custom-range:valid::-ms-track-lower, .custom-range.is-valid::-ms-track-lower {
  background: rgba(52, 168, 83, 0.35);
}
.was-validated .custom-range:valid::-ms-track-upper, .custom-range.is-valid::-ms-track-upper {
  background: rgba(52, 168, 83, 0.35);
}

.was-validated .input-group .custom-range:invalid, .input-group .custom-range.is-invalid {
  border-color: #ea4335;
}
.was-validated .input-group .custom-range:invalid:focus, .input-group .custom-range.is-invalid:focus {
  border-color: #ea4335;
  box-shadow: 0 0 0 0.15rem rgba(234, 67, 53, 0.25);
}

.was-validated .custom-range:invalid:focus::-webkit-slider-thumb, .custom-range.is-invalid:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #edeff4, 0 0 0 0.15rem #fbd9d7;
}
.was-validated .custom-range:invalid:focus::-moz-range-thumb, .custom-range.is-invalid:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #edeff4, 0 0 0 0.15rem #fbd9d7;
}
.was-validated .custom-range:invalid:focus::-ms-thumb, .custom-range.is-invalid:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #edeff4, 0 0 0 0.15rem #fbd9d7;
}
.was-validated .custom-range:invalid::-webkit-slider-thumb, .custom-range.is-invalid::-webkit-slider-thumb {
  background-color: #ea4335;
  background-image: none;
}
.was-validated .custom-range:invalid::-webkit-slider-thumb:active, .custom-range.is-invalid::-webkit-slider-thumb:active {
  background-color: #fbd9d7;
  background-image: none;
}
.was-validated .custom-range:invalid::-webkit-slider-runnable-track, .custom-range.is-invalid::-webkit-slider-runnable-track {
  background-color: rgba(234, 67, 53, 0.35);
}
.was-validated .custom-range:invalid::-moz-range-thumb, .custom-range.is-invalid::-moz-range-thumb {
  background-color: #ea4335;
  background-image: none;
}
.was-validated .custom-range:invalid::-moz-range-thumb:active, .custom-range.is-invalid::-moz-range-thumb:active {
  background-color: #fbd9d7;
  background-image: none;
}
.was-validated .custom-range:invalid::-moz-range-track, .custom-range.is-invalid::-moz-range-track {
  background: rgba(234, 67, 53, 0.35);
}
.was-validated .custom-range:invalid ~ .invalid-feedback,
.was-validated .custom-range:invalid ~ .invalid-tooltip, .custom-range.is-invalid ~ .invalid-feedback,
.custom-range.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-range:invalid::-ms-thumb, .custom-range.is-invalid::-ms-thumb {
  background-color: #ea4335;
  background-image: none;
}
.was-validated .custom-range:invalid::-ms-thumb:active, .custom-range.is-invalid::-ms-thumb:active {
  background-color: #fbd9d7;
  background-image: none;
}
.was-validated .custom-range:invalid::-ms-track-lower, .custom-range.is-invalid::-ms-track-lower {
  background: rgba(234, 67, 53, 0.35);
}
.was-validated .custom-range:invalid::-ms-track-upper, .custom-range.is-invalid::-ms-track-upper {
  background: rgba(234, 67, 53, 0.35);
}

.custom-radio.b-custom-control-lg,
.input-group-lg .custom-radio {
  font-size: 1.09375rem;
  line-height: 1.5;
  padding-left: 3.125rem;
}
.custom-radio.b-custom-control-lg .custom-control-label::before,
.input-group-lg .custom-radio .custom-control-label::before {
  top: -0.4296875rem;
  left: -3.125rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
}
.custom-radio.b-custom-control-lg .custom-control-label::after,
.input-group-lg .custom-radio .custom-control-label::after {
  top: -0.4296875rem;
  left: -3.125rem;
  width: 2.5rem;
  height: 2.5rem;
  background: no-repeat 50%/50% 50%;
}

.custom-radio.b-custom-control-sm,
.input-group-sm .custom-radio {
  font-size: 0.765625rem;
  line-height: 1.5;
  padding-left: 2.1875rem;
}
.custom-radio.b-custom-control-sm .custom-control-label::before,
.input-group-sm .custom-radio .custom-control-label::before {
  top: -0.30078125rem;
  left: -2.1875rem;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
}
.custom-radio.b-custom-control-sm .custom-control-label::after,
.input-group-sm .custom-radio .custom-control-label::after {
  top: -0.30078125rem;
  left: -2.1875rem;
  width: 1.75rem;
  height: 1.75rem;
  background: no-repeat 50%/50% 50%;
}

.b-rating {
  text-align: center;
}
.b-rating.d-inline-flex {
  width: auto;
}
.b-rating .b-rating-star,
.b-rating .b-rating-value {
  padding: 0 0.25em;
}
.b-rating .b-rating-value {
  min-width: 2.5em;
}
.b-rating .b-rating-star {
  display: inline-flex;
  justify-content: center;
  outline: 0;
}
.b-rating .b-rating-star .b-rating-icon {
  display: inline-flex;
  transition: all 0.15s ease-in-out;
}
.b-rating.disabled, .b-rating:disabled {
  background-color: #fff;
  color: #6c757d;
}
.b-rating:not(.disabled):not(.readonly) .b-rating-star {
  cursor: pointer;
}
.b-rating:not(.disabled):not(.readonly):focus:not(:hover) .b-rating-star.focused .b-rating-icon,
.b-rating:not(.disabled):not(.readonly) .b-rating-star:hover .b-rating-icon {
  transform: scale(1.5);
}
.b-rating[dir=rtl] .b-rating-star-half {
  transform: scale(-1, 1);
}

.b-form-spinbutton {
  text-align: center;
  overflow: hidden;
  background-image: none;
  padding: 0;
}
[dir=rtl] .b-form-spinbutton:not(.flex-column), .b-form-spinbutton[dir=rtl]:not(.flex-column) {
  flex-direction: row-reverse;
}

.b-form-spinbutton output {
  font-size: inherit;
  outline: 0;
  border: 0;
  background-color: transparent;
  width: auto;
  margin: 0;
  padding: 0 0.25rem;
}
.b-form-spinbutton output > div,
.b-form-spinbutton output > bdi {
  display: block;
  min-width: 2.25em;
  height: 1.5em;
}
.b-form-spinbutton.flex-column {
  height: auto;
  width: auto;
}
.b-form-spinbutton.flex-column output {
  margin: 0 0.25rem;
  padding: 0.25rem 0;
}
.b-form-spinbutton:not(.d-inline-flex):not(.flex-column) {
  output-width: 100%;
}
.b-form-spinbutton.d-inline-flex:not(.flex-column) {
  width: auto;
}
.b-form-spinbutton .btn {
  line-height: 1;
  box-shadow: none !important;
}
.b-form-spinbutton .btn:disabled {
  pointer-events: none;
}
.b-form-spinbutton .btn:hover:not(:disabled) > div > .b-icon {
  transform: scale(1.25);
}
.b-form-spinbutton.disabled, .b-form-spinbutton.readonly {
  background-color: #fff;
}
.b-form-spinbutton.disabled {
  pointer-events: none;
}

.b-form-tags .b-form-tags-list {
  margin-top: -0.25rem;
}
.b-form-tags .b-form-tags-list .b-from-tags-field,
.b-form-tags .b-form-tags-list .b-form-tag {
  margin-top: 0.25rem;
}
.b-form-tags.focus {
  color: #4b4b5a;
  background-color: #fff;
  border-color: #4284f4;
  outline: 0;
  box-shadow: none;
}
.b-form-tags.focus.is-valid {
  border-color: #34a853;
  box-shadow: 0 0 0 0.15rem rgba(52, 168, 83, 0.25);
}
.b-form-tags.focus.is-invalid {
  border-color: #ea4335;
  box-shadow: 0 0 0 0.15rem rgba(234, 67, 53, 0.25);
}
.b-form-tags.disabled {
  background-color: #fff;
}

.b-form-tag {
  font-size: 75%;
  font-weight: normal;
  line-height: 1.5;
  margin-right: 0.25rem;
}
.b-form-tag.disabled {
  opacity: 0.75;
}
.b-form-tag > button.b-form-tag-remove {
  color: inherit;
  font-size: 125%;
  line-height: 1;
  float: none;
  margin-left: 0.25rem;
}

.form-control-sm .b-form-tag {
  line-height: 1.5;
}

.form-control-lg .b-form-tag {
  line-height: 1.5;
}

.media-aside {
  display: flex;
  margin-right: 1rem;
}

.media-aside-right {
  margin-right: 0;
  margin-left: 1rem;
}

.modal-backdrop {
  opacity: 0.5;
}

.b-pagination-pills .page-item .page-link {
  border-radius: 50rem !important;
  margin-left: 0.25rem;
  line-height: 1;
}
.b-pagination-pills .page-item:first-child .page-link {
  margin-left: 0;
}

.popover.b-popover {
  display: block;
  opacity: 1;
  outline: 0;
}
.popover.b-popover.fade:not(.show) {
  opacity: 0;
}
.popover.b-popover.show {
  opacity: 1;
}

.b-popover-primary.popover {
  background-color: #4284f4;
  border-color: #4284f4;
}
.b-popover-primary.bs-popover-top > .arrow::before, .b-popover-primary.bs-popover-auto[x-placement^=top] > .arrow::before {
  border-top-color: #4284f4;
}
.b-popover-primary.bs-popover-top > .arrow::after, .b-popover-primary.bs-popover-auto[x-placement^=top] > .arrow::after {
  border-top-color: #4284f4;
}
.b-popover-primary.bs-popover-right > .arrow::before, .b-popover-primary.bs-popover-auto[x-placement^=right] > .arrow::before {
  border-right-color: #4284f4;
}
.b-popover-primary.bs-popover-right > .arrow::after, .b-popover-primary.bs-popover-auto[x-placement^=right] > .arrow::after {
  border-right-color: #4284f4;
}
.b-popover-primary.bs-popover-bottom > .arrow::before, .b-popover-primary.bs-popover-auto[x-placement^=bottom] > .arrow::before {
  border-bottom-color: #4284f4;
}
.b-popover-primary.bs-popover-bottom > .arrow::after, .b-popover-primary.bs-popover-auto[x-placement^=bottom] > .arrow::after {
  border-bottom-color: #347bf3;
}
.b-popover-primary.bs-popover-bottom .popover-header::before, .b-popover-primary.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  border-bottom-color: #347bf3;
}
.b-popover-primary.bs-popover-left > .arrow::before, .b-popover-primary.bs-popover-auto[x-placement^=left] > .arrow::before {
  border-left-color: #4284f4;
}
.b-popover-primary.bs-popover-left > .arrow::after, .b-popover-primary.bs-popover-auto[x-placement^=left] > .arrow::after {
  border-left-color: #4284f4;
}
.b-popover-primary .popover-header {
  color: #fff;
  background-color: #347bf3;
  border-bottom-color: #1b6bf2;
}
.b-popover-primary .popover-body {
  color: #22457f;
}

.b-popover-secondary.popover {
  background-color: #34a853;
  border-color: #34a853;
}
.b-popover-secondary.bs-popover-top > .arrow::before, .b-popover-secondary.bs-popover-auto[x-placement^=top] > .arrow::before {
  border-top-color: #34a853;
}
.b-popover-secondary.bs-popover-top > .arrow::after, .b-popover-secondary.bs-popover-auto[x-placement^=top] > .arrow::after {
  border-top-color: #34a853;
}
.b-popover-secondary.bs-popover-right > .arrow::before, .b-popover-secondary.bs-popover-auto[x-placement^=right] > .arrow::before {
  border-right-color: #34a853;
}
.b-popover-secondary.bs-popover-right > .arrow::after, .b-popover-secondary.bs-popover-auto[x-placement^=right] > .arrow::after {
  border-right-color: #34a853;
}
.b-popover-secondary.bs-popover-bottom > .arrow::before, .b-popover-secondary.bs-popover-auto[x-placement^=bottom] > .arrow::before {
  border-bottom-color: #34a853;
}
.b-popover-secondary.bs-popover-bottom > .arrow::after, .b-popover-secondary.bs-popover-auto[x-placement^=bottom] > .arrow::after {
  border-bottom-color: #309c4d;
}
.b-popover-secondary.bs-popover-bottom .popover-header::before, .b-popover-secondary.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  border-bottom-color: #309c4d;
}
.b-popover-secondary.bs-popover-left > .arrow::before, .b-popover-secondary.bs-popover-auto[x-placement^=left] > .arrow::before {
  border-left-color: #34a853;
}
.b-popover-secondary.bs-popover-left > .arrow::after, .b-popover-secondary.bs-popover-auto[x-placement^=left] > .arrow::after {
  border-left-color: #34a853;
}
.b-popover-secondary .popover-header {
  color: #fff;
  background-color: #309c4d;
  border-bottom-color: #2a8944;
}
.b-popover-secondary .popover-body {
  color: #1b572b;
}

.b-popover-success.popover {
  background-color: #34a853;
  border-color: #34a853;
}
.b-popover-success.bs-popover-top > .arrow::before, .b-popover-success.bs-popover-auto[x-placement^=top] > .arrow::before {
  border-top-color: #34a853;
}
.b-popover-success.bs-popover-top > .arrow::after, .b-popover-success.bs-popover-auto[x-placement^=top] > .arrow::after {
  border-top-color: #34a853;
}
.b-popover-success.bs-popover-right > .arrow::before, .b-popover-success.bs-popover-auto[x-placement^=right] > .arrow::before {
  border-right-color: #34a853;
}
.b-popover-success.bs-popover-right > .arrow::after, .b-popover-success.bs-popover-auto[x-placement^=right] > .arrow::after {
  border-right-color: #34a853;
}
.b-popover-success.bs-popover-bottom > .arrow::before, .b-popover-success.bs-popover-auto[x-placement^=bottom] > .arrow::before {
  border-bottom-color: #34a853;
}
.b-popover-success.bs-popover-bottom > .arrow::after, .b-popover-success.bs-popover-auto[x-placement^=bottom] > .arrow::after {
  border-bottom-color: #309c4d;
}
.b-popover-success.bs-popover-bottom .popover-header::before, .b-popover-success.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  border-bottom-color: #309c4d;
}
.b-popover-success.bs-popover-left > .arrow::before, .b-popover-success.bs-popover-auto[x-placement^=left] > .arrow::before {
  border-left-color: #34a853;
}
.b-popover-success.bs-popover-left > .arrow::after, .b-popover-success.bs-popover-auto[x-placement^=left] > .arrow::after {
  border-left-color: #34a853;
}
.b-popover-success .popover-header {
  color: #fff;
  background-color: #309c4d;
  border-bottom-color: #2a8944;
}
.b-popover-success .popover-body {
  color: #1b572b;
}

.b-popover-info.popover {
  background-color: #25c2e3;
  border-color: #25c2e3;
}
.b-popover-info.bs-popover-top > .arrow::before, .b-popover-info.bs-popover-auto[x-placement^=top] > .arrow::before {
  border-top-color: #25c2e3;
}
.b-popover-info.bs-popover-top > .arrow::after, .b-popover-info.bs-popover-auto[x-placement^=top] > .arrow::after {
  border-top-color: #25c2e3;
}
.b-popover-info.bs-popover-right > .arrow::before, .b-popover-info.bs-popover-auto[x-placement^=right] > .arrow::before {
  border-right-color: #25c2e3;
}
.b-popover-info.bs-popover-right > .arrow::after, .b-popover-info.bs-popover-auto[x-placement^=right] > .arrow::after {
  border-right-color: #25c2e3;
}
.b-popover-info.bs-popover-bottom > .arrow::before, .b-popover-info.bs-popover-auto[x-placement^=bottom] > .arrow::before {
  border-bottom-color: #25c2e3;
}
.b-popover-info.bs-popover-bottom > .arrow::after, .b-popover-info.bs-popover-auto[x-placement^=bottom] > .arrow::after {
  border-bottom-color: #1cbbdc;
}
.b-popover-info.bs-popover-bottom .popover-header::before, .b-popover-info.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  border-bottom-color: #1cbbdc;
}
.b-popover-info.bs-popover-left > .arrow::before, .b-popover-info.bs-popover-auto[x-placement^=left] > .arrow::before {
  border-left-color: #25c2e3;
}
.b-popover-info.bs-popover-left > .arrow::after, .b-popover-info.bs-popover-auto[x-placement^=left] > .arrow::after {
  border-left-color: #25c2e3;
}
.b-popover-info .popover-header {
  color: #fff;
  background-color: #1cbbdc;
  border-bottom-color: #19a8c6;
}
.b-popover-info .popover-body {
  color: #136576;
}

.b-popover-warning.popover {
  background-color: #ffbe0b;
  border-color: #ffbe0b;
}
.b-popover-warning.bs-popover-top > .arrow::before, .b-popover-warning.bs-popover-auto[x-placement^=top] > .arrow::before {
  border-top-color: #ffbe0b;
}
.b-popover-warning.bs-popover-top > .arrow::after, .b-popover-warning.bs-popover-auto[x-placement^=top] > .arrow::after {
  border-top-color: #ffbe0b;
}
.b-popover-warning.bs-popover-right > .arrow::before, .b-popover-warning.bs-popover-auto[x-placement^=right] > .arrow::before {
  border-right-color: #ffbe0b;
}
.b-popover-warning.bs-popover-right > .arrow::after, .b-popover-warning.bs-popover-auto[x-placement^=right] > .arrow::after {
  border-right-color: #ffbe0b;
}
.b-popover-warning.bs-popover-bottom > .arrow::before, .b-popover-warning.bs-popover-auto[x-placement^=bottom] > .arrow::before {
  border-bottom-color: #ffbe0b;
}
.b-popover-warning.bs-popover-bottom > .arrow::after, .b-popover-warning.bs-popover-auto[x-placement^=bottom] > .arrow::after {
  border-bottom-color: #fbb800;
}
.b-popover-warning.bs-popover-bottom .popover-header::before, .b-popover-warning.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  border-bottom-color: #fbb800;
}
.b-popover-warning.bs-popover-left > .arrow::before, .b-popover-warning.bs-popover-auto[x-placement^=left] > .arrow::before {
  border-left-color: #ffbe0b;
}
.b-popover-warning.bs-popover-left > .arrow::after, .b-popover-warning.bs-popover-auto[x-placement^=left] > .arrow::after {
  border-left-color: #ffbe0b;
}
.b-popover-warning .popover-header {
  color: #212529;
  background-color: #fbb800;
  border-bottom-color: #e1a500;
}
.b-popover-warning .popover-body {
  color: #856306;
}

.b-popover-danger.popover {
  background-color: #ea4335;
  border-color: #ea4335;
}
.b-popover-danger.bs-popover-top > .arrow::before, .b-popover-danger.bs-popover-auto[x-placement^=top] > .arrow::before {
  border-top-color: #ea4335;
}
.b-popover-danger.bs-popover-top > .arrow::after, .b-popover-danger.bs-popover-auto[x-placement^=top] > .arrow::after {
  border-top-color: #ea4335;
}
.b-popover-danger.bs-popover-right > .arrow::before, .b-popover-danger.bs-popover-auto[x-placement^=right] > .arrow::before {
  border-right-color: #ea4335;
}
.b-popover-danger.bs-popover-right > .arrow::after, .b-popover-danger.bs-popover-auto[x-placement^=right] > .arrow::after {
  border-right-color: #ea4335;
}
.b-popover-danger.bs-popover-bottom > .arrow::before, .b-popover-danger.bs-popover-auto[x-placement^=bottom] > .arrow::before {
  border-bottom-color: #ea4335;
}
.b-popover-danger.bs-popover-bottom > .arrow::after, .b-popover-danger.bs-popover-auto[x-placement^=bottom] > .arrow::after {
  border-bottom-color: #e93627;
}
.b-popover-danger.bs-popover-bottom .popover-header::before, .b-popover-danger.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  border-bottom-color: #e93627;
}
.b-popover-danger.bs-popover-left > .arrow::before, .b-popover-danger.bs-popover-auto[x-placement^=left] > .arrow::before {
  border-left-color: #ea4335;
}
.b-popover-danger.bs-popover-left > .arrow::after, .b-popover-danger.bs-popover-auto[x-placement^=left] > .arrow::after {
  border-left-color: #ea4335;
}
.b-popover-danger .popover-header {
  color: #fff;
  background-color: #e93627;
  border-bottom-color: #df2717;
}
.b-popover-danger .popover-body {
  color: #7a231c;
}

.b-popover-light.popover {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.b-popover-light.bs-popover-top > .arrow::before, .b-popover-light.bs-popover-auto[x-placement^=top] > .arrow::before {
  border-top-color: #f8f9fa;
}
.b-popover-light.bs-popover-top > .arrow::after, .b-popover-light.bs-popover-auto[x-placement^=top] > .arrow::after {
  border-top-color: #f8f9fa;
}
.b-popover-light.bs-popover-right > .arrow::before, .b-popover-light.bs-popover-auto[x-placement^=right] > .arrow::before {
  border-right-color: #f8f9fa;
}
.b-popover-light.bs-popover-right > .arrow::after, .b-popover-light.bs-popover-auto[x-placement^=right] > .arrow::after {
  border-right-color: #f8f9fa;
}
.b-popover-light.bs-popover-bottom > .arrow::before, .b-popover-light.bs-popover-auto[x-placement^=bottom] > .arrow::before {
  border-bottom-color: #f8f9fa;
}
.b-popover-light.bs-popover-bottom > .arrow::after, .b-popover-light.bs-popover-auto[x-placement^=bottom] > .arrow::after {
  border-bottom-color: #eff1f4;
}
.b-popover-light.bs-popover-bottom .popover-header::before, .b-popover-light.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  border-bottom-color: #eff1f4;
}
.b-popover-light.bs-popover-left > .arrow::before, .b-popover-light.bs-popover-auto[x-placement^=left] > .arrow::before {
  border-left-color: #f8f9fa;
}
.b-popover-light.bs-popover-left > .arrow::after, .b-popover-light.bs-popover-auto[x-placement^=left] > .arrow::after {
  border-left-color: #f8f9fa;
}
.b-popover-light .popover-header {
  color: #212529;
  background-color: #eff1f4;
  border-bottom-color: #e0e5e9;
}
.b-popover-light .popover-body {
  color: #818182;
}

.b-popover-dark.popover {
  background-color: #343a40;
  border-color: #343a40;
}
.b-popover-dark.bs-popover-top > .arrow::before, .b-popover-dark.bs-popover-auto[x-placement^=top] > .arrow::before {
  border-top-color: #343a40;
}
.b-popover-dark.bs-popover-top > .arrow::after, .b-popover-dark.bs-popover-auto[x-placement^=top] > .arrow::after {
  border-top-color: #343a40;
}
.b-popover-dark.bs-popover-right > .arrow::before, .b-popover-dark.bs-popover-auto[x-placement^=right] > .arrow::before {
  border-right-color: #343a40;
}
.b-popover-dark.bs-popover-right > .arrow::after, .b-popover-dark.bs-popover-auto[x-placement^=right] > .arrow::after {
  border-right-color: #343a40;
}
.b-popover-dark.bs-popover-bottom > .arrow::before, .b-popover-dark.bs-popover-auto[x-placement^=bottom] > .arrow::before {
  border-bottom-color: #343a40;
}
.b-popover-dark.bs-popover-bottom > .arrow::after, .b-popover-dark.bs-popover-auto[x-placement^=bottom] > .arrow::after {
  border-bottom-color: #2d3238;
}
.b-popover-dark.bs-popover-bottom .popover-header::before, .b-popover-dark.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  border-bottom-color: #2d3238;
}
.b-popover-dark.bs-popover-left > .arrow::before, .b-popover-dark.bs-popover-auto[x-placement^=left] > .arrow::before {
  border-left-color: #343a40;
}
.b-popover-dark.bs-popover-left > .arrow::after, .b-popover-dark.bs-popover-auto[x-placement^=left] > .arrow::after {
  border-left-color: #343a40;
}
.b-popover-dark .popover-header {
  color: #fff;
  background-color: #2d3238;
  border-bottom-color: #222629;
}
.b-popover-dark .popover-body {
  color: #1b1e21;
}

.b-sidebar-outer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  overflow: visible;
  z-index: calc(1030 + 5);
}

.b-sidebar-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  opacity: 0.6;
}

.b-sidebar {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  width: 320px;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  margin: 0;
  outline: 0;
  transform: translateX(0);
}
.b-sidebar.slide {
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .b-sidebar.slide {
    transition: none;
  }
}
.b-sidebar:not(.b-sidebar-right) {
  left: 0;
  right: auto;
}
.b-sidebar:not(.b-sidebar-right).slide:not(.show) {
  transform: translateX(-100%);
}
.b-sidebar:not(.b-sidebar-right) > .b-sidebar-header .close {
  margin-left: auto;
}
.b-sidebar.b-sidebar-right {
  left: auto;
  right: 0;
}
.b-sidebar.b-sidebar-right.slide:not(.show) {
  transform: translateX(100%);
}
.b-sidebar.b-sidebar-right > .b-sidebar-header .close {
  margin-right: auto;
}
.b-sidebar > .b-sidebar-header {
  font-size: 1.5rem;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  align-items: center;
}
[dir=rtl] .b-sidebar > .b-sidebar-header {
  flex-direction: row-reverse;
}

.b-sidebar > .b-sidebar-header .close {
  float: none;
  font-size: 1.5rem;
}
.b-sidebar > .b-sidebar-body {
  flex-grow: 1;
  height: 100%;
  overflow-y: auto;
}
.b-sidebar > .b-sidebar-footer {
  flex-grow: 0;
}

.b-skeleton-wrapper {
  cursor: wait;
}

.b-skeleton {
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.12);
  cursor: wait;
  mask-image: radial-gradient(white, black);
}
.b-skeleton::before {
  content: " ";
}

.b-skeleton-text {
  height: 0.875rem;
  margin-bottom: 0.25rem;
  border-radius: 0.25rem;
}

.b-skeleton-button {
  width: 75px;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.b-skeleton-avatar {
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
}

.b-skeleton-input {
  height: calc( 1.5em + 1rem + 2px );
  padding: 0.5rem 0.75rem;
  line-height: 1.5;
  border: #e2e7f1 solid 1px;
  border-radius: 0.3rem;
}

.b-skeleton-icon-wrapper svg {
  color: rgba(0, 0, 0, 0.12);
}

.b-skeleton-img {
  height: 100%;
  width: 100%;
}

.b-skeleton-animate-wave::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
  animation: b-skeleton-animate-wave 1.75s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .b-skeleton-animate-wave::after {
    background: none;
    animation: none;
  }
}

@keyframes b-skeleton-animate-wave {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}
.b-skeleton-animate-fade {
  animation: b-skeleton-animate-fade 0.875s ease-in-out alternate infinite;
}
@media (prefers-reduced-motion: reduce) {
  .b-skeleton-animate-fade {
    animation: none;
  }
}

@keyframes b-skeleton-animate-fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}
.b-skeleton-animate-throb {
  animation: b-skeleton-animate-throb 0.875s ease-in alternate infinite;
}
@media (prefers-reduced-motion: reduce) {
  .b-skeleton-animate-throb {
    animation: none;
  }
}

@keyframes b-skeleton-animate-throb {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.975);
  }
}
.table.b-table.b-table-fixed {
  table-layout: fixed;
}
.table.b-table.b-table-no-border-collapse {
  border-collapse: separate;
  border-spacing: 0;
}
.table.b-table[aria-busy=true] {
  opacity: 0.55;
}
.table.b-table > tbody > tr.b-table-details > td {
  border-top: none !important;
}
.table.b-table > caption {
  caption-side: bottom;
}
.table.b-table.b-table-caption-top > caption {
  caption-side: top !important;
}
.table.b-table > tbody > .table-active,
.table.b-table > tbody > .table-active > th,
.table.b-table > tbody > .table-active > td {
  background-color: #f6f6f7;
}
.table.b-table.table-hover > tbody > tr.table-active:hover td,
.table.b-table.table-hover > tbody > tr.table-active:hover th {
  color: #4b4b5a;
  background-image: linear-gradient(#f6f6f7, #f6f6f7);
  background-repeat: no-repeat;
}
.table.b-table > tbody > .bg-active,
.table.b-table > tbody > .bg-active > th,
.table.b-table > tbody > .bg-active > td {
  background-color: rgba(255, 255, 255, 0.075) !important;
}
.table.b-table.table-hover.table-dark > tbody > tr.bg-active:hover td,
.table.b-table.table-hover.table-dark > tbody > tr.bg-active:hover th {
  color: #fff;
  background-image: linear-gradient(rgba(255, 255, 255, 0.075), rgba(255, 255, 255, 0.075));
  background-repeat: no-repeat;
}

.b-table-sticky-header,
.table-responsive,
[class*=table-responsive-] {
  margin-bottom: 1rem;
}
.b-table-sticky-header > .table,
.table-responsive > .table,
[class*=table-responsive-] > .table {
  margin-bottom: 0;
}

.b-table-sticky-header {
  overflow-y: auto;
  max-height: 300px;
}

@media print {
  .b-table-sticky-header {
    overflow-y: visible !important;
    max-height: none !important;
  }
}
@supports (position: sticky) {
  .b-table-sticky-header > .table.b-table > thead > tr > th {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .b-table-sticky-header > .table.b-table > thead > tr > .b-table-sticky-column,
.b-table-sticky-header > .table.b-table > tbody > tr > .b-table-sticky-column,
.b-table-sticky-header > .table.b-table > tfoot > tr > .b-table-sticky-column,
.table-responsive > .table.b-table > thead > tr > .b-table-sticky-column,
.table-responsive > .table.b-table > tbody > tr > .b-table-sticky-column,
.table-responsive > .table.b-table > tfoot > tr > .b-table-sticky-column,
[class*=table-responsive-] > .table.b-table > thead > tr > .b-table-sticky-column,
[class*=table-responsive-] > .table.b-table > tbody > tr > .b-table-sticky-column,
[class*=table-responsive-] > .table.b-table > tfoot > tr > .b-table-sticky-column {
    position: sticky;
    left: 0;
  }
  .b-table-sticky-header > .table.b-table > thead > tr > .b-table-sticky-column,
.table-responsive > .table.b-table > thead > tr > .b-table-sticky-column,
[class*=table-responsive-] > .table.b-table > thead > tr > .b-table-sticky-column {
    z-index: 5;
  }
  .b-table-sticky-header > .table.b-table > tbody > tr > .b-table-sticky-column,
.b-table-sticky-header > .table.b-table > tfoot > tr > .b-table-sticky-column,
.table-responsive > .table.b-table > tbody > tr > .b-table-sticky-column,
.table-responsive > .table.b-table > tfoot > tr > .b-table-sticky-column,
[class*=table-responsive-] > .table.b-table > tbody > tr > .b-table-sticky-column,
[class*=table-responsive-] > .table.b-table > tfoot > tr > .b-table-sticky-column {
    z-index: 2;
  }

  .table.b-table > thead > tr > .table-b-table-default,
.table.b-table > tbody > tr > .table-b-table-default,
.table.b-table > tfoot > tr > .table-b-table-default {
    color: #4b4b5a;
    background-color: #edeff4;
  }
  .table.b-table.table-dark > thead > tr > .bg-b-table-default,
.table.b-table.table-dark > tbody > tr > .bg-b-table-default,
.table.b-table.table-dark > tfoot > tr > .bg-b-table-default {
    color: #fff;
    background-color: #343a40;
  }
  .table.b-table.table-striped > tbody > tr:nth-of-type(odd) > .table-b-table-default {
    background-image: linear-gradient(#f6f6f7, #f6f6f7);
    background-repeat: no-repeat;
  }
  .table.b-table.table-striped.table-dark > tbody > tr:nth-of-type(odd) > .bg-b-table-default {
    background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
    background-repeat: no-repeat;
  }
  .table.b-table.table-hover > tbody > tr:hover > .table-b-table-default {
    color: #4b4b5a;
    background-image: linear-gradient(#f6f6f7, #f6f6f7);
    background-repeat: no-repeat;
  }
  .table.b-table.table-hover.table-dark > tbody > tr:hover > .bg-b-table-default {
    color: #fff;
    background-image: linear-gradient(rgba(255, 255, 255, 0.075), rgba(255, 255, 255, 0.075));
    background-repeat: no-repeat;
  }
}
.table.b-table > thead > tr > [aria-sort],
.table.b-table > tfoot > tr > [aria-sort] {
  cursor: pointer;
  background-image: none;
  background-repeat: no-repeat;
  background-size: 0.65em 1em;
}
.table.b-table > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table > tfoot > tr > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(0.75rem / 2) center;
  padding-right: calc(0.75rem + 0.65em);
}
.table.b-table > thead > tr > [aria-sort].b-table-sort-icon-left,
.table.b-table > tfoot > tr > [aria-sort].b-table-sort-icon-left {
  background-position: left calc(0.75rem / 2) center;
  padding-left: calc(0.75rem + 0.65em);
}
.table.b-table > thead > tr > [aria-sort=none],
.table.b-table > tfoot > tr > [aria-sort=none] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}
.table.b-table > thead > tr > [aria-sort=ascending],
.table.b-table > tfoot > tr > [aria-sort=ascending] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='black' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}
.table.b-table > thead > tr > [aria-sort=descending],
.table.b-table > tfoot > tr > [aria-sort=descending] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='black' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}
.table.b-table.table-dark > thead > tr > [aria-sort=none], .table.b-table.table-dark > tfoot > tr > [aria-sort=none],
.table.b-table > .thead-dark > tr > [aria-sort=none] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' opacity='.3' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}
.table.b-table.table-dark > thead > tr > [aria-sort=ascending], .table.b-table.table-dark > tfoot > tr > [aria-sort=ascending],
.table.b-table > .thead-dark > tr > [aria-sort=ascending] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='white' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}
.table.b-table.table-dark > thead > tr > [aria-sort=descending], .table.b-table.table-dark > tfoot > tr > [aria-sort=descending],
.table.b-table > .thead-dark > tr > [aria-sort=descending] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='white' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}
.table.b-table > thead > tr > .table-dark[aria-sort=none],
.table.b-table > tfoot > tr > .table-dark[aria-sort=none] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' opacity='.3' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}
.table.b-table > thead > tr > .table-dark[aria-sort=ascending],
.table.b-table > tfoot > tr > .table-dark[aria-sort=ascending] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='white' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}
.table.b-table > thead > tr > .table-dark[aria-sort=descending],
.table.b-table > tfoot > tr > .table-dark[aria-sort=descending] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='white' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm > tfoot > tr > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(0.3rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
.table.b-table.table-sm > thead > tr > [aria-sort].b-table-sort-icon-left,
.table.b-table.table-sm > tfoot > tr > [aria-sort].b-table-sort-icon-left {
  background-position: left calc(0.3rem / 2) center;
  padding-left: calc(0.3rem + 0.65em);
}

.table.b-table.b-table-selectable:not(.b-table-selectable-no-click) > tbody > tr {
  cursor: pointer;
}
.table.b-table.b-table-selectable:not(.b-table-selectable-no-click).b-table-selecting.b-table-select-range > tbody > tr {
  user-select: none;
}

@media (max-width: 575.98px) {
  .table.b-table.b-table-stacked-sm {
    display: block;
    width: 100%;
  }
  .table.b-table.b-table-stacked-sm > caption,
.table.b-table.b-table-stacked-sm > tbody,
.table.b-table.b-table-stacked-sm > tbody > tr,
.table.b-table.b-table-stacked-sm > tbody > tr > td,
.table.b-table.b-table-stacked-sm > tbody > tr > th {
    display: block;
  }
  .table.b-table.b-table-stacked-sm > thead,
.table.b-table.b-table-stacked-sm > tfoot {
    display: none;
  }
  .table.b-table.b-table-stacked-sm > thead > tr.b-table-top-row,
.table.b-table.b-table-stacked-sm > thead > tr.b-table-bottom-row,
.table.b-table.b-table-stacked-sm > tfoot > tr.b-table-top-row,
.table.b-table.b-table-stacked-sm > tfoot > tr.b-table-bottom-row {
    display: none;
  }
  .table.b-table.b-table-stacked-sm > caption {
    caption-side: top !important;
  }
  .table.b-table.b-table-stacked-sm > tbody > tr > [data-label]::before {
    content: attr(data-label);
    width: 40%;
    float: left;
    text-align: right;
    overflow-wrap: break-word;
    font-weight: bold;
    font-style: normal;
    padding: 0 calc(1rem / 2) 0 0;
    margin: 0;
  }
  .table.b-table.b-table-stacked-sm > tbody > tr > [data-label]::after {
    display: block;
    clear: both;
    content: "";
  }
  .table.b-table.b-table-stacked-sm > tbody > tr > [data-label] > div {
    display: inline-block;
    width: calc(100% - 40%);
    padding: 0 0 0 calc(1rem / 2);
    margin: 0;
  }
  .table.b-table.b-table-stacked-sm > tbody > tr.top-row, .table.b-table.b-table-stacked-sm > tbody > tr.bottom-row {
    display: none;
  }
  .table.b-table.b-table-stacked-sm > tbody > tr > :first-child {
    border-top-width: 3px;
  }
  .table.b-table.b-table-stacked-sm > tbody > tr > [rowspan] + td,
.table.b-table.b-table-stacked-sm > tbody > tr > [rowspan] + th {
    border-top-width: 3px;
  }
}
@media (max-width: 767.98px) {
  .table.b-table.b-table-stacked-md {
    display: block;
    width: 100%;
  }
  .table.b-table.b-table-stacked-md > caption,
.table.b-table.b-table-stacked-md > tbody,
.table.b-table.b-table-stacked-md > tbody > tr,
.table.b-table.b-table-stacked-md > tbody > tr > td,
.table.b-table.b-table-stacked-md > tbody > tr > th {
    display: block;
  }
  .table.b-table.b-table-stacked-md > thead,
.table.b-table.b-table-stacked-md > tfoot {
    display: none;
  }
  .table.b-table.b-table-stacked-md > thead > tr.b-table-top-row,
.table.b-table.b-table-stacked-md > thead > tr.b-table-bottom-row,
.table.b-table.b-table-stacked-md > tfoot > tr.b-table-top-row,
.table.b-table.b-table-stacked-md > tfoot > tr.b-table-bottom-row {
    display: none;
  }
  .table.b-table.b-table-stacked-md > caption {
    caption-side: top !important;
  }
  .table.b-table.b-table-stacked-md > tbody > tr > [data-label]::before {
    content: attr(data-label);
    width: 40%;
    float: left;
    text-align: right;
    overflow-wrap: break-word;
    font-weight: bold;
    font-style: normal;
    padding: 0 calc(1rem / 2) 0 0;
    margin: 0;
  }
  .table.b-table.b-table-stacked-md > tbody > tr > [data-label]::after {
    display: block;
    clear: both;
    content: "";
  }
  .table.b-table.b-table-stacked-md > tbody > tr > [data-label] > div {
    display: inline-block;
    width: calc(100% - 40%);
    padding: 0 0 0 calc(1rem / 2);
    margin: 0;
  }
  .table.b-table.b-table-stacked-md > tbody > tr.top-row, .table.b-table.b-table-stacked-md > tbody > tr.bottom-row {
    display: none;
  }
  .table.b-table.b-table-stacked-md > tbody > tr > :first-child {
    border-top-width: 3px;
  }
  .table.b-table.b-table-stacked-md > tbody > tr > [rowspan] + td,
.table.b-table.b-table-stacked-md > tbody > tr > [rowspan] + th {
    border-top-width: 3px;
  }
}
@media (max-width: 991.98px) {
  .table.b-table.b-table-stacked-lg {
    display: block;
    width: 100%;
  }
  .table.b-table.b-table-stacked-lg > caption,
.table.b-table.b-table-stacked-lg > tbody,
.table.b-table.b-table-stacked-lg > tbody > tr,
.table.b-table.b-table-stacked-lg > tbody > tr > td,
.table.b-table.b-table-stacked-lg > tbody > tr > th {
    display: block;
  }
  .table.b-table.b-table-stacked-lg > thead,
.table.b-table.b-table-stacked-lg > tfoot {
    display: none;
  }
  .table.b-table.b-table-stacked-lg > thead > tr.b-table-top-row,
.table.b-table.b-table-stacked-lg > thead > tr.b-table-bottom-row,
.table.b-table.b-table-stacked-lg > tfoot > tr.b-table-top-row,
.table.b-table.b-table-stacked-lg > tfoot > tr.b-table-bottom-row {
    display: none;
  }
  .table.b-table.b-table-stacked-lg > caption {
    caption-side: top !important;
  }
  .table.b-table.b-table-stacked-lg > tbody > tr > [data-label]::before {
    content: attr(data-label);
    width: 40%;
    float: left;
    text-align: right;
    overflow-wrap: break-word;
    font-weight: bold;
    font-style: normal;
    padding: 0 calc(1rem / 2) 0 0;
    margin: 0;
  }
  .table.b-table.b-table-stacked-lg > tbody > tr > [data-label]::after {
    display: block;
    clear: both;
    content: "";
  }
  .table.b-table.b-table-stacked-lg > tbody > tr > [data-label] > div {
    display: inline-block;
    width: calc(100% - 40%);
    padding: 0 0 0 calc(1rem / 2);
    margin: 0;
  }
  .table.b-table.b-table-stacked-lg > tbody > tr.top-row, .table.b-table.b-table-stacked-lg > tbody > tr.bottom-row {
    display: none;
  }
  .table.b-table.b-table-stacked-lg > tbody > tr > :first-child {
    border-top-width: 3px;
  }
  .table.b-table.b-table-stacked-lg > tbody > tr > [rowspan] + td,
.table.b-table.b-table-stacked-lg > tbody > tr > [rowspan] + th {
    border-top-width: 3px;
  }
}
@media (max-width: 1199.98px) {
  .table.b-table.b-table-stacked-xl {
    display: block;
    width: 100%;
  }
  .table.b-table.b-table-stacked-xl > caption,
.table.b-table.b-table-stacked-xl > tbody,
.table.b-table.b-table-stacked-xl > tbody > tr,
.table.b-table.b-table-stacked-xl > tbody > tr > td,
.table.b-table.b-table-stacked-xl > tbody > tr > th {
    display: block;
  }
  .table.b-table.b-table-stacked-xl > thead,
.table.b-table.b-table-stacked-xl > tfoot {
    display: none;
  }
  .table.b-table.b-table-stacked-xl > thead > tr.b-table-top-row,
.table.b-table.b-table-stacked-xl > thead > tr.b-table-bottom-row,
.table.b-table.b-table-stacked-xl > tfoot > tr.b-table-top-row,
.table.b-table.b-table-stacked-xl > tfoot > tr.b-table-bottom-row {
    display: none;
  }
  .table.b-table.b-table-stacked-xl > caption {
    caption-side: top !important;
  }
  .table.b-table.b-table-stacked-xl > tbody > tr > [data-label]::before {
    content: attr(data-label);
    width: 40%;
    float: left;
    text-align: right;
    overflow-wrap: break-word;
    font-weight: bold;
    font-style: normal;
    padding: 0 calc(1rem / 2) 0 0;
    margin: 0;
  }
  .table.b-table.b-table-stacked-xl > tbody > tr > [data-label]::after {
    display: block;
    clear: both;
    content: "";
  }
  .table.b-table.b-table-stacked-xl > tbody > tr > [data-label] > div {
    display: inline-block;
    width: calc(100% - 40%);
    padding: 0 0 0 calc(1rem / 2);
    margin: 0;
  }
  .table.b-table.b-table-stacked-xl > tbody > tr.top-row, .table.b-table.b-table-stacked-xl > tbody > tr.bottom-row {
    display: none;
  }
  .table.b-table.b-table-stacked-xl > tbody > tr > :first-child {
    border-top-width: 3px;
  }
  .table.b-table.b-table-stacked-xl > tbody > tr > [rowspan] + td,
.table.b-table.b-table-stacked-xl > tbody > tr > [rowspan] + th {
    border-top-width: 3px;
  }
}
.table.b-table.b-table-stacked {
  display: block;
  width: 100%;
}
.table.b-table.b-table-stacked > caption,
.table.b-table.b-table-stacked > tbody,
.table.b-table.b-table-stacked > tbody > tr,
.table.b-table.b-table-stacked > tbody > tr > td,
.table.b-table.b-table-stacked > tbody > tr > th {
  display: block;
}
.table.b-table.b-table-stacked > thead,
.table.b-table.b-table-stacked > tfoot {
  display: none;
}
.table.b-table.b-table-stacked > thead > tr.b-table-top-row,
.table.b-table.b-table-stacked > thead > tr.b-table-bottom-row,
.table.b-table.b-table-stacked > tfoot > tr.b-table-top-row,
.table.b-table.b-table-stacked > tfoot > tr.b-table-bottom-row {
  display: none;
}
.table.b-table.b-table-stacked > caption {
  caption-side: top !important;
}
.table.b-table.b-table-stacked > tbody > tr > [data-label]::before {
  content: attr(data-label);
  width: 40%;
  float: left;
  text-align: right;
  overflow-wrap: break-word;
  font-weight: bold;
  font-style: normal;
  padding: 0 calc(1rem / 2) 0 0;
  margin: 0;
}
.table.b-table.b-table-stacked > tbody > tr > [data-label]::after {
  display: block;
  clear: both;
  content: "";
}
.table.b-table.b-table-stacked > tbody > tr > [data-label] > div {
  display: inline-block;
  width: calc(100% - 40%);
  padding: 0 0 0 calc(1rem / 2);
  margin: 0;
}
.table.b-table.b-table-stacked > tbody > tr.top-row, .table.b-table.b-table-stacked > tbody > tr.bottom-row {
  display: none;
}
.table.b-table.b-table-stacked > tbody > tr > :first-child {
  border-top-width: 3px;
}
.table.b-table.b-table-stacked > tbody > tr > [rowspan] + td,
.table.b-table.b-table-stacked > tbody > tr > [rowspan] + th {
  border-top-width: 3px;
}

.b-time {
  min-width: 150px;
}
.b-time[aria-disabled=true] output, .b-time[aria-readonly=true] output,
.b-time output.disabled {
  background-color: #fff;
  opacity: 1;
}
.b-time[aria-disabled=true] output {
  pointer-events: none;
}
[dir=rtl] .b-time > .d-flex:not(.flex-column) {
  flex-direction: row-reverse;
}

.b-time .b-time-header {
  margin-bottom: 0.5rem;
}
.b-time .b-time-header output {
  padding: 0.25rem;
  font-size: 80%;
}
.b-time .b-time-footer {
  margin-top: 0.5rem;
}
.b-time .b-time-ampm {
  margin-left: 0.5rem;
}

.b-toast {
  display: block;
  position: relative;
  max-width: 350px;
  backface-visibility: hidden;
  background-clip: padding-box;
  z-index: 1;
  border-radius: 0.25rem;
}
.b-toast .toast {
  background-color: rgba(255, 255, 255, 0.85);
}
.b-toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.b-toast.b-toast-solid .toast {
  background-color: white;
}
.b-toast .toast {
  opacity: 1;
}
.b-toast .toast.fade:not(.show) {
  opacity: 0;
}
.b-toast .toast .toast-body {
  display: block;
}

.b-toast-primary .toast {
  background-color: rgba(90, 148, 245, 0.85);
  border-color: rgba(66, 132, 244, 0.85);
  color: #22457f;
}
.b-toast-primary .toast .toast-header {
  color: #22457f;
  background-color: rgba(66, 132, 244, 0.85);
  border-bottom-color: rgba(66, 132, 244, 0.85);
}
.b-toast-primary.b-toast-solid .toast {
  background-color: #5a94f5;
}

.b-toast-secondary .toast {
  background-color: rgba(58, 187, 93, 0.85);
  border-color: rgba(52, 168, 83, 0.85);
  color: #1b572b;
}
.b-toast-secondary .toast .toast-header {
  color: #1b572b;
  background-color: rgba(52, 168, 83, 0.85);
  border-bottom-color: rgba(52, 168, 83, 0.85);
}
.b-toast-secondary.b-toast-solid .toast {
  background-color: #3abb5d;
}

.b-toast-success .toast {
  background-color: rgba(58, 187, 93, 0.85);
  border-color: rgba(52, 168, 83, 0.85);
  color: #1b572b;
}
.b-toast-success .toast .toast-header {
  color: #1b572b;
  background-color: rgba(52, 168, 83, 0.85);
  border-bottom-color: rgba(52, 168, 83, 0.85);
}
.b-toast-success.b-toast-solid .toast {
  background-color: #3abb5d;
}

.b-toast-info .toast {
  background-color: rgba(60, 200, 230, 0.85);
  border-color: rgba(37, 194, 227, 0.85);
  color: #136576;
}
.b-toast-info .toast .toast-header {
  color: #136576;
  background-color: rgba(37, 194, 227, 0.85);
  border-bottom-color: rgba(37, 194, 227, 0.85);
}
.b-toast-info.b-toast-solid .toast {
  background-color: #3cc8e6;
}

.b-toast-warning .toast {
  background-color: rgba(255, 197, 37, 0.85);
  border-color: rgba(255, 190, 11, 0.85);
  color: #856306;
}
.b-toast-warning .toast .toast-header {
  color: #856306;
  background-color: rgba(255, 190, 11, 0.85);
  border-bottom-color: rgba(255, 190, 11, 0.85);
}
.b-toast-warning.b-toast-solid .toast {
  background-color: #ffc525;
}

.b-toast-danger .toast {
  background-color: rgba(236, 88, 76, 0.85);
  border-color: rgba(234, 67, 53, 0.85);
  color: #7a231c;
}
.b-toast-danger .toast .toast-header {
  color: #7a231c;
  background-color: rgba(234, 67, 53, 0.85);
  border-bottom-color: rgba(234, 67, 53, 0.85);
}
.b-toast-danger.b-toast-solid .toast {
  background-color: #ec584c;
}

.b-toast-light .toast {
  background-color: rgba(255, 255, 255, 0.85);
  border-color: rgba(248, 249, 250, 0.85);
  color: #818182;
}
.b-toast-light .toast .toast-header {
  color: #818182;
  background-color: rgba(248, 249, 250, 0.85);
  border-bottom-color: rgba(248, 249, 250, 0.85);
}
.b-toast-light.b-toast-solid .toast {
  background-color: white;
}

.b-toast-dark .toast {
  background-color: rgba(63, 71, 78, 0.85);
  border-color: rgba(52, 58, 64, 0.85);
  color: #1b1e21;
}
.b-toast-dark .toast .toast-header {
  color: #1b1e21;
  background-color: rgba(52, 58, 64, 0.85);
  border-bottom-color: rgba(52, 58, 64, 0.85);
}
.b-toast-dark.b-toast-solid .toast {
  background-color: #3f474e;
}

.b-toaster {
  z-index: 1100;
}
.b-toaster .b-toaster-slot {
  position: relative;
  display: block;
}
.b-toaster .b-toaster-slot:empty {
  display: none !important;
}

.b-toaster.b-toaster-top-right, .b-toaster.b-toaster-top-left, .b-toaster.b-toaster-top-center, .b-toaster.b-toaster-top-full, .b-toaster.b-toaster-bottom-right, .b-toaster.b-toaster-bottom-left, .b-toaster.b-toaster-bottom-center, .b-toaster.b-toaster-bottom-full {
  position: fixed;
  left: 0.5rem;
  right: 0.5rem;
  margin: 0;
  padding: 0;
  height: 0;
  overflow: visible;
}
.b-toaster.b-toaster-top-right .b-toaster-slot, .b-toaster.b-toaster-top-left .b-toaster-slot, .b-toaster.b-toaster-top-center .b-toaster-slot, .b-toaster.b-toaster-top-full .b-toaster-slot, .b-toaster.b-toaster-bottom-right .b-toaster-slot, .b-toaster.b-toaster-bottom-left .b-toaster-slot, .b-toaster.b-toaster-bottom-center .b-toaster-slot, .b-toaster.b-toaster-bottom-full .b-toaster-slot {
  position: absolute;
  max-width: 350px;
  width: 100%;
  /* IE 11 fix */
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
}
.b-toaster.b-toaster-top-full .b-toaster-slot, .b-toaster.b-toaster-bottom-full .b-toaster-slot {
  width: 100%;
  max-width: 100%;
}
.b-toaster.b-toaster-top-full .b-toaster-slot .b-toast,
.b-toaster.b-toaster-top-full .b-toaster-slot .toast, .b-toaster.b-toaster-bottom-full .b-toaster-slot .b-toast,
.b-toaster.b-toaster-bottom-full .b-toaster-slot .toast {
  width: 100%;
  max-width: 100%;
}
.b-toaster.b-toaster-top-right, .b-toaster.b-toaster-top-left, .b-toaster.b-toaster-top-center, .b-toaster.b-toaster-top-full {
  top: 0;
}
.b-toaster.b-toaster-top-right .b-toaster-slot, .b-toaster.b-toaster-top-left .b-toaster-slot, .b-toaster.b-toaster-top-center .b-toaster-slot, .b-toaster.b-toaster-top-full .b-toaster-slot {
  top: 0.5rem;
}
.b-toaster.b-toaster-bottom-right, .b-toaster.b-toaster-bottom-left, .b-toaster.b-toaster-bottom-center, .b-toaster.b-toaster-bottom-full {
  bottom: 0;
}
.b-toaster.b-toaster-bottom-right .b-toaster-slot, .b-toaster.b-toaster-bottom-left .b-toaster-slot, .b-toaster.b-toaster-bottom-center .b-toaster-slot, .b-toaster.b-toaster-bottom-full .b-toaster-slot {
  bottom: 0.5rem;
}
.b-toaster.b-toaster-top-right .b-toaster-slot, .b-toaster.b-toaster-bottom-right .b-toaster-slot, .b-toaster.b-toaster-top-center .b-toaster-slot, .b-toaster.b-toaster-bottom-center .b-toaster-slot {
  margin-left: auto;
}
.b-toaster.b-toaster-top-left .b-toaster-slot, .b-toaster.b-toaster-bottom-left .b-toaster-slot, .b-toaster.b-toaster-top-center .b-toaster-slot, .b-toaster.b-toaster-bottom-center .b-toaster-slot {
  margin-right: auto;
}

.b-toaster.b-toaster-top-right .b-toast.b-toaster-enter-active, .b-toaster.b-toaster-top-right .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-top-right .b-toast.b-toaster-move, .b-toaster.b-toaster-top-left .b-toast.b-toaster-enter-active, .b-toaster.b-toaster-top-left .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-top-left .b-toast.b-toaster-move, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-enter-active, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-move, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-enter-active, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-move {
  transition: transform 0.175s;
}
.b-toaster.b-toaster-top-right .b-toast.b-toaster-enter-to .toast.fade, .b-toaster.b-toaster-top-right .b-toast.b-toaster-enter-active .toast.fade, .b-toaster.b-toaster-top-left .b-toast.b-toaster-enter-to .toast.fade, .b-toaster.b-toaster-top-left .b-toast.b-toaster-enter-active .toast.fade, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-enter-to .toast.fade, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-enter-active .toast.fade, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-enter-to .toast.fade, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-enter-active .toast.fade {
  transition-delay: 0.175s;
}
.b-toaster.b-toaster-top-right .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-top-left .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-leave-active {
  position: absolute;
  transition-delay: 0.175s;
}
.b-toaster.b-toaster-top-right .b-toast.b-toaster-leave-active .toast.fade, .b-toaster.b-toaster-top-left .b-toast.b-toaster-leave-active .toast.fade, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-leave-active .toast.fade, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-leave-active .toast.fade {
  transition-delay: 0s;
}
.tooltip.b-tooltip {
  display: block;
  opacity: 0.9;
  outline: 0;
}
.tooltip.b-tooltip.fade:not(.show) {
  opacity: 0;
}
.tooltip.b-tooltip.show {
  opacity: 0.9;
}
.tooltip.b-tooltip.noninteractive {
  pointer-events: none;
}
.tooltip.b-tooltip .arrow {
  margin: 0 0.3rem;
}
.tooltip.b-tooltip.bs-tooltip-right .arrow, .tooltip.b-tooltip.bs-tooltip-auto[x-placement^=right] .arrow, .tooltip.b-tooltip.bs-tooltip-left .arrow, .tooltip.b-tooltip.bs-tooltip-auto[x-placement^=left] .arrow {
  margin: 0.3rem 0;
}

.tooltip.b-tooltip-primary.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #4284f4;
}
.tooltip.b-tooltip-primary.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #4284f4;
}
.tooltip.b-tooltip-primary.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #4284f4;
}
.tooltip.b-tooltip-primary.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #4284f4;
}
.tooltip.b-tooltip-primary .tooltip-inner {
  color: #fff;
  background-color: #4284f4;
}

.tooltip.b-tooltip-secondary.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #34a853;
}
.tooltip.b-tooltip-secondary.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #34a853;
}
.tooltip.b-tooltip-secondary.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #34a853;
}
.tooltip.b-tooltip-secondary.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #34a853;
}
.tooltip.b-tooltip-secondary .tooltip-inner {
  color: #fff;
  background-color: #34a853;
}

.tooltip.b-tooltip-success.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #34a853;
}
.tooltip.b-tooltip-success.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #34a853;
}
.tooltip.b-tooltip-success.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #34a853;
}
.tooltip.b-tooltip-success.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #34a853;
}
.tooltip.b-tooltip-success .tooltip-inner {
  color: #fff;
  background-color: #34a853;
}

.tooltip.b-tooltip-info.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #25c2e3;
}
.tooltip.b-tooltip-info.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #25c2e3;
}
.tooltip.b-tooltip-info.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #25c2e3;
}
.tooltip.b-tooltip-info.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #25c2e3;
}
.tooltip.b-tooltip-info .tooltip-inner {
  color: #fff;
  background-color: #25c2e3;
}

.tooltip.b-tooltip-warning.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #ffbe0b;
}
.tooltip.b-tooltip-warning.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #ffbe0b;
}
.tooltip.b-tooltip-warning.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #ffbe0b;
}
.tooltip.b-tooltip-warning.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #ffbe0b;
}
.tooltip.b-tooltip-warning .tooltip-inner {
  color: #212529;
  background-color: #ffbe0b;
}

.tooltip.b-tooltip-danger.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #ea4335;
}
.tooltip.b-tooltip-danger.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #ea4335;
}
.tooltip.b-tooltip-danger.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #ea4335;
}
.tooltip.b-tooltip-danger.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #ea4335;
}
.tooltip.b-tooltip-danger .tooltip-inner {
  color: #fff;
  background-color: #ea4335;
}

.tooltip.b-tooltip-light.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #f8f9fa;
}
.tooltip.b-tooltip-light.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #f8f9fa;
}
.tooltip.b-tooltip-light.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #f8f9fa;
}
.tooltip.b-tooltip-light.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #f8f9fa;
}
.tooltip.b-tooltip-light .tooltip-inner {
  color: #212529;
  background-color: #f8f9fa;
}

.tooltip.b-tooltip-dark.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #343a40;
}
.tooltip.b-tooltip-dark.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #343a40;
}
.tooltip.b-tooltip-dark.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #343a40;
}
.tooltip.b-tooltip-dark.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #343a40;
}
.tooltip.b-tooltip-dark .tooltip-inner {
  color: #fff;
  background-color: #343a40;
}

.b-icon.bi {
  display: inline-block;
  overflow: visible;
  vertical-align: -0.15em;
}
.b-icon.b-icon-animation-cylon, .b-icon.b-iconstack .b-icon-animation-cylon > g {
  transform-origin: center;
  animation: 0.75s infinite ease-in-out alternate b-icon-animation-cylon;
}
@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-cylon, .b-icon.b-iconstack .b-icon-animation-cylon > g {
    animation: none;
  }
}
.b-icon.b-icon-animation-cylon-vertical, .b-icon.b-iconstack .b-icon-animation-cylon-vertical > g {
  transform-origin: center;
  animation: 0.75s infinite ease-in-out alternate b-icon-animation-cylon-vertical;
}
@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-cylon-vertical, .b-icon.b-iconstack .b-icon-animation-cylon-vertical > g {
    animation: none;
  }
}
.b-icon.b-icon-animation-fade, .b-icon.b-iconstack .b-icon-animation-fade > g {
  transform-origin: center;
  animation: 0.75s infinite ease-in-out alternate b-icon-animation-fade;
}
@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-fade, .b-icon.b-iconstack .b-icon-animation-fade > g {
    animation: none;
  }
}
.b-icon.b-icon-animation-spin, .b-icon.b-iconstack .b-icon-animation-spin > g {
  transform-origin: center;
  animation: 2s infinite linear normal b-icon-animation-spin;
}
@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-spin, .b-icon.b-iconstack .b-icon-animation-spin > g {
    animation: none;
  }
}
.b-icon.b-icon-animation-spin-reverse, .b-icon.b-iconstack .b-icon-animation-spin-reverse > g {
  transform-origin: center;
  animation: 2s infinite linear reverse b-icon-animation-spin;
}
@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-spin-reverse, .b-icon.b-iconstack .b-icon-animation-spin-reverse > g {
    animation: none;
  }
}
.b-icon.b-icon-animation-spin-pulse, .b-icon.b-iconstack .b-icon-animation-spin-pulse > g {
  transform-origin: center;
  animation: 1s infinite steps(8) normal b-icon-animation-spin;
}
@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-spin-pulse, .b-icon.b-iconstack .b-icon-animation-spin-pulse > g {
    animation: none;
  }
}
.b-icon.b-icon-animation-spin-reverse-pulse, .b-icon.b-iconstack .b-icon-animation-spin-reverse-pulse > g {
  transform-origin: center;
  animation: 1s infinite steps(8) reverse b-icon-animation-spin;
}
@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-spin-reverse-pulse, .b-icon.b-iconstack .b-icon-animation-spin-reverse-pulse > g {
    animation: none;
  }
}
.b-icon.b-icon-animation-throb, .b-icon.b-iconstack .b-icon-animation-throb > g {
  transform-origin: center;
  animation: 0.75s infinite ease-in-out alternate b-icon-animation-throb;
}
@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-throb, .b-icon.b-iconstack .b-icon-animation-throb > g {
    animation: none;
  }
}

@keyframes b-icon-animation-cylon {
  0% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(25%);
  }
}
@keyframes b-icon-animation-cylon-vertical {
  0% {
    transform: translateY(25%);
  }
  100% {
    transform: translateY(-25%);
  }
}
@keyframes b-icon-animation-fade {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes b-icon-animation-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
@keyframes b-icon-animation-throb {
  0% {
    opacity: 0.5;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.btn .b-icon.bi,
.nav-link .b-icon.bi,
.dropdown-toggle .b-icon.bi,
.dropdown-item .b-icon.bi,
.input-group-text .b-icon.bi {
  font-size: 125%;
  vertical-align: text-bottom;
}

/* 2. General */
html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
}

/* 3. Left Menu */
.nav-second-level li a,
.nav-thrid-level li a {
  position: relative;
  display: block;
  padding: 8px 20px;
  color: #4b4b5a;
  transition: all 0.4s;
}
.nav-second-level li a:focus, .nav-second-level li a:hover,
.nav-thrid-level li a:focus,
.nav-thrid-level li a:hover {
  color: #4284f4;
}
.nav-second-level li > a.active,
.nav-second-level li a.router-link-exact-active,
.nav-thrid-level li > a.active,
.nav-thrid-level li a.router-link-exact-active {
  color: #4284f4;
}

#wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
#wrapper .content-page.blocked-screen {
  margin-left: 0 !important;
}

.content-page {
  min-height: 80vh;
  padding: 0 12.5px 5px 12.5px;
  margin-top: 72px;
  margin-left: 250px;
  overflow: hidden;
}
.has-notification .content-page {
  margin-top: calc( 30px + 72px );
}
.has-notification .has-notification .content-page {
  margin-top: calc( 30px + 30px + 72px );
}

.left-side-menu {
  position: fixed;
  top: 72px;
  bottom: 0;
  z-index: 99;
  width: 250px;
  padding: 0 0 30px 0;
  background: #fff;
  box-shadow: 0 0.05rem 0.01rem rgba(75, 75, 90, 0.075);
  transition-duration: 0.2s;
}
.has-notification .left-side-menu {
  top: calc( 30px + 72px );
}
.has-notification .has-notification .left-side-menu {
  top: calc( 30px + 30px + 72px );
}
.left-side-menu .sidebar-content {
  height: 100%;
  padding-bottom: 30px;
}

#sidebar-menu > ul > li.mm-active > a {
  color: #4284f4;
  background-color: #eff1f5;
  border-left: 3px solid #4284f4;
}
#sidebar-menu > ul > li.mm-active > a svg {
  color: #4284f4;
  fill: rgba(66, 132, 244, 0.1);
}
#sidebar-menu > ul > li > a {
  position: relative;
  display: block;
  padding: 12px 30px;
  font-size: 1rem;
  font-weight: 400;
  color: #4b4b5a;
  border-left: 3px solid transparent;
  transition: all 0.4s;
}
#sidebar-menu > ul > li > a svg {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 10px 0 3px;
  color: #4b4b5a;
  fill: rgba(75, 75, 90, 0.12);
}
#sidebar-menu > ul > li > a:hover, #sidebar-menu > ul > li > a:focus, #sidebar-menu > ul > li > a:active {
  color: #4284f4;
  text-decoration: none;
  background-color: #eff1f5;
}
#sidebar-menu > ul > li > a:hover svg, #sidebar-menu > ul > li > a:focus svg, #sidebar-menu > ul > li > a:active svg {
  color: #4284f4;
  fill: rgba(66, 132, 244, 0.1);
}
#sidebar-menu > ul > li > a > span {
  vertical-align: middle;
}
#sidebar-menu > ul > li > a i {
  display: inline-block;
  margin: -1px 4px 0 0;
  line-height: 1rem;
  text-align: center;
  vertical-align: middle;
}
#sidebar-menu > ul > li > a.active {
  color: #4284f4;
  background-color: #eff1f5;
  border-left: 3px solid #4284f4;
}
#sidebar-menu > ul > li > a.active svg {
  color: #4284f4;
  fill: rgba(66, 132, 244, 0.1);
}
#sidebar-menu > ul > li > ul {
  padding-left: 43px;
}
#sidebar-menu > ul > li > ul ul {
  padding-left: 20px;
}
#sidebar-menu .menu-arrow {
  position: absolute;
  top: 15px;
  right: 30px;
  display: inline-block;
  font-family: "unicons";
  font-size: 0.9rem;
  line-height: 1.3rem;
  transition: transform 0.15s;
  transform: translate(0, 0);
  text-rendering: auto;
}
#sidebar-menu .menu-arrow::before {
  content: "";
}
#sidebar-menu .badge {
  margin-top: 3px;
}
#sidebar-menu .nav-second-level > li .menu-arrow {
  top: 8px;
}
#sidebar-menu li.mm-active > a > span.menu-arrow {
  transform: rotate(90deg);
}
#sidebar-menu .menu-title {
  padding: 15px 30px 10px 30px;
  font-size: 0.6875rem;
  color: #4b4b5a;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  pointer-events: none;
  cursor: default;
}

.user-profile {
  padding: 15px 20px 15px 30px;
}
.user-profile .avatar-xs {
  display: none;
}
.user-profile .pro-user-name {
  font-weight: 700;
  color: #4b4b5a;
}
.user-profile .pro-user-desc {
  font-size: 0.6rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.user-profile .dropdown-toggle svg {
  width: 18px;
  height: 18px;
  color: #4b4b5a;
  background-color: rgba(75, 75, 90, 0.1);
  border-radius: 3px;
}

.left-side-menu-condensed .logo {
  width: 70px !important;
  text-align: center;
}
.left-side-menu-condensed .logo span.logo-lg {
  display: none;
}
.left-side-menu-condensed .logo span.logo-sm {
  display: block !important;
}
.left-side-menu-condensed .navbar-custom {
  left: 0 !important;
  padding-left: 10px;
}
.left-side-menu-condensed .navbar-custom .button-menu-mobile.disable-btn {
  display: block !important;
}
.left-side-menu-condensed .left-side-menu {
  position: absolute;
  z-index: 5;
  width: 70px !important;
}
.left-side-menu-condensed .left-side-menu .slimScrollDiv,
.left-side-menu-condensed .left-side-menu .slimscroll-menu {
  height: auto !important;
  overflow: inherit !important;
}
.left-side-menu-condensed .left-side-menu .slimScrollBar {
  visibility: hidden;
}
.left-side-menu-condensed .left-side-menu .sidebar-content {
  height: auto;
}
.left-side-menu-condensed .left-side-menu #sidebar-menu .menu-title,
.left-side-menu-condensed .left-side-menu #sidebar-menu .menu-arrow,
.left-side-menu-condensed .left-side-menu #sidebar-menu .label,
.left-side-menu-condensed .left-side-menu #sidebar-menu .badge {
  display: none !important;
}
.left-side-menu-condensed .left-side-menu #sidebar-menu > ul > li {
  position: relative;
  white-space: nowrap;
}
.left-side-menu-condensed .left-side-menu #sidebar-menu > ul > li > a {
  min-height: 54px;
  padding: 15px;
  margin: 0;
  transition: none;
}
.left-side-menu-condensed .left-side-menu #sidebar-menu > ul > li > a:hover, .left-side-menu-condensed .left-side-menu #sidebar-menu > ul > li > a:active, .left-side-menu-condensed .left-side-menu #sidebar-menu > ul > li > a:focus {
  color: #4284f4;
}
.left-side-menu-condensed .left-side-menu #sidebar-menu > ul > li > a svg {
  width: 26px;
}
.left-side-menu-condensed .left-side-menu #sidebar-menu > ul > li > a span {
  display: none;
  padding-left: 25px;
  vertical-align: baseline;
}
.left-side-menu-condensed .left-side-menu #sidebar-menu > ul > li > a.active {
  background-color: #eff1f5;
}
.left-side-menu-condensed .left-side-menu #sidebar-menu > ul > li:hover > a {
  position: relative;
  width: calc(190px + 70px);
  color: #4284f4 !important;
  background-color: #eff1f5;
  transition: none;
}
.left-side-menu-condensed .left-side-menu #sidebar-menu > ul > li:hover > a span {
  display: inline;
}
.left-side-menu-condensed .left-side-menu #sidebar-menu > ul > li:hover a.open ::after,
.left-side-menu-condensed .left-side-menu #sidebar-menu > ul > li:hover a.mm-active ::after {
  display: none;
}
.left-side-menu-condensed .left-side-menu #sidebar-menu > ul > li:hover > ul {
  position: absolute;
  left: 70px;
  display: block;
  width: 190px;
  height: auto !important;
  box-shadow: 0 0.05rem 0.01rem rgba(75, 75, 90, 0.075);
}
.left-side-menu-condensed .left-side-menu #sidebar-menu > ul > li:hover > ul ul {
  box-shadow: 0 0.05rem 0.01rem rgba(75, 75, 90, 0.075);
}
.left-side-menu-condensed .left-side-menu #sidebar-menu > ul > li:hover > ul a {
  position: relative;
  z-index: 6;
  width: 190px;
  padding: 8px 20px;
  box-shadow: none;
}
.left-side-menu-condensed .left-side-menu #sidebar-menu > ul > li:hover > ul a:hover {
  color: #4284f4;
}
.left-side-menu-condensed .left-side-menu #sidebar-menu > ul ul {
  z-index: 9999;
  display: none;
  padding: 5px 0;
  background-color: #fff;
}
.left-side-menu-condensed .left-side-menu #sidebar-menu > ul ul li:hover > ul {
  position: absolute;
  left: 190px;
  display: block;
  width: 190px;
  height: auto !important;
  margin-top: -36px;
}
.left-side-menu-condensed .left-side-menu #sidebar-menu > ul ul li > a span.pull-right {
  position: absolute;
  top: 12px;
  right: 20px;
  transform: rotate(270deg);
}
.left-side-menu-condensed .left-side-menu #sidebar-menu > ul ul li.active a {
  color: #c8cddc;
}
.left-side-menu-condensed .user-profile {
  padding: 0px;
}
.left-side-menu-condensed .user-profile .avatar-sm {
  display: none;
}
.left-side-menu-condensed .user-profile .avatar-xs {
  display: block;
}
.left-side-menu-condensed .user-profile .pro-user-name,
.left-side-menu-condensed .user-profile .pro-user-desc {
  display: none;
}
.left-side-menu-condensed .user-profile .profile-dropdown-menu .dropdown-toggle svg {
  background-color: transparent;
}
.left-side-menu-condensed .content-page {
  margin-left: 70px !important;
}
.left-side-menu-condensed .footer {
  left: 0 !important;
}
.left-side-menu-condensed .user-box {
  display: none;
}

@media (max-width: 1024px) {
  .left-side-menu {
    top: 72px;
    padding: 12px 0;
  }
  .has-notification .left-side-menu {
    top: calc( 30px + 72px );
  }
  .has-notification .has-notification .left-side-menu {
    top: calc( 30px + 30px + 72px );
  }

  .left-side-menu-condensed .left-side-menu {
    padding-top: 12px;
  }
}
@media (max-width: 1025px) {
  body:not(.left-side-menu-condensed) .left-side-menu {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  body {
    overflow-x: hidden;
  }

  .left-side-menu {
    top: 72px;
    z-index: 10 !important;
    display: none;
  }
  .has-notification .left-side-menu {
    top: calc( 30px + 72px );
  }
  .has-notification .has-notification .left-side-menu {
    top: calc( 30px + 30px + 72px );
  }

  .sidebar-enable .left-side-menu {
    display: block;
  }

  .content-page,
.left-side-menu-condensed .content-page {
    padding: 0 10px;
    margin-left: 0 !important;
  }

  .pro-user-name {
    display: none;
  }

  .logo-box {
    display: none;
  }
}
/* =============
  Menu - Dark
============= */
.left-side-menu-dark .metismenu li a[aria-expanded=true] {
  color: #fff !important;
}
.left-side-menu-dark .left-side-menu {
  background-color: #323742;
  box-shadow: none;
}
.left-side-menu-dark .left-side-menu #sidebar-menu > ul > li.mm-active > a {
  color: #fff;
  background-color: #3b414e;
}
.left-side-menu-dark .left-side-menu #sidebar-menu > ul > li.mm-active > a svg {
  color: #fff;
  fill: rgba(255, 255, 255, 0.12);
}
.left-side-menu-dark .left-side-menu #sidebar-menu > ul > li > a {
  color: #9097a7;
}
.left-side-menu-dark .left-side-menu #sidebar-menu > ul > li > a svg {
  color: #9097a7;
  fill: rgba(255, 255, 255, 0.12);
}
.left-side-menu-dark .left-side-menu #sidebar-menu > ul > li > a:hover, .left-side-menu-dark .left-side-menu #sidebar-menu > ul > li > a:focus, .left-side-menu-dark .left-side-menu #sidebar-menu > ul > li > a:active {
  color: #c8cddc;
  background-color: #3b414e;
}
.left-side-menu-dark .left-side-menu #sidebar-menu > ul > li > a.active {
  color: #fff;
  background-color: #3b414e;
}
.left-side-menu-dark .left-side-menu #sidebar-menu > ul > li > a.active svg {
  color: #fff;
  fill: rgba(255, 255, 255, 0.12);
}
.left-side-menu-dark .left-side-menu #sidebar-menu .menu-title {
  color: #adb5bd;
}
.left-side-menu-dark .nav-second-level li a,
.left-side-menu-dark .nav-thrid-level li a {
  color: #9097a7;
}
.left-side-menu-dark .nav-second-level li a:focus, .left-side-menu-dark .nav-second-level li a:hover,
.left-side-menu-dark .nav-thrid-level li a:focus,
.left-side-menu-dark .nav-thrid-level li a:hover {
  color: #c8cddc;
  background-color: transparent;
}
.left-side-menu-dark .nav-second-level li.mm-active > a,
.left-side-menu-dark .nav-thrid-level li.mm-active > a {
  color: #fff;
}
.left-side-menu-dark.left-side-menu-condensed .left-side-menu #sidebar-menu > ul > li:hover > a {
  color: #fff !important;
  background-color: #3b414e;
}
.left-side-menu-dark.left-side-menu-condensed .nav-second-level li.mm-active > a,
.left-side-menu-dark.left-side-menu-condensed .nav-thrid-level li.mm-active > a {
  color: #4284f4;
}
.left-side-menu-dark .user-profile .pro-user-name,
.left-side-menu-dark .user-profile .pro-user-desc {
  color: #adb5bd;
}
.left-side-menu-dark .user-profile .profile-dropdown-menu .dropdown-toggle svg {
  color: #fff;
  background-color: #3b414e;
}

/* 4. Topbar */
.top-notification-bar {
  display: none;
  height: 30px;
  padding-top: 2px;
  z-index: 101;
  text-align: center;
  width: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  font-size: 16px;
  overflow: hidden;
}
.top-notification-bar.app-notification {
  z-index: 102;
}
.top-notification-bar.notify {
  background-color: #4284f4;
}
.top-notification-bar.warning {
  background-color: #ffbe09;
}
.top-notification-bar.danger {
  background-color: #ea4335;
}
.top-notification-bar.primary {
  background-color: #4284f4;
}
.has-notification .top-notification-bar {
  display: block;
}
.top-notification-bar a {
  padding: 0 0px;
  color: inherit;
}
.top-notification-bar a:hover {
  text-decoration: underline !important;
}

.has-notification .has-notification .top-notification-bar-inside {
  top: 30px;
}

.navbar-custom {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
  height: 72px;
  padding: 0 22px 0 37px;
  background-color: #fff;
  box-shadow: 0 0.05rem 0.01rem rgba(75, 75, 90, 0.075);
  /* Search */
}
.has-notification .navbar-custom {
  top: 30px;
}
.has-notification .has-notification .navbar-custom {
  top: calc(30px + 30px);
}
.navbar-custom .topnav-menu > li {
  float: left;
}
.navbar-custom .topnav-menu .nav-link {
  display: block;
  min-width: 32px;
  max-height: 72px;
  padding: 0 15px;
  line-height: 72px;
  color: #4b4b5a;
  text-align: center;
}
.navbar-custom .topnav-menu .nav-link svg {
  display: inline-block;
  width: 20px;
  height: 20px;
  color: #4b4b5a;
  fill: rgba(75, 75, 90, 0.12);
}
.navbar-custom .topnav-menu .nav-link:hover svg, .navbar-custom .topnav-menu .nav-link:focus svg, .navbar-custom .topnav-menu .nav-link:active svg {
  color: #4284f4;
  fill: rgba(66, 132, 244, 0.1);
}
.navbar-custom .dropdown.show .nav-link {
  background-color: rgba(255, 255, 255, 0.05);
}
.navbar-custom .menu-left {
  overflow: hidden;
}
.navbar-custom .app-search {
  padding: 17px 0;
  margin-right: 15px;
  margin-left: 15px;
  overflow: hidden;
}
.navbar-custom .app-search form {
  max-width: 320px;
}
.navbar-custom .app-search .form-control {
  height: 38px;
  padding-right: 20px;
  padding-left: 40px;
  background-color: #eff1f5;
  border: none;
  border-radius: 0.3rem;
  box-shadow: none;
}
.navbar-custom .app-search svg {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10;
  width: 20px;
  height: 20px;
  font-size: 18px;
  line-height: 38px;
  color: #adb5bd;
}
.navbar-custom .button-menu-mobile {
  display: block;
  width: 60px;
  height: 72px;
  margin-right: 10px;
  font-size: 24px;
  line-height: 72px;
  color: #adb5bd;
  cursor: pointer;
  background-color: transparent;
  border: none;
}
.navbar-custom .button-menu-mobile .menu-icon {
  display: inline-block;
}
.navbar-custom .button-menu-mobile .close-icon {
  display: none;
}
.navbar-custom .button-menu-mobile.open .menu-icon {
  display: none;
}
.navbar-custom .button-menu-mobile.open .close-icon {
  display: inline-block;
}
.navbar-custom .logo span.logo-sm {
  display: none;
}

.text-logo {
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Notification */
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.noti-scroll {
  max-height: 230px;
}

.notification-list {
  margin-left: 0;
}
.notification-list .noti-title {
  padding: 7px 20px 15px;
  background-color: transparent;
}
.notification-list .noti-icon {
  font-size: 21px;
  vertical-align: middle;
}
.notification-list .noti-icon-badge {
  position: absolute;
  top: 24px;
  right: 16px;
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: #ea4335;
  border: 2px solid #fff;
  border-radius: 50%;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  animation: blinker 3s linear infinite;
  animation-fill-mode: initial;
}
.notification-list .b-dropdown-text {
  padding: 0;
}
.notification-list .notify-item {
  padding: 12px 20px;
}
.notification-list .notify-item .notify-icon {
  float: left;
  width: 36px;
  height: 36px;
  margin-right: 10px;
  font-size: 16px;
  line-height: 36px;
  color: #fff;
  text-align: center;
  border-radius: 50%;
}
.notification-list .notify-item .notify-details {
  margin-bottom: 5px;
  margin-left: 45px;
  overflow: hidden;
  line-height: 1.5;
  color: #343a40;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.notification-list .notify-item .notify-details b {
  font-weight: 500;
}
.notification-list .notify-item .notify-details small {
  display: block;
}
.notification-list .notify-item .notify-details span {
  display: block;
  overflow: hidden;
  font-size: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.notification-list .notify-item .user-msg {
  margin-left: 45px;
  line-height: 16px;
  white-space: normal;
}
.notification-list .profile-dropdown-items .notify-item {
  padding: 0.35rem 1.5rem;
}

.profile-dropdown i.feather {
  display: inline-flex;
}

.profile-dropdown-items {
  width: 170px;
}
.profile-dropdown-items i {
  margin-right: 5px;
  vertical-align: middle;
}

.nav-user {
  padding: 0 12px !important;
}
.nav-user img {
  width: 32px;
  height: 32px;
}

@media (max-width: 1024px) {
  .navbar-custom {
    left: 0;
    padding: 0 10px;
    margin: 0;
  }
  .navbar-custom .button-menu-mobile.disable-btn {
    display: block;
  }
  .navbar-custom .logo {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .navbar-custom .page-title {
    display: none;
  }
}
/* 5. Page Head */
.page-title-topbar {
  margin: 0;
  font-size: 20px;
  line-height: 72px;
}

.page-title {
  padding: 20px 0;
}
.page-title .breadcrumb {
  padding: 0;
  margin-bottom: 0;
  background-color: transparent;
}
.page-title h4 {
  font-size: 19px;
}

/* 6. Footer */
.footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 250px;
  padding: 19px 15px 20px;
  color: #6c757d;
}
.footer .footer-links a {
  margin-left: 1.5rem;
  color: #6c757d;
  transition: all 0.4s;
}
.footer .footer-links a:hover {
  color: #212529;
}
.footer .footer-links a:first-of-type {
  margin-left: 0;
}

.footer-alt {
  left: 0 !important;
  text-align: center;
  background-color: transparent;
}

@media (max-width: 767.98px) {
  .footer {
    left: 0 !important;
    text-align: center;
  }
}
/* 7. Horizontal Nav */
body[data-layout=topnav] .container-fluid, body[data-layout=topnav] .container-sm, body[data-layout=topnav] .container-md, body[data-layout=topnav] .container-lg, body[data-layout=topnav] .container-xl {
  padding-right: 24px;
  padding-left: 24px;
}
body[data-layout=topnav] .navbar-custom {
  position: relative;
  padding: 0;
}
body[data-layout=topnav] .navbar-custom .topnav-menu .nav-link:hover svg, body[data-layout=topnav] .navbar-custom .topnav-menu .nav-link:focus svg, body[data-layout=topnav] .navbar-custom .topnav-menu .nav-link:active svg {
  color: #4b4b5a;
}
body[data-layout=topnav] .content-page {
  padding: 5px 0 5px 0;
  margin-top: 0;
  margin-left: 0 !important;
}
body[data-layout=topnav] .footer {
  left: 0;
}
body[data-layout=topnav] .user-profile {
  padding: 20px 0;
}
body[data-layout=topnav] .profile-dropdown {
  display: block;
}

.topnav {
  background: #fff;
}

.topbar-nav {
  padding: 0;
  margin: 0;
}
.topbar-nav .badge {
  display: none;
}
.topbar-nav .metismenu {
  display: flex;
  flex-direction: column;
  margin: 0;
}
.topbar-nav .metismenu .has-arrow::after {
  right: 1rem;
  width: 0.4em;
  height: 0.4em;
  transform: rotate(45deg) translate(0, -50%);
}
.topbar-nav .metismenu .menu-title {
  display: none;
}
.topbar-nav .metismenu li:hover, .topbar-nav .metismenu li:focus, .topbar-nav .metismenu li:active {
  color: #4284f4;
  text-decoration: none;
}
.topbar-nav .metismenu li:hover svg, .topbar-nav .metismenu li:focus svg, .topbar-nav .metismenu li:active svg {
  color: #4284f4;
  fill: rgba(66, 132, 244, 0.1);
}
.topbar-nav .metismenu li a {
  position: relative;
  display: block;
  padding: 1rem;
  font-size: 1rem;
  color: #4b4b5a;
}
.topbar-nav .metismenu li a i {
  margin-top: -1px;
  vertical-align: middle;
}
.topbar-nav .metismenu li a svg {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 5px 0 0;
  color: #4b4b5a;
  fill: rgba(75, 75, 90, 0.12);
}
.topbar-nav .metismenu li a > span {
  vertical-align: middle;
}
.topbar-nav .metismenu li a:hover, .topbar-nav .metismenu li a:focus, .topbar-nav .metismenu li a:active {
  color: #4284f4;
  text-decoration: none;
}
.topbar-nav .metismenu li a:hover svg, .topbar-nav .metismenu li a:focus svg, .topbar-nav .metismenu li a:active svg {
  color: #4284f4;
  fill: rgba(66, 132, 244, 0.1);
}
.topbar-nav .metismenu li:nth-child(2) a {
  padding-left: 0;
}
.topbar-nav .metismenu li .menu-arrow {
  display: inline-block;
  margin-left: 5px;
  font-family: "unicons";
  font-size: 1.25rem;
  line-height: 1.3rem;
  vertical-align: middle;
  transition: transform 0.15s;
  transform: translate(0, 0);
  text-rendering: auto;
}
.topbar-nav .metismenu li .menu-arrow::before {
  content: "";
}
.topbar-nav .metismenu li.mm-active > a {
  color: #4284f4;
}
.topbar-nav .metismenu li.mm-active > a svg {
  color: #4284f4;
  fill: rgba(66, 132, 244, 0.1);
}
.topbar-nav .metismenu li.mm-active > a > span.menu-arrow {
  transform: rotate(180deg);
}
.topbar-nav .metismenu li.active > a {
  color: #4284f4;
}
.topbar-nav .metismenu li.active > a svg {
  color: #4284f4;
  fill: rgba(66, 132, 244, 0.1);
}
.topbar-nav .metismenu li .nav-second-level {
  min-width: calc(10rem + 1.5rem);
  padding: 0.5rem 0;
  color: #4b4b5a;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0 0 0.2rem 0.2rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
  font-size: calc(0.875rem - 0.01rem);
}
.topbar-nav .metismenu li .nav-second-level li:hover, .topbar-nav .metismenu li .nav-second-level li:focus, .topbar-nav .metismenu li .nav-second-level li:active {
  color: #4284f4;
  text-decoration: none;
}
.topbar-nav .metismenu li .nav-second-level li:first-of-type {
  padding-top: 0.5rem;
}
.topbar-nav .metismenu li .nav-second-level li:last-of-type {
  padding-bottom: 0.5rem;
}
.topbar-nav .metismenu li .nav-second-level a {
  display: block;
  width: 100%;
  padding: 0.35rem 1.5rem !important;
  clear: both;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.topbar-nav .metismenu li .nav-second-level a:hover, .topbar-nav .metismenu li .nav-second-level a:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.topbar-nav .metismenu li .nav-second-level .has-arrow::after {
  margin-top: -2px;
}
.topbar-nav .metismenu li .nav-second-level .menu-arrow {
  float: right;
}
.topbar-nav .metismenu li .nav-third-level {
  min-width: calc(10rem + 1.5rem);
  color: #4b4b5a;
}
.topbar-nav .metismenu li .nav-third-level li:hover, .topbar-nav .metismenu li .nav-third-level li:focus, .topbar-nav .metismenu li .nav-third-level li:active {
  color: #4284f4;
  text-decoration: none;
}
.topbar-nav .metismenu li .nav-third-level li:first-of-type {
  padding-top: 0.5rem;
}
.topbar-nav .metismenu li .nav-third-level li:last-of-type {
  padding-bottom: 0.5rem;
}
.topbar-nav .metismenu li .nav-third-level a {
  display: block;
  width: 100%;
  padding: 0.35rem 1.5rem !important;
  padding-left: 2.2rem !important;
  clear: both;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.topbar-nav .metismenu li .nav-third-level a:hover, .topbar-nav .metismenu li .nav-third-level a:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.topbar-nav .metismenu li .nav-third-level .has-arrow::after {
  margin-top: -2px;
}
.topbar-nav .metismenu li .nav-third-level .menu-arrow {
  float: right;
}

@media (min-width: 992px) {
  body[data-layout=topnav] .container-fluid, body[data-layout=topnav] .container-sm, body[data-layout=topnav] .container-md, body[data-layout=topnav] .container-lg, body[data-layout=topnav] .container-xl {
    max-width: 85%;
  }
  body[data-layout=topnav] .navbar-custom .logo {
    display: block;
  }
  body[data-layout=topnav] .navbar-custom .logo span.logo-sm {
    display: none !important;
  }
  body[data-layout=topnav] .navbar-custom .logo span.logo-lg {
    display: block;
  }
  body[data-layout=topnav].left-side-menu-condensed .navbar-custom .button-menu-mobile.disable-btn {
    display: none !important;
  }
  body[data-layout=topnav].boxed-layout .container-fluid, body[data-layout=topnav].boxed-layout .container-sm, body[data-layout=topnav].boxed-layout .container-md, body[data-layout=topnav].boxed-layout .container-lg, body[data-layout=topnav].boxed-layout .container-xl {
    max-width: 97%;
  }

  .topbar-nav .metismenu {
    flex-direction: row;
  }
  .topbar-nav .metismenu > li {
    flex-direction: column;
  }
  .topbar-nav .metismenu > li > ul {
    position: absolute;
    z-index: 1001;
  }
  .topbar-nav .metismenu .side-nav-item:first-of-type .side-nav-link {
    padding-left: 0;
  }

  .navbar-toggle {
    display: none;
  }
}
@media (max-width: 991.98px) {
  body[data-layout=topnav] .navbar-custom .container-fluid, body[data-layout=topnav] .navbar-custom .container-sm, body[data-layout=topnav] .navbar-custom .container-md, body[data-layout=topnav] .navbar-custom .container-lg, body[data-layout=topnav] .navbar-custom .container-xl {
    padding-right: 12px;
    padding-left: 12px;
  }

  .topnav {
    overflow: auto;
  }

  .topbar-nav {
    max-height: 400px;
  }
  .topbar-nav .metismenu li a {
    padding: 15px 0;
  }
  .topbar-nav .metismenu li .nav-second-level {
    padding-top: 0;
    box-shadow: none;
  }
  .topbar-nav .metismenu li .nav-second-level li:first-of-type {
    padding: 0;
  }
  .topbar-nav .metismenu li .nav-second-level a {
    padding-right: 0 !important;
  }
  .topbar-nav .metismenu li .nav-second-level a:hover, .topbar-nav .metismenu li .nav-second-level a:focus {
    background-color: transparent;
  }
  .topbar-nav .metismenu li .menu-arrow {
    float: right;
    margin-top: 2px;
  }
}
@media (min-width: 992px) and (max-width: 1180px) {
  .topbar-nav .metismenu li .menu-arrow {
    display: none;
  }
}
/* 8. Right Sidebar */
.right-bar {
  position: fixed;
  top: 0;
  right: -270px;
  bottom: 0;
  z-index: 9999;
  display: block;
  float: right !important;
  width: 260px;
  padding-bottom: 60px;
  background-color: #fff;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
  transition: all 200ms ease-out;
}
.right-bar .rightbar-title {
  padding: 25px 25px;
}
.right-bar .right-bar-toggle {
  width: 24px;
  height: 24px;
  margin-top: -4px;
  line-height: 24px;
  text-align: center;
  border-radius: 50%;
}
.right-bar .demo-img {
  height: 200px;
}

.rightbar-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9998;
  display: none;
  background-color: rgba(52, 58, 64, 0.55);
  transition: all 0.2s ease-out;
}

.right-bar-enabled .right-bar {
  right: 0;
}
.right-bar-enabled .rightbar-overlay {
  display: block;
}

@media (max-width: 767.98px) {
  .right-bar {
    overflow: auto;
  }
  .right-bar .slimscroll-menu {
    height: auto !important;
  }
}
.activity-widget .activity-list {
  padding: 12px 0;
  border-bottom: 1px solid #f6f6f7;
}
.activity-widget .activity-list:last-child {
  border: none;
}

.upgrade-wid .laptop-img {
  position: relative;
  bottom: 22px;
}

/* 9. Layouts */
@media (min-width: 1200px) {
  .scrollable-layout {
    padding-bottom: 0;
  }
  .scrollable-layout #wrapper {
    display: flex;
  }
  .scrollable-layout .left-side-menu {
    position: relative;
    min-width: 250px;
    min-height: 100vh;
  }
  .scrollable-layout .navbar-custom {
    position: absolute;
  }
  .scrollable-layout .content-page {
    width: 100%;
    padding-bottom: 60px;
    margin-left: 0;
  }
  .scrollable-layout.left-side-menu-condensed .left-side-menu {
    min-width: 70px;
  }
  .scrollable-layout.left-side-menu-condensed .content-page {
    margin-left: 0 !important;
  }
}
.boxed-layout {
  background: #e7e9f0;
}
.boxed-layout #wrapper {
  max-width: 1300px;
  margin: 0 auto;
  background: #edeff4;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.boxed-layout .navbar-custom {
  max-width: 1300px;
  margin: 0 auto;
}
.boxed-layout .footer {
  max-width: calc(1300px - 250px);
  margin: 0 auto;
  background: #edeff4;
}
.boxed-layout.left-side-menu-condensed {
  min-height: auto;
}
.boxed-layout.left-side-menu-condensed .footer {
  max-width: 1300px;
}

/* 10. Helper */
.width-xs {
  min-width: 80px;
}

.width-sm {
  min-width: 95px;
}

.width-md {
  min-width: 110px;
}

.width-lg {
  min-width: 140px;
}

.width-xl {
  min-width: 160px;
}

.font-family-secondary {
  font-family: "Nunito Sans", sans-serif;
}

.avatar-xs {
  width: 1.5rem;
  height: 1.5rem;
}

.avatar-sm {
  width: 2.25rem;
  height: 2.25rem;
}

.avatar {
  width: 3rem;
  height: 3rem;
}

.avatar-md {
  width: 3.5rem;
  height: 3.5rem;
}

.avatar-lg {
  width: 4.5rem;
  height: 4.5rem;
}

.avatar-xl {
  width: 6rem;
  height: 6rem;
}

.avatar-xxl {
  width: 7.5rem;
  height: 7.5rem;
}

.avatar-title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #fff;
}

.avatar-group {
  padding-left: 12px;
}
.avatar-group .avatar-group-item {
  display: inline-block;
  margin: 0 0 10px -12px;
  border: 2px solid #fff;
  border-radius: 50%;
}

.font-weight-medium {
  font-weight: 500;
}

.font-weight-semibold {
  font-weight: 600;
}

.sp-line-1,
.sp-line-2,
.sp-line-3,
.sp-line-4 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.sp-line-1 {
  -webkit-line-clamp: 1;
}

.sp-line-2 {
  -webkit-line-clamp: 2;
}

.sp-line-3 {
  -webkit-line-clamp: 3;
}

.sp-line-4 {
  -webkit-line-clamp: 4;
}

.pull-in {
  margin-right: -1.25rem;
  margin-left: -1.25rem;
}

/* 11. Social */
.social-list-item {
  display: block;
  width: 2rem;
  height: 2rem;
  line-height: calc(2rem - 4px);
  color: #adb5bd;
  border: 2px solid #adb5bd;
  border-radius: 50%;
}

/* 12. Widgets */
.widget-flat {
  position: relative;
  overflow: hidden;
}
.widget-flat i.widget-icon {
  font-size: 36px;
}

/* Chat widget */
.conversation-list {
  height: 332px;
  padding: 0 7px;
  list-style: none;
}
.conversation-list li {
  padding-bottom: 24px;
}
.conversation-list .chat-avatar {
  float: left;
  width: 34px;
  text-align: center;
}
.conversation-list .chat-avatar img {
  width: 100%;
  border-radius: 100%;
}
.conversation-list .chat-avatar i {
  font-style: normal;
}
.conversation-list .ctext-wrap {
  position: relative;
  display: inline-block;
  padding: 4px 12px;
  color: #fff;
  background: #4284f4;
  border-radius: 0.3rem;
  border-top-left-radius: 0;
  cursor: pointer;
}
.conversation-list .ctext-wrap i {
  position: relative;
  display: block;
  font-style: normal;
  font-weight: 700;
}
.conversation-list .ctext-wrap p {
  padding-top: 3px;
  margin: 0;
  white-space: pre-wrap;
  word-break: break-word;
  text-align: left;
}
.conversation-list .ctext-wrap::after {
  position: absolute;
  top: 0;
  right: 99%;
  width: 0;
  height: 0;
  margin-left: -1px;
  pointer-events: none;
  content: " ";
  border: solid transparent;
  border-width: 6px;
  border-top-color: #4284f4;
  border-right-color: #4284f4;
}
.conversation-list .conversation-text {
  float: left;
  width: 70%;
  margin-left: 17px;
}
.conversation-list .conversation-text .timestamp {
  font-size: 12px;
}
.conversation-list .conversation-text a {
  color: #fff;
}
.conversation-list .conversation-text a:hover {
  text-decoration: underline !important;
}
.conversation-list .odd .chat-avatar {
  float: right !important;
}
.conversation-list .odd .conversation-text {
  float: right !important;
  width: 55% !important;
  margin-right: 17px;
  text-align: right;
}
.conversation-list .odd .ctext-wrap {
  color: #4b4b5a;
  background-color: #e2e7f1;
  border-top-right-radius: 0;
}
.conversation-list .odd .ctext-wrap::after {
  left: 99% !important;
  border-color: transparent;
  border-top-color: #e2e7f1;
  border-left-color: #e2e7f1;
}
.conversation-list .odd .ctext-wrap a {
  color: #4284f4;
}
.conversation-list .odd .ctext-wrap.email {
  background-color: #4284f4;
  color: #fff;
}
.conversation-list .odd .ctext-wrap.email a {
  color: #fff;
}
.conversation-list .odd .ctext-wrap.email::after {
  border-top-color: #4284f4;
  border-left-color: #4284f4;
}
.conversation-list .broadcast .ctext-wrap {
  color: #fff;
  background-color: #34a853 !important;
}
.conversation-list .broadcast .ctext-wrap a {
  color: #fff;
}
.conversation-list .broadcast .ctext-wrap::after {
  border-top-color: #34a853 !important;
  border-left-color: #34a853 !important;
}

/* profile / users */
.profile-widget {
  position: relative;
}
.profile-widget .card-action {
  position: absolute;
  top: 12px;
  right: 7px;
  z-index: 100;
}
.profile-widget .card-img-top {
  max-height: 220px;
}
.profile-widget .profile-info {
  margin-top: -2.7rem;
}

.calendar-widget .flatpickr-calendar {
  border: 1px solid #f6f6f7 !important;
  box-shadow: none;
}

.calendar-widget-inline .flatpickr-input {
  display: none;
}

/* 13. Custom Checkbox */
.checkbox label {
  position: relative;
  display: inline-block;
  padding-left: 8px;
  margin-bottom: 0;
  font-weight: normal;
}
.checkbox label::before {
  position: absolute;
  top: 2px;
  left: 0;
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-left: -18px;
  content: "";
  background-color: #e2e7f1;
  border: 2px solid #e2e7f1;
  border-radius: 3px;
  outline: none !important;
  transition: 0.3s ease-in-out;
}
.checkbox label::after {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 18px;
  height: 18px;
  padding-top: 2px;
  padding-left: 3px;
  margin-left: -18px;
  font-size: 11px;
  color: #4b4b5a;
}
.checkbox input[type=checkbox] {
  z-index: 1;
  cursor: pointer;
  outline: none !important;
  opacity: 0;
}
.checkbox input[type=checkbox]:disabled + label {
  opacity: 0.65;
}
.checkbox input[type=checkbox]:focus + label::before {
  outline: none;
  outline-offset: -2px;
}
.checkbox input[type=checkbox]:checked + label::after {
  position: absolute;
  top: 6px;
  left: 7px;
  display: table;
  width: 4px;
  height: 8px;
  content: "";
  border: 2px solid #4b4b5a;
  border-top-width: 0;
  border-left-width: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.checkbox input[type=checkbox]:disabled + label::before {
  cursor: not-allowed;
  background-color: #f8f9fa;
}

.checkbox.checkbox-circle label::before {
  border-radius: 50%;
}

.checkbox.checkbox-inline {
  margin-top: 0;
}

.checkbox.checkbox-single input {
  position: absolute;
  width: 18px;
  height: 18px;
}
.checkbox.checkbox-single label {
  width: 18px;
  height: 18px;
}
.checkbox.checkbox-single label::before {
  margin-left: 0;
}
.checkbox.checkbox-single label::after {
  margin-left: 0;
}

.checkbox-primary input[type=checkbox]:checked + label::before {
  background-color: #4284f4;
  border-color: #4284f4;
}
.checkbox-primary input[type=checkbox]:checked + label::after {
  border-color: #fff;
}

.checkbox-secondary input[type=checkbox]:checked + label::before {
  background-color: #34a853;
  border-color: #34a853;
}
.checkbox-secondary input[type=checkbox]:checked + label::after {
  border-color: #fff;
}

.checkbox-success input[type=checkbox]:checked + label::before {
  background-color: #34a853;
  border-color: #34a853;
}
.checkbox-success input[type=checkbox]:checked + label::after {
  border-color: #fff;
}

.checkbox-info input[type=checkbox]:checked + label::before {
  background-color: #25c2e3;
  border-color: #25c2e3;
}
.checkbox-info input[type=checkbox]:checked + label::after {
  border-color: #fff;
}

.checkbox-warning input[type=checkbox]:checked + label::before {
  background-color: #ffbe0b;
  border-color: #ffbe0b;
}
.checkbox-warning input[type=checkbox]:checked + label::after {
  border-color: #fff;
}

.checkbox-danger input[type=checkbox]:checked + label::before {
  background-color: #ea4335;
  border-color: #ea4335;
}
.checkbox-danger input[type=checkbox]:checked + label::after {
  border-color: #fff;
}

.checkbox-light input[type=checkbox]:checked + label::before {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.checkbox-light input[type=checkbox]:checked + label::after {
  border-color: #fff;
}

.checkbox-dark input[type=checkbox]:checked + label::before {
  background-color: #343a40;
  border-color: #343a40;
}
.checkbox-dark input[type=checkbox]:checked + label::after {
  border-color: #fff;
}

/* 14. Custom Radio */
.radio label {
  position: relative;
  display: inline-block;
  padding-left: 8px;
  margin-bottom: 0;
  font-weight: normal;
}
.radio label::before {
  position: absolute;
  left: 0;
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-left: -18px;
  content: "";
  background-color: #fff;
  border: 2px solid #6c757d;
  border-radius: 50%;
  outline: none !important;
  -o-transition: border 0.5s ease-in-out;
  -webkit-transition: border 0.5s ease-in-out;
  transition: border 0.5s ease-in-out;
}
.radio label::after {
  position: absolute;
  top: 4px;
  left: 6px;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: -20px;
  content: " ";
  background-color: #4b4b5a;
  border-radius: 50%;
  -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  -webkit-transform: scale(0, 0);
  transform: scale(0, 0);
}
.radio input[type=radio] {
  z-index: 1;
  cursor: pointer;
  outline: none !important;
  opacity: 0;
}
.radio input[type=radio]:disabled + label {
  opacity: 0.65;
}
.radio input[type=radio]:focus + label::before {
  outline: 5px auto -webkit-focus-ring-color;
  outline: thin dotted;
  outline-offset: -2px;
}
.radio input[type=radio]:checked + label::after {
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}
.radio input[type=radio]:disabled + label::before {
  cursor: not-allowed;
}

.radio.radio-inline {
  margin-top: 0;
}

.radio.radio-single label {
  height: 17px;
}

.radio-primary input[type=radio] + label::after {
  background-color: #4284f4;
}
.radio-primary input[type=radio]:checked + label::before {
  border-color: #4284f4;
}
.radio-primary input[type=radio]:checked + label::after {
  background-color: #4284f4;
}

.radio-secondary input[type=radio] + label::after {
  background-color: #34a853;
}
.radio-secondary input[type=radio]:checked + label::before {
  border-color: #34a853;
}
.radio-secondary input[type=radio]:checked + label::after {
  background-color: #34a853;
}

.radio-success input[type=radio] + label::after {
  background-color: #34a853;
}
.radio-success input[type=radio]:checked + label::before {
  border-color: #34a853;
}
.radio-success input[type=radio]:checked + label::after {
  background-color: #34a853;
}

.radio-info input[type=radio] + label::after {
  background-color: #25c2e3;
}
.radio-info input[type=radio]:checked + label::before {
  border-color: #25c2e3;
}
.radio-info input[type=radio]:checked + label::after {
  background-color: #25c2e3;
}

.radio-warning input[type=radio] + label::after {
  background-color: #ffbe0b;
}
.radio-warning input[type=radio]:checked + label::before {
  border-color: #ffbe0b;
}
.radio-warning input[type=radio]:checked + label::after {
  background-color: #ffbe0b;
}

.radio-danger input[type=radio] + label::after {
  background-color: #ea4335;
}
.radio-danger input[type=radio]:checked + label::before {
  border-color: #ea4335;
}
.radio-danger input[type=radio]:checked + label::after {
  background-color: #ea4335;
}

.radio-light input[type=radio] + label::after {
  background-color: #f8f9fa;
}
.radio-light input[type=radio]:checked + label::before {
  border-color: #f8f9fa;
}
.radio-light input[type=radio]:checked + label::after {
  background-color: #f8f9fa;
}

.radio-dark input[type=radio] + label::after {
  background-color: #343a40;
}
.radio-dark input[type=radio]:checked + label::before {
  border-color: #343a40;
}
.radio-dark input[type=radio]:checked + label::after {
  background-color: #343a40;
}

/* 15. Print */
@media print {
  .left-side-menu,
.right-bar,
.page-title-box,
.navbar-custom,
.footer {
    display: none;
  }

  .card-body,
.content-page,
.right-bar,
.content,
body {
    padding: 0;
    margin: 0;
  }
}
/* 16. Preloader */
.preloader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background-color: #edeff4;
}

.status {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 80px;
  margin: -40px 0 0 -40px;
}

.spinner > div {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 7px;
  background: #fff;
  border: 2px solid #4284f4;
  border-radius: 50%;
  animation: circle-loader 1s infinite ease-in-out both;
}
.spinner .circle1 {
  animation-delay: -0.32s;
}
.spinner .circle2 {
  animation-delay: -0.16s;
}

@keyframes circle-loader {
  0%, 80%, 100% {
    transform: scale(0.4);
  }
  40% {
    transform: scale(1);
  }
}
/* 17. Plugins */
.slimScrollDiv {
  height: auto !important;
}

.ps__rail-x {
  display: none !important;
}

.ps__thumb-y {
  width: 4px !important;
}

.ps__rail-y.ps--clicking .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y:hover > .ps__thumb-y {
  width: 4px !important;
}

.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-x:focus,
.ps .ps__rail-x:hover,
.ps .ps__rail-y.ps--clicking,
.ps .ps__rail-y:focus,
.ps .ps__rail-y:hover {
  background: transparent !important;
}

.apex-charts {
  min-height: 10px !important;
}
.apex-charts text {
  font-family: "Nunito Sans", sans-serif !important;
  fill: #4b4b5a;
}
.apex-charts .apexcharts-canvas {
  margin: 0 auto;
}

.apexcharts-tooltip-title,
.apexcharts-tooltip-text {
  font-family: "Nunito Sans", sans-serif !important;
}

.apexcharts-legend-series {
  font-weight: 400;
}

.apexcharts-gridline {
  pointer-events: none;
  stroke: #f8f9fa;
}

.apexcharts-legend-text {
  font-family: "Nunito Sans", sans-serif !important;
  font-size: 13px !important;
  color: #6c757d !important;
}

.apexcharts-pie-label {
  fill: #fff !important;
}

.apexcharts-yaxis text,
.apexcharts-xaxis text {
  font-family: "Nunito Sans", sans-serif !important;
  fill: #adb5bd;
}

.apexcharts-point-annotations text,
.apexcharts-xaxis-annotations text,
.apexcharts-yaxis-annotations text {
  fill: #fff;
}

.apexcharts-radar-series polygon {
  fill: transparent;
  stroke: #e2e7f1;
}
.apexcharts-radar-series line {
  stroke: #e2e7f1;
}

.apexcharts-pie-label,
.apexcharts-datalabel,
.apexcharts-datalabel-label,
.apexcharts-datalabel-value {
  fill: #fff !important;
}

.calendar {
  float: left;
  margin-bottom: 0;
}

.fc-view {
  margin-top: 30px;
}

.none-border .modal-footer {
  border-top: none;
}

.fc-toolbar {
  margin: 10px 0 5px 0;
}
.fc-toolbar h2 {
  font-size: 1.25rem;
  line-height: 1.875rem;
  text-transform: uppercase;
}

.fc-day-grid-event .fc-time {
  font-weight: 500;
}

th.fc-day-header {
  padding: 0.5rem 0;
}

.fc-day {
  background: transparent;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
  z-index: 0;
}

.fc th.fc-widget-header {
  padding: 10px 0;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
  background: #e2e7f1;
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
  border-color: #e2e7f1;
}
.fc-unthemed td.fc-today,
.fc-unthemed .fc-divider {
  background: #e2e7f1;
}

.fc-button {
  height: auto;
  padding: 6px 12px;
  margin: 0 3px;
  color: #4b4b5a;
  text-transform: capitalize;
  background: #e2e7f1;
  border: none;
  border-radius: 3px;
  box-shadow: none;
}

.fc-text-arrow {
  font-family: inherit;
  font-size: 1rem;
}

.fc-state-hover {
  background: #e2e7f1;
}

.fc-state-highlight {
  background: #e2e7f1;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  color: #fff;
  text-shadow: none;
  background-color: #4284f4;
}

.fc-cell-overlay {
  background: #e2e7f1;
}

.fc-unthemed .fc-today {
  background: #fff;
}

.fc-event {
  padding: 5px 5px !important;
  margin: 5px 7px !important;
  font-size: 0.8125rem !important;
  text-align: center !important;
  cursor: move !important;
  border: none !important;
  border-radius: 2px !important;
}

.external-event {
  padding: 8px 10px;
  margin: 10px 0;
  color: #fff;
  cursor: move;
  border-radius: 4px;
}

.fc-basic-view td.fc-week-number span {
  padding-right: 8px;
}
.fc-basic-view td.fc-day-number {
  padding-right: 8px;
}
.fc-basic-view .fc-content {
  color: #fff;
}

.fc-time-grid-event .fc-content {
  color: #fff;
}

@media (max-width: 767.98px) {
  .fc-toolbar {
    display: block;
  }
  .fc-toolbar .fc-left,
.fc-toolbar .fc-right,
.fc-toolbar .fc-center {
    display: block;
    float: none;
    margin: 10px 0;
    clear: both;
  }

  .fc .fc-toolbar > * > * {
    float: none;
  }

  .fc-today-button {
    display: none;
  }
}
.fc-toolbar .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.is-invalid .multiselect__tags {
  border: 1px solid #ff5c75 !important;
}

.multiselect__tags {
  min-height: calc( 1.5em + 1rem + 2px ) !important;
  padding: 0.5rem 0.75rem !important;
  font-family: "Nunito Sans", sans-serif !important;
  font-size: 0.875rem;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #4b4b5a !important;
  background-color: #fff !important;
  background-clip: padding-box;
  border: 1px solid #e2e7f1 !important;
}

.multiselect__option--highlight,
.multiselect__option--highlight::after {
  background: #4284f4 !important;
}

.multiselect__placeholder {
  padding-top: 0 !important;
  margin-bottom: 0 !important;
}

.multiselect__input {
  margin-bottom: 0 !important;
}

.multiselect__single {
  padding-left: 0 !important;
  margin-bottom: 0 !important;
}

.multiselect__tag {
  margin-bottom: 0 !important;
  background-color: #4284f4 !important;
}

.multiselect__tag-icon::after {
  color: #f8f9fa !important;
}

.ms-container {
  width: auto;
  max-width: 370px;
  background: transparent url("~~~@assets/images/plugin/multiple-arrow.png") no-repeat 50% 50%;
}
.ms-container .ms-list {
  border: 1px solid #e2e7f1;
  box-shadow: none;
}
.ms-container .ms-list.ms-focus {
  border: 1px solid #4284f4;
  box-shadow: none;
}
.ms-container .ms-selectable {
  background-color: #fff;
}
.ms-container .ms-selectable li.ms-elem-selectable {
  padding: 5px 10px;
  color: #6c757d;
  border: none;
}
.ms-container .ms-selectable li.ms-hover {
  color: #fff;
  background-color: #4284f4;
}
.ms-container .ms-selection {
  background-color: #fff;
}
.ms-container .ms-selection li.ms-elem-selection {
  padding: 5px 10px;
  color: #6c757d;
  border: none;
}
.ms-container .ms-selection li.ms-hover {
  color: #fff;
  background-color: #4284f4;
}

.ms-selectable {
  outline: none !important;
  box-shadow: none;
}

.ms-optgroup-label {
  font-family: "Nunito Sans", sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: #343a40 !important;
}

.flatpickr-calendar {
  overflow: hidden;
  background-color: #fff !important;
  border: 2px solid #e2e7f1 !important;
  box-shadow: none !important;
}
.flatpickr-calendar .flatpickr-time {
  border: none !important;
}
.flatpickr-calendar .flatpickr-time input,
.flatpickr-calendar .flatpickr-time .flatpickr-am-pm {
  color: #6c757d;
}
.flatpickr-calendar .flatpickr-time input:focus, .flatpickr-calendar .flatpickr-time input:hover,
.flatpickr-calendar .flatpickr-time .flatpickr-am-pm:focus,
.flatpickr-calendar .flatpickr-time .flatpickr-am-pm:hover {
  background: #e2e7f1;
}

.flatpickr-day {
  color: #6c757d !important;
}
.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  color: #fff !important;
  background: #4284f4 !important;
  border-color: #4284f4 !important;
}
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: -10px 0 0 #4284f4 !important;
}
.flatpickr-day.today {
  border-color: #e2e7f1 !important;
}
.flatpickr-day.inRange {
  background: whitesmoke !important;
  border-color: whitesmoke !important;
  box-shadow: -10px 0 0 whitesmoke !important;
}
.flatpickr-day.inRange.nextMonthDay {
  background: whitesmoke !important;
  border-color: whitesmoke !important;
}
.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(108, 117, 125, 0.4);
}
.flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.today:hover, .flatpickr-day.today:focus, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
  color: #6c757d;
  background: #e2e7f1 !important;
  border-color: #e2e7f1 !important;
}

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #f8f9fa !important;
}

.flatpickr-months .flatpickr-month {
  height: 48px !important;
}
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  fill: #4b4b5a !important;
}

.flatpickr-current-month {
  padding: 8px 0 0 0 !important;
  font-size: 1rem !important;
  color: #4b4b5a !important;
}
.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  color: #4b4b5a !important;
}

.flatpickr-weekdays {
  background: #4284f4 !important;
}

.flatpickr-weekday {
  color: rgba(255, 255, 255, 0.6) !important;
}

.bootstrap-touchspin .btn .input-group-text {
  padding: 0;
  color: inherit;
  background-color: transparent;
  border: none;
}

.parsley-errors-list {
  padding: 0;
  margin: 0;
}
.parsley-errors-list > li {
  position: relative;
  display: inline-block;
  padding: 4px 7px 4px 28px;
  margin-top: 10px;
  color: #ea4335;
  list-style: none;
  background-color: rgba(234, 67, 53, 0.2);
  border-radius: 7px;
}
.parsley-errors-list > li::before {
  position: absolute;
  top: 4px;
  left: 8px;
  font-family: "unicons";
  content: "";
}
.parsley-errors-list > li::after {
  position: absolute;
  top: -16px;
  left: 14px;
  content: "";
  border: 8px solid transparent;
  border-bottom-color: rgba(234, 67, 53, 0.2);
}

.parsley-error {
  border-color: #ea4335;
}

.parsley-success {
  border-color: #34a853;
}

.wizard-header {
  display: none;
}

.wizard-icon-container.tab_shape:focus,
.wizard-icon-circle.md:focus {
  outline: none !important;
}

.wizard-icon-circle.checked {
  color: #4284f4;
}

.wizard-icon-circle {
  width: 40px !important;
  height: 40px !important;
  font-size: 14px !important;
  font-style: inherit !important;
}

.wizard-navigation .wizard-progress-with-circle {
  top: 28px !important;
}

.wizard-progress-with-circle {
  background: #e2e7f1;
}

.wizard-tab-content {
  padding-top: 40px !important;
}

.wizard-icon-container .wizard-icon {
  font-size: 14px !important;
  font-style: inherit;
}

.vue-dropzone {
  cursor: pointer;
  background: #fff;
  border: 2px dashed rgba(52, 58, 64, 0.3) !important;
  border-radius: 6px;
}
.vue-dropzone .dz-message {
  margin: 3em 0 !important;
  font-size: 1.5rem;
}

.dataTables_wrapper.container-fluid, .dataTables_wrapper.container-sm, .dataTables_wrapper.container-md, .dataTables_wrapper.container-lg, .dataTables_wrapper.container-xl {
  padding: 0;
}

table.dataTable {
  margin-bottom: 15px !important;
  border-collapse: collapse !important;
}
table.dataTable tbody > tr.selected,
table.dataTable tbody > tr > .selected {
  background-color: #4284f4;
}
table.dataTable tbody > tr.selected td,
table.dataTable tbody > tr > .selected td {
  border-color: #4284f4;
}
table.dataTable tbody td:focus {
  outline: none !important;
}
table.dataTable tbody th.focus,
table.dataTable tbody td.focus {
  color: #4284f4;
  background-color: rgba(66, 132, 244, 0.15);
  outline: 2px solid #4284f4 !important;
  outline-offset: -1px;
}

.dataTables_info {
  font-weight: 500;
}

table.dataTable.dtr-inline.collapsed > tbody > tr[role=row] > td:first-child::before,
table.dataTable.dtr-inline.collapsed > tbody > tr[role=row] > th:first-child::before {
  top: 0.75rem;
  background-color: #34a853;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
}
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child::before,
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child::before {
  top: 0.75rem;
  background-color: #ea4335;
}

div.dt-button-info {
  z-index: 21;
  color: #fff;
  text-align: center;
  background-color: #4284f4;
  border: none;
  border-radius: 3px;
  box-shadow: none;
}
div.dt-button-info h2 {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  border-bottom: none;
}

@media (max-width: 767.98px) {
  li.paginate_button.previous,
li.paginate_button.next {
    display: inline-block;
    font-size: 1.5rem;
  }

  li.paginate_button {
    display: none;
  }

  .dataTables_paginate ul {
    margin: 1rem 0 0 !important;
    text-align: center;
  }

  div.dt-buttons {
    display: inline-table;
    margin-bottom: 1rem;
  }
}
.activate-select .sorting_1 {
  background-color: #f8f9fa;
}

#pagination-list .pagination li a {
  display: block;
  padding: 0.5rem 0.75rem;
  margin: 0 3px;
  line-height: 1.25;
  border-radius: 50%;
}
#pagination-list .pagination li.active a {
  color: #fff;
  background-color: #4284f4;
}

#transaction-list .sort {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
}

#noresult-list .error-message {
  display: none;
}

.tasklist {
  min-height: 40px;
  margin-bottom: 0;
}
.tasklist > li {
  padding: 20px;
  margin-bottom: 24px;
  background-color: #fff;
  border-radius: 7px;
  box-shadow: 0 0.05rem 0.01rem rgba(75, 75, 90, 0.075);
}
.tasklist > li:last-of-type {
  margin-bottom: 0;
}
.tasklist > li:last-of-type .btn-sm, .tasklist > li:last-of-type .btn-group-sm > .btn {
  padding: 2px 8px;
  font-size: 12px;
}

.task-placeholder {
  padding: 20px;
  background-color: #fff !important;
  border: 1px dashed #e2e7f1 !important;
  box-shadow: none !important;
}

.metismenu {
  padding: 0;
}
.metismenu li {
  list-style: none;
}
.metismenu li a[aria-expanded=true] {
  color: #4284f4 !important;
}
.metismenu ul {
  padding: 0;
}
.metismenu ul li {
  width: 100%;
}
.metismenu .mm-collapse:not(.mm-show) {
  display: none;
}
.metismenu .mm-collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition-timing-function: ease;
  transition-duration: 0.35s;
  transition-property: height, visibility;
}

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1031;
  width: 100%;
  height: 2px;
  background: #4284f4;
}

/* Fancy blur effect */
#nprogress .peg {
  position: absolute;
  right: 0;
  display: block;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #4284f4, 0 0 5px #4284f4;
  opacity: 1;
  -webkit-transform: rotate(3deg) translate(0, -4px);
  -ms-transform: rotate(3deg) translate(0, -4px);
  transform: rotate(3deg) translate(0, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: none;
}

#nprogress .spinner-icon {
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  border: solid 2px transparent;
  border-top-color: #4284f4;
  border-left-color: #4284f4;
  border-radius: 50%;
  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  position: relative;
  overflow: hidden;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ql-container {
  font-family: "Nunito Sans", sans-serif !important;
}
.ql-container.ql-snow {
  border: 1px solid #e2e7f1 !important;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.ql-bubble {
  border: 1px solid #e2e7f1;
  border-radius: 0.3rem;
}

.ql-toolbar {
  font-family: "Nunito Sans", sans-serif !important;
}
.ql-toolbar span {
  outline: none !important;
}
.ql-toolbar.ql-snow {
  border: 1px solid #e2e7f1 !important;
  border-bottom: none !important;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter {
  stroke: #4284f4 !important;
}

/* 18. Components Demo */
.button-list {
  margin-bottom: -12px;
  margin-left: -8px;
}
.button-list .btn {
  margin-bottom: 12px;
  margin-left: 8px;
}

.icons-list-demo div {
  display: block;
  overflow: hidden;
  line-height: 45px;
  color: #6c757d;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.icons-list-demo div p {
  margin-bottom: 0;
  line-height: inherit;
}
.icons-list-demo i {
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-right: 12px;
  font-size: 22px;
  line-height: 50px;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  transition: all 0.2s;
}
.icons-list-demo .col-lg-4 {
  margin-top: 10px;
  background-clip: padding-box;
  border-radius: 3px;
}
.icons-list-demo .col-lg-4:hover i {
  color: #fff;
  text-shadow: 5px 5px rgba(255, 255, 255, 0.2);
  background-color: #4284f4;
}

.icons-list .icon-item {
  padding: 15px 0;
  color: #6c757d;
}
.icons-list .icon-item svg {
  margin-right: 10px;
}
.icons-list .icon-item i {
  vertical-align: middle;
}
.icons-list .icon-item span {
  display: inline-block;
  line-height: 30px;
  vertical-align: middle;
}

/* 19. Tasks */
.board {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.tasks {
  display: inline-block;
  width: 22rem;
  padding: 0 1rem 1rem 1rem;
  margin-bottom: 24px;
  vertical-align: top;
  background-color: #fff;
  border-radius: 0.2rem;
}
.tasks.tasks:not(:last-child) {
  margin-right: 1.25rem;
}
.tasks .card {
  margin-top: 1rem;
  white-space: normal;
}
.tasks .task-header {
  padding: 1rem;
  margin: 0 -1rem;
  background-color: #fff;
  border-radius: 0.2rem;
}

.task-list-items {
  position: relative;
  min-height: 100px;
}
.task-list-items .card {
  cursor: pointer;
}
.task-list-items::before {
  position: absolute;
  width: 100%;
  font-weight: 600;
  line-height: 110px;
  text-align: center;
  content: "No Tasks";
}

.task-modal-content .form-control-light {
  background-color: #fdfdfd !important;
  border-color: #fdfdfd !important;
}

.gantt-task-details {
  min-width: 220px;
}

.task-search .search-input {
  z-index: 10;
  padding-left: 32px;
}
.task-search .icon-search {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 11;
}

.item-dropzone-area {
  padding: 60px;
  margin-bottom: 15px;
  background-color: #f8f9fa !important;
  border: 1px dashed #e2e7f1 !important;
}

@keyframes nodeInserted {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 0.8;
  }
}
.taskList {
  min-height: 40px;
  margin-bottom: 0;
}

.taskList li {
  padding: 20px;
  margin-bottom: 15px;
  background-color: #fff;
  border: 1px solid #e2e7f1;
  border-radius: 3px;
}
.taskList li:focus {
  outline: none !important;
}

.taskList li .btn-sm, .taskList li .btn-group-sm > .btn {
  padding: 2px 8px;
  font-size: 12px;
}

.taskList .checkbox {
  margin-top: 5px;
  margin-left: 20px;
}

/* 20. Email */
.email-container {
  margin-bottom: 24px;
  background-color: #fff;
}
.email-container .inbox-leftbar {
  float: left;
  width: 250px;
  padding: 1.25rem;
  padding-bottom: 184px;
  margin-bottom: -184px;
}
.email-container .inbox-rightbar {
  padding: 0 0 1.5rem 25px;
  margin-left: 250px;
  border-left: 5px solid #edeff4;
}

.message-list {
  display: block;
  padding-left: 0;
}
.message-list li {
  position: relative;
  display: block;
  height: 48px;
  margin-bottom: 1px;
  line-height: 48px;
  cursor: default;
  background: #fff;
  box-shadow: 0 0.05rem 0.01rem rgba(75, 75, 90, 0.075);
  transition-duration: 0.3s;
}
.message-list li a {
  color: #4b4b5a;
}
.message-list li:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  transition-duration: 0.05s;
}
.message-list li .col-mail {
  position: relative;
  float: left;
}
.message-list li .col-mail-1 {
  width: 320px;
}
.message-list li .col-mail-1 .star-toggle,
.message-list li .col-mail-1 .checkbox-wrapper-mail,
.message-list li .col-mail-1 .dot {
  display: block;
  float: left;
}
.message-list li .col-mail-1 .dot {
  width: 0;
  height: 0;
  margin: 22px 26px 0;
  font-size: 0;
  line-height: 0;
  border: 4px solid transparent;
  border-radius: 100px;
}
.message-list li .col-mail-1 .checkbox-wrapper-mail {
  margin: 15px 10px 0 20px;
}
.message-list li .col-mail-1 .star-toggle {
  margin-left: 10px;
  color: #adb5bd;
}
.message-list li .col-mail-1 .title {
  position: absolute;
  top: 0;
  right: 0;
  left: 100px;
  margin-bottom: 0;
  overflow: hidden;
  line-height: 50px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.message-list li .col-mail-2 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 320px;
}
.message-list li .col-mail-2 .subject,
.message-list li .col-mail-2 .date {
  position: absolute;
  top: 0;
}
.message-list li .col-mail-2 .subject {
  right: 110px;
  left: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.message-list li .col-mail-2 .date {
  right: 0;
  width: 100px;
  padding-left: 10px;
}
.message-list li.active,
.message-list li.mail-selected {
  background: #f8f9fa;
  transition-duration: 0.05s;
}
.message-list li.active,
.message-list li.active:hover {
  box-shadow: inset 3px 0 0 #25c2e3;
}
.message-list li.unread a {
  color: #292d32;
}
.message-list .checkbox-wrapper-mail {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: inset 0 0 0 2px #ced4da;
}
.message-list .checkbox-wrapper-mail input {
  cursor: pointer;
  opacity: 0;
}
.message-list .checkbox-wrapper-mail input:checked ~ label {
  opacity: 1;
}
.message-list .checkbox-wrapper-mail label {
  position: absolute;
  top: 3px;
  right: 3px;
  bottom: 3px;
  left: 3px;
  margin-bottom: 0 !important;
  cursor: pointer;
  background: #6c757d;
  opacity: 0;
  transition-duration: 0.05s;
}
.message-list .checkbox-wrapper-mail label:active {
  background: #87949b;
}

.mail-list a {
  display: block;
  padding: 7px 10px;
  color: #4b4b5a;
}

.reply-box {
  border: 2px solid #f8f9fa;
}

@media (max-width: 648px) {
  .email-container .inbox-leftbar {
    float: none;
    width: 100%;
    padding-bottom: 1.25rem;
    margin-bottom: 21px;
  }
  .email-container .inbox-rightbar {
    padding-left: 0;
    margin-left: 0;
    border: 0;
  }
}
@media (max-width: 520px) {
  .inbox-rightbar > .btn-group {
    margin-bottom: 10px;
  }

  .message-list li .col-mail-1 {
    width: 150px;
  }
  .message-list li .col-mail-1 .title {
    left: 80px;
  }
  .message-list li .col-mail-2 {
    left: 160px;
  }
  .message-list li .col-mail-2 .date {
    padding-right: 10px;
    padding-left: 20px;
    text-align: right;
  }
}
.chatbox {
  position: fixed;
  right: 35px;
  bottom: 10px;
  z-index: 99;
  width: 300px;
  background-color: #fff;
  border-radius: 0.3rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.chatbox .chat-input .form-control {
  padding-right: 60px;
}
.chatbox .chat-link {
  position: absolute;
  top: 10px;
  right: 4px;
}

/* 21. Timeline */
/************** Horizontal timeline **************/
.left-timeline .events {
  padding-left: 35px;
  border-left: 3px solid #e2e7f1;
}
.left-timeline .events .event-list {
  position: relative;
}
.left-timeline .events .event-list::before {
  position: absolute;
  top: 6px;
  left: -45px;
  z-index: 9;
  width: 18px;
  height: 18px;
  content: "";
  background-color: #4284f4;
  border: 5px solid #fff;
  border-radius: 50%;
}
.left-timeline .events .event-list::after {
  position: absolute;
  top: 14px;
  left: -38px;
  width: 30px;
  height: 3px;
  content: "";
  background-color: #e2e7f1;
}
.left-timeline .album img {
  width: auto;
  height: 36px;
  border-radius: 4px;
}

.timeline {
  position: relative;
  margin-bottom: 50px;
}
.timeline::before {
  position: absolute;
  top: 30px;
  bottom: 0;
  left: 50%;
  z-index: 0;
  width: 2px;
  content: "";
  background-color: #e2e7f1;
}
.timeline .time-show {
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
}
.timeline .timeline-box {
  position: relative;
  display: block;
  padding: 20px;
}
.timeline .timeline-album {
  margin-top: 12px;
}
.timeline .timeline-album a {
  display: inline-block;
  margin-right: 5px;
}
.timeline .timeline-album img {
  width: auto;
  height: 36px;
  border-radius: 3px;
}

@media (min-width: 768px) {
  .timeline .time-show {
    right: 69px;
    text-align: right;
  }
  .timeline .timeline-box {
    margin-left: 15px;
  }
  .timeline .timeline-icon {
    position: absolute;
    top: 15%;
    left: -24px;
    z-index: 9;
    display: block;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    text-align: center;
    background: #4284f4;
    border: 5px solid #fff;
    border-radius: 50%;
  }
  .timeline .timeline-icon::before {
    position: absolute;
    top: 3px;
    left: 15px;
    width: 19px;
    height: 3px;
    content: "";
    background-color: #e2e7f1;
  }
  .timeline .timeline-desk {
    display: table-cell;
    width: 50%;
    vertical-align: top;
  }

  .timeline-item {
    display: flex;
  }
  .timeline-item::before {
    display: block;
    width: 50%;
    content: "";
  }
  .timeline-item.timeline-item-left::after {
    display: block;
    width: 50%;
    content: "";
  }
  .timeline-item.timeline-item-left .timeline-desk .album {
    float: right;
    margin-top: 20px;
  }
  .timeline-item.timeline-item-left .timeline-desk .album a {
    float: right;
    margin-left: 5px;
  }
  .timeline-item.timeline-item-left .timeline-icon {
    right: -26px;
    left: auto;
  }
  .timeline-item.timeline-item-left .timeline-icon::before {
    right: 15px;
    left: auto;
  }
  .timeline-item.timeline-item-left::before {
    display: none;
  }
  .timeline-item.timeline-item-left .timeline-box {
    margin-right: 15px;
    margin-left: 0;
    text-align: right;
  }
}
@media (max-width: 767.98px) {
  .timeline .time-show {
    position: relative;
    text-align: center;
  }
  .timeline .event-date {
    float: left !important;
    margin-right: 1.5rem;
    margin-left: 0 !important;
  }
  .timeline .timeline-icon {
    display: none;
  }
}
/* 22. Extra Pages */
.card-pricing .card-pricing-features li {
  padding: 10px;
}
.card-pricing .card-pricing-features li::before {
  color: #4284f4;
}

/* 23. Authentication */
body.authentication-bg {
  padding-bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.authentication-form .form-group .input-group-text {
  background-color: transparent;
}
.authentication-form .form-group .input-group-text .icon-dual > svg {
  width: 22px;
  height: 22px;
}

.icon-fb {
  color: #3c5a98;
}

.icon-google {
  color: #e94134;
}

.auth-page-sidebar {
  position: relative;
  height: 100%;
  padding: 0;
  background-image: url("~~~@assets/images/auth-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.auth-page-sidebar .overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex: 1;
  background-color: rgba(0, 0, 0, 0.4);
}
.auth-page-sidebar .auth-user-testimonial {
  position: absolute;
  right: 0;
  bottom: 3rem;
  left: 0;
  padding: 0 1.75rem;
  margin: 0 auto;
  color: #fff;
  text-align: center;
}

/* 24. Icons */
/*
Template Name: Shreyu - Responsive Bootstrap 4 Admin Dashboard
Author: CoderThemes
Version: 1.0.0
Website: https://coderthemes.com/
Contact: support@coderthemes.com
File: Icons Css File
*/
@font-face {
  font-family: "unicons";
  font-style: normal;
  font-weight: normal;
  src: url("~~~@assets/fonts/unicons.eot");
  src: url("~~~@assets/fonts/unicons.eot?2256574#iefix") format("embedded-opentype"), url("~~~@assets/fonts/unicons.woff2?2256574") format("woff2"), url("~~~@assets/fonts/unicons.woff?2256574") format("woff"), url("~~~@assets/fonts/unicons.ttf?2256574") format("truetype"), url("~~~@assets/fonts/unicons.svg?2256574#unicons") format("svg");
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'unicons';
    src: url('../fonts/unicons.svg?2256574#unicons') format('svg');
  }
}
*/
.uil {
  line-height: inherit;
}

[class^=uil-]::before,
[class*=" uil-"]::before {
  display: inline-block;
  width: 1em;
  font-family: "unicons";
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  /* you can be more comfortable with increased icons size */
  font-size: 120%;
  font-style: normal;
  font-weight: normal;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes */
  font-variant: normal;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  text-align: center;
  text-decoration: inherit;
  text-transform: none;
  speak: none;
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.uil-0-plus::before {
  content: "";
}

/* '' */
.uil-10-plus::before {
  content: "";
}

/* '' */
.uil-12-plus::before {
  content: "";
}

/* '' */
.uil-13-plus::before {
  content: "";
}

/* '' */
.uil-16-plus::before {
  content: "";
}

/* '' */
.uil-17-plus::before {
  content: "";
}

/* '' */
.uil-18-plus::before {
  content: "";
}

/* '' */
.uil-21-plus::before {
  content: "";
}

/* '' */
.uil-3-plus::before {
  content: "";
}

/* '' */
.uil-500px::before {
  content: "";
}

/* '' */
.uil-6-plus::before {
  content: "";
}

/* '' */
.uil-abacus::before {
  content: "";
}

/* '' */
.uil-accessible-icon-alt::before {
  content: "";
}

/* '' */
.uil-adjust-alt::before {
  content: "";
}

/* '' */
.uil-adjust-circle::before {
  content: "";
}

/* '' */
.uil-adjust-half::before {
  content: "";
}

/* '' */
.uil-adjust::before {
  content: "";
}

/* '' */
.uil-adobe-alt::before {
  content: "";
}

/* '' */
.uil-adobe::before {
  content: "";
}

/* '' */
.uil-airplay::before {
  content: "";
}

/* '' */
.uil-align-alt::before {
  content: "";
}

/* '' */
.uil-align-center-alt::before {
  content: "";
}

/* '' */
.uil-align-center-h::before {
  content: "";
}

/* '' */
.uil-align-center-justify::before {
  content: "";
}

/* '' */
.uil-align-center-v::before {
  content: "";
}

/* '' */
.uil-align-center::before {
  content: "";
}

/* '' */
.uil-align-justify::before {
  content: "";
}

/* '' */
.uil-align-left-justify::before {
  content: "";
}

/* '' */
.uil-align-left::before {
  content: "";
}

/* '' */
.uil-align-letter-right::before {
  content: "";
}

/* '' */
.uil-align-right-justify::before {
  content: "";
}

/* '' */
.uil-align-right::before {
  content: "";
}

/* '' */
.uil-align::before {
  content: "";
}

/* '' */
.uil-amazon::before {
  content: "";
}

/* '' */
.uil-ambulance::before {
  content: "";
}

/* '' */
.uil-analysis::before {
  content: "";
}

/* '' */
.uil-analytics::before {
  content: "";
}

/* '' */
.uil-anchor::before {
  content: "";
}

/* '' */
.uil-android-alt::before {
  content: "";
}

/* '' */
.uil-android-phone-slash::before {
  content: "";
}

/* '' */
.uil-android::before {
  content: "";
}

/* '' */
.uil-angle-double-down::before {
  content: "";
}

/* '' */
.uil-angle-double-left::before {
  content: "";
}

/* '' */
.uil-angle-double-right::before {
  content: "";
}

/* '' */
.uil-angle-double-up::before {
  content: "";
}

/* '' */
.uil-angle-down::before {
  content: "";
}

/* '' */
.uil-angle-left::before {
  content: "";
}

/* '' */
.uil-angle-right-b::before {
  content: "";
}

/* '' */
.uil-angle-right::before {
  content: "";
}

/* '' */
.uil-angle-up::before {
  content: "";
}

/* '' */
.uil-angry::before {
  content: "";
}

/* '' */
.uil-ankh::before {
  content: "";
}

/* '' */
.uil-annoyed-alt::before {
  content: "";
}

/* '' */
.uil-annoyed::before {
  content: "";
}

/* '' */
.uil-apple-alt::before {
  content: "";
}

/* '' */
.uil-apple::before {
  content: "";
}

/* '' */
.uil-apps::before {
  content: "";
}

/* '' */
.uil-archive-alt::before {
  content: "";
}

/* '' */
.uil-archive::before {
  content: "";
}

/* '' */
.uil-archway::before {
  content: "";
}

/* '' */
.uil-arrow-break::before {
  content: "";
}

/* '' */
.uil-arrow-circle-down::before {
  content: "";
}

/* '' */
.uil-arrow-circle-left::before {
  content: "";
}

/* '' */
.uil-arrow-circle-right::before {
  content: "";
}

/* '' */
.uil-arrow-circle-up::before {
  content: "";
}

/* '' */
.uil-arrow-compress-h::before {
  content: "";
}

/* '' */
.uil-arrow-down-left::before {
  content: "";
}

/* '' */
.uil-arrow-down-right::before {
  content: "";
}

/* '' */
.uil-arrow-down::before {
  content: "";
}

/* '' */
.uil-arrow-from-right::before {
  content: "";
}

/* '' */
.uil-arrow-from-top::before {
  content: "";
}

/* '' */
.uil-arrow-growth::before {
  content: "";
}

/* '' */
.uil-arrow-left::before {
  content: "";
}

/* '' */
.uil-arrow-random::before {
  content: "";
}

/* '' */
.uil-arrow-resize-diagonal::before {
  content: "";
}

/* '' */
.uil-arrow-right::before {
  content: "";
}

/* '' */
.uil-arrow-to-bottom::before {
  content: "";
}

/* '' */
.uil-arrow-to-right::before {
  content: "";
}

/* '' */
.uil-arrow-up-left::before {
  content: "";
}

/* '' */
.uil-arrow-up-right::before {
  content: "";
}

/* '' */
.uil-arrow-up::before {
  content: "";
}

/* '' */
.uil-arrows-h-alt::before {
  content: "";
}

/* '' */
.uil-arrows-h::before {
  content: "";
}

/* '' */
.uil-arrows-left-down::before {
  content: "";
}

/* '' */
.uil-arrows-maximize::before {
  content: "";
}

/* '' */
.uil-arrows-merge::before {
  content: "";
}

/* '' */
.uil-arrows-resize-h::before {
  content: "";
}

/* '' */
.uil-arrows-resize-v::before {
  content: "";
}

/* '' */
.uil-arrows-resize::before {
  content: "";
}

/* '' */
.uil-arrows-right-down::before {
  content: "";
}

/* '' */
.uil-arrows-shrink-h::before {
  content: "";
}

/* '' */
.uil-arrows-shrink-v::before {
  content: "";
}

/* '' */
.uil-arrows-up-right::before {
  content: "";
}

/* '' */
.uil-arrows-v-alt::before {
  content: "";
}

/* '' */
.uil-arrows-v::before {
  content: "";
}

/* '' */
.uil-assistive-listening-systems::before {
  content: "";
}

/* '' */
.uil-asterisk::before {
  content: "";
}

/* '' */
.uil-at::before {
  content: "";
}

/* '' */
.uil-atm-card::before {
  content: "";
}

/* '' */
.uil-atom::before {
  content: "";
}

/* '' */
.uil-auto-flash::before {
  content: "";
}

/* '' */
.uil-award-alt::before {
  content: "";
}

/* '' */
.uil-award::before {
  content: "";
}

/* '' */
.uil-baby-carriage::before {
  content: "";
}

/* '' */
.uil-backpack::before {
  content: "";
}

/* '' */
.uil-backspace::before {
  content: "";
}

/* '' */
.uil-backward::before {
  content: "";
}

/* '' */
.uil-bag-alt::before {
  content: "";
}

/* '' */
.uil-bag-slash::before {
  content: "";
}

/* '' */
.uil-bag::before {
  content: "";
}

/* '' */
.uil-balance-scale::before {
  content: "";
}

/* '' */
.uil-ball::before {
  content: "";
}

/* '' */
.uil-ban::before {
  content: "";
}

/* '' */
.uil-bars::before {
  content: "";
}

/* '' */
.uil-baseball-ball::before {
  content: "";
}

/* '' */
.uil-basketball-hoop::before {
  content: "";
}

/* '' */
.uil-basketball::before {
  content: "";
}

/* '' */
.uil-bath::before {
  content: "";
}

/* '' */
.uil-battery-bolt::before {
  content: "";
}

/* '' */
.uil-battery-empty::before {
  content: "";
}

/* '' */
.uil-bed-double::before {
  content: "";
}

/* '' */
.uil-bed::before {
  content: "";
}

/* '' */
.uil-behance-alt::before {
  content: "";
}

/* '' */
.uil-behance::before {
  content: "";
}

/* '' */
.uil-bell-school::before {
  content: "";
}

/* '' */
.uil-bell-slash::before {
  content: "";
}

/* '' */
.uil-bell::before {
  content: "";
}

/* '' */
.uil-bill::before {
  content: "";
}

/* '' */
.uil-bitcoin-alt::before {
  content: "";
}

/* '' */
.uil-bitcoin-bold::before {
  content: "";
}

/* '' */
.uil-bitcoin-circle::before {
  content: "";
}

/* '' */
.uil-bitcoin::before {
  content: "";
}

/* '' */
.uil-black-berry::before {
  content: "";
}

/* '' */
.uil-blogger-alt::before {
  content: "";
}

/* '' */
.uil-blogger::before {
  content: "";
}

/* '' */
.uil-bluetooth-b::before {
  content: "";
}

/* '' */
.uil-bold::before {
  content: "";
}

/* '' */
.uil-bolt-alt::before {
  content: "";
}

/* '' */
.uil-bolt-slash::before {
  content: "";
}

/* '' */
.uil-bolt::before {
  content: "";
}

/* '' */
.uil-book-alt::before {
  content: "";
}

/* '' */
.uil-book-medical::before {
  content: "";
}

/* '' */
.uil-book-open::before {
  content: "";
}

/* '' */
.uil-book-reader::before {
  content: "";
}

/* '' */
.uil-book::before {
  content: "";
}

/* '' */
.uil-bookmark-full::before {
  content: "";
}

/* '' */
.uil-bookmark::before {
  content: "";
}

/* '' */
.uil-books::before {
  content: "";
}

/* '' */
.uil-boombox::before {
  content: "";
}

/* '' */
.uil-border-alt::before {
  content: "";
}

/* '' */
.uil-border-bottom::before {
  content: "";
}

/* '' */
.uil-border-clear::before {
  content: "";
}

/* '' */
.uil-border-horizontal::before {
  content: "";
}

/* '' */
.uil-border-inner::before {
  content: "";
}

/* '' */
.uil-border-left::before {
  content: "";
}

/* '' */
.uil-border-out::before {
  content: "";
}

/* '' */
.uil-border-right::before {
  content: "";
}

/* '' */
.uil-border-top::before {
  content: "";
}

/* '' */
.uil-border-vertical::before {
  content: "";
}

/* '' */
.uil-bowling-ball::before {
  content: "";
}

/* '' */
.uil-box::before {
  content: "";
}

/* '' */
.uil-briefcase-alt::before {
  content: "";
}

/* '' */
.uil-briefcase::before {
  content: "";
}

/* '' */
.uil-bright::before {
  content: "";
}

/* '' */
.uil-brightness-empty::before {
  content: "";
}

/* '' */
.uil-brightness-half::before {
  content: "";
}

/* '' */
.uil-brightness-low::before {
  content: "";
}

/* '' */
.uil-brightness-minus::before {
  content: "";
}

/* '' */
.uil-brightness-plus::before {
  content: "";
}

/* '' */
.uil-brightness::before {
  content: "";
}

/* '' */
.uil-bring-bottom::before {
  content: "";
}

/* '' */
.uil-bring-front::before {
  content: "";
}

/* '' */
.uil-brush-alt::before {
  content: "";
}

/* '' */
.uil-bug::before {
  content: "";
}

/* '' */
.uil-building::before {
  content: "";
}

/* '' */
.uil-bullseye::before {
  content: "";
}

/* '' */
.uil-bus-alt::before {
  content: "";
}

/* '' */
.uil-bus-school::before {
  content: "";
}

/* '' */
.uil-bus::before {
  content: "";
}

/* '' */
.uil-calcualtor::before {
  content: "";
}

/* '' */
.uil-calculator-alt::before {
  content: "";
}

/* '' */
.uil-calculator::before {
  content: "";
}

/* '' */
.uil-calendar-alt::before {
  content: "";
}

/* '' */
.uil-calendar-slash::before {
  content: "";
}

/* '' */
.uil-calender::before {
  content: "";
}

/* '' */
.uil-calling::before {
  content: "";
}

/* '' */
.uil-camera-change::before {
  content: "";
}

/* '' */
.uil-camera-plus::before {
  content: "";
}

/* '' */
.uil-camera-slash::before {
  content: "";
}

/* '' */
.uil-camera::before {
  content: "";
}

/* '' */
.uil-cancel::before {
  content: "";
}

/* '' */
.uil-capsule::before {
  content: "";
}

/* '' */
.uil-capture::before {
  content: "";
}

/* '' */
.uil-car-sideview::before {
  content: "";
}

/* '' */
.uil-car-slash::before {
  content: "";
}

/* '' */
.uil-car-wash::before {
  content: "";
}

/* '' */
.uil-car::before {
  content: "";
}

/* '' */
.uil-card-atm::before {
  content: "";
}

/* '' */
.uil-caret-right::before {
  content: "";
}

/* '' */
.uil-cart::before {
  content: "";
}

/* '' */
.uil-cell::before {
  content: "";
}

/* '' */
.uil-celsius::before {
  content: "";
}

/* '' */
.uil-chart-bar-alt::before {
  content: "";
}

/* '' */
.uil-chart-bar::before {
  content: "";
}

/* '' */
.uil-chart-down::before {
  content: "";
}

/* '' */
.uil-chart-growth-alt::before {
  content: "";
}

/* '' */
.uil-chart-growth::before {
  content: "";
}

/* '' */
.uil-chart-line::before {
  content: "";
}

/* '' */
.uil-chart-pie-alt::before {
  content: "";
}

/* '' */
.uil-chart-pie::before {
  content: "";
}

/* '' */
.uil-chart::before {
  content: "";
}

/* '' */
.uil-chat-bubble-user::before {
  content: "";
}

/* '' */
.uil-chat-info::before {
  content: "";
}

/* '' */
.uil-chat::before {
  content: "";
}

/* '' */
.uil-check-circle::before {
  content: "";
}

/* '' */
.uil-check-square::before {
  content: "";
}

/* '' */
.uil-check::before {
  content: "";
}

/* '' */
.uil-circle-layer::before {
  content: "";
}

/* '' */
.uil-circle::before {
  content: "";
}

/* '' */
.uil-circuit::before {
  content: "";
}

/* '' */
.uil-clapper-board::before {
  content: "";
}

/* '' */
.uil-clipboard-alt::before {
  content: "";
}

/* '' */
.uil-clipboard-blank::before {
  content: "";
}

/* '' */
.uil-clipboard-notes::before {
  content: "";
}

/* '' */
.uil-clipboard::before {
  content: "";
}

/* '' */
.uil-clock-eight::before {
  content: "";
}

/* '' */
.uil-clock-five::before {
  content: "";
}

/* '' */
.uil-clock-nine::before {
  content: "";
}

/* '' */
.uil-clock-seven::before {
  content: "";
}

/* '' */
.uil-clock-ten::before {
  content: "";
}

/* '' */
.uil-clock-three::before {
  content: "";
}

/* '' */
.uil-clock-two::before {
  content: "";
}

/* '' */
.uil-clock::before {
  content: "";
}

/* '' */
.uil-closed-captioning::before {
  content: "";
}

/* '' */
.uil-cloud-block::before {
  content: "";
}

/* '' */
.uil-cloud-bookmark::before {
  content: "";
}

/* '' */
.uil-cloud-check::before {
  content: "";
}

/* '' */
.uil-cloud-computing::before {
  content: "";
}

/* '' */
.uil-cloud-data-connection::before {
  content: "";
}

/* '' */
.uil-cloud-database-tree::before {
  content: "";
}

/* '' */
.uil-cloud-download::before {
  content: "";
}

/* '' */
.uil-cloud-drizzle::before {
  content: "";
}

/* '' */
.uil-cloud-exclamation::before {
  content: "";
}

/* '' */
.uil-cloud-hail::before {
  content: "";
}

/* '' */
.uil-cloud-heart::before {
  content: "";
}

/* '' */
.uil-cloud-info::before {
  content: "";
}

/* '' */
.uil-cloud-lock::before {
  content: "";
}

/* '' */
.uil-cloud-meatball::before {
  content: "";
}

/* '' */
.uil-cloud-moon-hail::before {
  content: "";
}

/* '' */
.uil-cloud-moon-meatball::before {
  content: "";
}

/* '' */
.uil-cloud-moon-rain::before {
  content: "";
}

/* '' */
.uil-cloud-moon-showers::before {
  content: "";
}

/* '' */
.uil-cloud-moon::before {
  content: "";
}

/* '' */
.uil-cloud-question::before {
  content: "";
}

/* '' */
.uil-cloud-rain-sun::before {
  content: "";
}

/* '' */
.uil-cloud-rain::before {
  content: "";
}

/* '' */
.uil-cloud-redo::before {
  content: "";
}

/* '' */
.uil-cloud-set::before {
  content: "";
}

/* '' */
.uil-cloud-share::before {
  content: "";
}

/* '' */
.uil-cloud-shield::before {
  content: "";
}

/* '' */
.uil-cloud-showers-alt::before {
  content: "";
}

/* '' */
.uil-cloud-showers-heavy::before {
  content: "";
}

/* '' */
.uil-cloud-showers::before {
  content: "";
}

/* '' */
.uil-cloud-slash::before {
  content: "";
}

/* '' */
.uil-cloud-sun-hail::before {
  content: "";
}

/* '' */
.uil-cloud-sun-meatball::before {
  content: "";
}

/* '' */
.uil-cloud-sun-rain-alt::before {
  content: "";
}

/* '' */
.uil-cloud-sun-rain::before {
  content: "";
}

/* '' */
.uil-cloud-sun-tear::before {
  content: "";
}

/* '' */
.uil-cloud-sun::before {
  content: "";
}

/* '' */
.uil-cloud-times::before {
  content: "";
}

/* '' */
.uil-cloud-unlock::before {
  content: "";
}

/* '' */
.uil-cloud-upload::before {
  content: "";
}

/* '' */
.uil-cloud-wifi::before {
  content: "";
}

/* '' */
.uil-cloud-wind::before {
  content: "";
}

/* '' */
.uil-cloud::before {
  content: "";
}

/* '' */
.uil-clouds::before {
  content: "";
}

/* '' */
.uil-club::before {
  content: "";
}

/* '' */
.uil-code::before {
  content: "";
}

/* '' */
.uil-coffee::before {
  content: "";
}

/* '' */
.uil-cog::before {
  content: "";
}

/* '' */
.uil-coins::before {
  content: "";
}

/* '' */
.uil-columns::before {
  content: "";
}

/* '' */
.uil-comment-alt-block::before {
  content: "";
}

/* '' */
.uil-comment-alt-chart-lines::before {
  content: "";
}

/* '' */
.uil-comment-alt-check::before {
  content: "";
}

/* '' */
.uil-comment-alt-dots::before {
  content: "";
}

/* '' */
.uil-comment-alt-download::before {
  content: "";
}

/* '' */
.uil-comment-alt-edit::before {
  content: "";
}

/* '' */
.uil-comment-alt-exclamation::before {
  content: "";
}

/* '' */
.uil-comment-alt-heart::before {
  content: "";
}

/* '' */
.uil-comment-alt-image::before {
  content: "";
}

/* '' */
.uil-comment-alt-info::before {
  content: "";
}

/* '' */
.uil-comment-alt-lines::before {
  content: "";
}

/* '' */
.uil-comment-alt-lock::before {
  content: "";
}

/* '' */
.uil-comment-alt-medical::before {
  content: "";
}

/* '' */
.uil-comment-alt-message::before {
  content: "";
}

/* '' */
.uil-comment-alt-notes::before {
  content: "";
}

/* '' */
.uil-comment-alt-plus::before {
  content: "";
}

/* '' */
.uil-comment-alt-question::before {
  content: "";
}

/* '' */
.uil-comment-alt-redo::before {
  content: "";
}

/* '' */
.uil-comment-alt-search::before {
  content: "";
}

/* '' */
.uil-comment-alt-share::before {
  content: "";
}

/* '' */
.uil-comment-alt-shield::before {
  content: "";
}

/* '' */
.uil-comment-alt-slash::before {
  content: "";
}

/* '' */
.uil-comment-alt-upload::before {
  content: "";
}

/* '' */
.uil-comment-alt-verify::before {
  content: "";
}

/* '' */
.uil-comment-alt::before {
  content: "";
}

/* '' */
.uil-comment-block::before {
  content: "";
}

/* '' */
.uil-comment-chart-line::before {
  content: "";
}

/* '' */
.uil-comment-check::before {
  content: "";
}

/* '' */
.uil-comment-dots::before {
  content: "";
}

/* '' */
.uil-comment-download::before {
  content: "";
}

/* '' */
.uil-comment-edit::before {
  content: "";
}

/* '' */
.uil-comment-exclamation::before {
  content: "";
}

/* '' */
.uil-comment-heart::before {
  content: "";
}

/* '' */
.uil-comment-image::before {
  content: "";
}

/* '' */
.uil-comment-info-alt::before {
  content: "";
}

/* '' */
.uil-comment-info::before {
  content: "";
}

/* '' */
.uil-comment-lines::before {
  content: "";
}

/* '' */
.uil-comment-lock::before {
  content: "";
}

/* '' */
.uil-comment-medical::before {
  content: "";
}

/* '' */
.uil-comment-message::before {
  content: "";
}

/* '' */
.uil-comment-notes::before {
  content: "";
}

/* '' */
.uil-comment-plus::before {
  content: "";
}

/* '' */
.uil-comment-question::before {
  content: "";
}

/* '' */
.uil-comment-redo::before {
  content: "";
}

/* '' */
.uil-comment-search::before {
  content: "";
}

/* '' */
.uil-comment-share::before {
  content: "";
}

/* '' */
.uil-comment-shield::before {
  content: "";
}

/* '' */
.uil-comment-slash::before {
  content: "";
}

/* '' */
.uil-comment-upload::before {
  content: "";
}

/* '' */
.uil-comment-verify::before {
  content: "";
}

/* '' */
.uil-comment::before {
  content: "";
}

/* '' */
.uil-comments-alt::before {
  content: "";
}

/* '' */
.uil-comments::before {
  content: "";
}

/* '' */
.uil-commnet-alt-slash::before {
  content: "";
}

/* '' */
.uil-compact-disc::before {
  content: "";
}

/* '' */
.uil-compass::before {
  content: "";
}

/* '' */
.uil-compress-alt-left::before {
  content: "";
}

/* '' */
.uil-compress-alt::before {
  content: "";
}

/* '' */
.uil-compress-arrows::before {
  content: "";
}

/* '' */
.uil-compress-lines::before {
  content: "";
}

/* '' */
.uil-compress-point::before {
  content: "";
}

/* '' */
.uil-compress-v::before {
  content: "";
}

/* '' */
.uil-compress::before {
  content: "";
}

/* '' */
.uil-computer-mouse::before {
  content: "";
}

/* '' */
.uil-confused::before {
  content: "";
}

/* '' */
.uil-constructor::before {
  content: "";
}

/* '' */
.uil-copy-alt::before {
  content: "";
}

/* '' */
.uil-copy-landscape::before {
  content: "";
}

/* '' */
.uil-copy::before {
  content: "";
}

/* '' */
.uil-copyright::before {
  content: "";
}

/* '' */
.uil-corner-down-left::before {
  content: "";
}

/* '' */
.uil-corner-down-right-alt::before {
  content: "";
}

/* '' */
.uil-corner-down-right::before {
  content: "";
}

/* '' */
.uil-corner-left-down::before {
  content: "";
}

/* '' */
.uil-corner-right-down::before {
  content: "";
}

/* '' */
.uil-corner-up-left-alt::before {
  content: "";
}

/* '' */
.uil-corner-up-left::before {
  content: "";
}

/* '' */
.uil-corner-up-right-alt::before {
  content: "";
}

/* '' */
.uil-corner-up-right::before {
  content: "";
}

/* '' */
.uil-creative-commons-pd-alt::before {
  content: "";
}

/* '' */
.uil-creative-commons-pd::before {
  content: "";
}

/* '' */
.uil-crockery::before {
  content: "";
}

/* '' */
.uil-crop-alt-rotate-left::before {
  content: "";
}

/* '' */
.uil-crop-alt-rotate-right::before {
  content: "";
}

/* '' */
.uil-crop-alt::before {
  content: "";
}

/* '' */
.uil-crosshair-alt::before {
  content: "";
}

/* '' */
.uil-crosshair::before {
  content: "";
}

/* '' */
.uil-crosshairs::before {
  content: "";
}

/* '' */
.uil-cube::before {
  content: "";
}

/* '' */
.uil-dashboard::before {
  content: "";
}

/* '' */
.uil-data-sharing::before {
  content: "";
}

/* '' */
.uil-database-alt::before {
  content: "";
}

/* '' */
.uil-database::before {
  content: "";
}

/* '' */
.uil-desert::before {
  content: "";
}

/* '' */
.uil-desktop-alt-slash::before {
  content: "";
}

/* '' */
.uil-desktop-alt::before {
  content: "";
}

/* '' */
.uil-desktop-cloud-alt::before {
  content: "";
}

/* '' */
.uil-desktop-slash::before {
  content: "";
}

/* '' */
.uil-desktop::before {
  content: "";
}

/* '' */
.uil-dialpad-alt::before {
  content: "";
}

/* '' */
.uil-dialpad::before {
  content: "";
}

/* '' */
.uil-diamond::before {
  content: "";
}

/* '' */
.uil-diary-alt::before {
  content: "";
}

/* '' */
.uil-diary::before {
  content: "";
}

/* '' */
.uil-dice-five::before {
  content: "";
}

/* '' */
.uil-dice-four::before {
  content: "";
}

/* '' */
.uil-dice-one::before {
  content: "";
}

/* '' */
.uil-dice-six::before {
  content: "";
}

/* '' */
.uil-dice-three::before {
  content: "";
}

/* '' */
.uil-dice-two::before {
  content: "";
}

/* '' */
.uil-direction::before {
  content: "";
}

/* '' */
.uil-directions::before {
  content: "";
}

/* '' */
.uil-dizzy-meh::before {
  content: "";
}

/* '' */
.uil-dna::before {
  content: "";
}

/* '' */
.uil-document-layout-center::before {
  content: "";
}

/* '' */
.uil-document-layout-left::before {
  content: "";
}

/* '' */
.uil-document-layout-right::before {
  content: "";
}

/* '' */
.uil-document::before {
  content: "";
}

/* '' */
.uil-dollar-alt::before {
  content: "";
}

/* '' */
.uil-dollar-sign-alt::before {
  content: "";
}

/* '' */
.uil-dollar-sign::before {
  content: "";
}

/* '' */
.uil-down-arrow::before {
  content: "";
}

/* '' */
.uil-download-alt::before {
  content: "";
}

/* '' */
.uil-dribbble::before {
  content: "";
}

/* '' */
.uil-drill::before {
  content: "";
}

/* '' */
.uil-dropbox::before {
  content: "";
}

/* '' */
.uil-dumbbell::before {
  content: "";
}

/* '' */
.uil-ear::before {
  content: "";
}

/* '' */
.uil-edit-alt::before {
  content: "";
}

/* '' */
.uil-edit::before {
  content: "";
}

/* '' */
.uil-ellipsis-h::before {
  content: "";
}

/* '' */
.uil-ellipsis-v::before {
  content: "";
}

/* '' */
.uil-emoji::before {
  content: "";
}

/* '' */
.uil-enter::before {
  content: "";
}

/* '' */
.uil-entry::before {
  content: "";
}

/* '' */
.uil-envelope-add::before {
  content: "";
}

/* '' */
.uil-envelope-alt::before {
  content: "";
}

/* '' */
.uil-envelope-block::before {
  content: "";
}

/* '' */
.uil-envelope-bookmark::before {
  content: "";
}

/* '' */
.uil-envelope-check::before {
  content: "";
}

/* '' */
.uil-envelope-download-alt::before {
  content: "";
}

/* '' */
.uil-envelope-download::before {
  content: "";
}

/* '' */
.uil-envelope-edit::before {
  content: "";
}

/* '' */
.uil-envelope-exclamation::before {
  content: "";
}

/* '' */
.uil-envelope-heart::before {
  content: "";
}

/* '' */
.uil-envelope-info::before {
  content: "";
}

/* '' */
.uil-envelope-lock::before {
  content: "";
}

/* '' */
.uil-envelope-minus::before {
  content: "";
}

/* '' */
.uil-envelope-open::before {
  content: "";
}

/* '' */
.uil-envelope-question::before {
  content: "";
}

/* '' */
.uil-envelope-receive::before {
  content: "";
}

/* '' */
.uil-envelope-redo::before {
  content: "";
}

/* '' */
.uil-envelope-search::before {
  content: "";
}

/* '' */
.uil-envelope-send::before {
  content: "";
}

/* '' */
.uil-envelope-share::before {
  content: "";
}

/* '' */
.uil-envelope-shield::before {
  content: "";
}

/* '' */
.uil-envelope-star::before {
  content: "";
}

/* '' */
.uil-envelope-times::before {
  content: "";
}

/* '' */
.uil-envelope-upload-alt::before {
  content: "";
}

/* '' */
.uil-envelope-upload::before {
  content: "";
}

/* '' */
.uil-envelope::before {
  content: "";
}

/* '' */
.uil-envelopes::before {
  content: "";
}

/* '' */
.uil-equal-circle::before {
  content: "";
}

/* '' */
.uil-euro-circle::before {
  content: "";
}

/* '' */
.uil-euro::before {
  content: "";
}

/* '' */
.uil-exchange-alt::before {
  content: "";
}

/* '' */
.uil-exchange::before {
  content: "";
}

/* '' */
.uil-exclamation-circle::before {
  content: "";
}

/* '' */
.uil-exclamation-octagon::before {
  content: "";
}

/* '' */
.uil-exclamation-triangle::before {
  content: "";
}

/* '' */
.uil-exclude::before {
  content: "";
}

/* '' */
.uil-exit::before {
  content: "";
}

/* '' */
.uil-expand-alt::before {
  content: "";
}

/* '' */
.uil-expand-arrows-alt::before {
  content: "";
}

/* '' */
.uil-expand-arrows::before {
  content: "";
}

/* '' */
.uil-expand-from-corner::before {
  content: "";
}

/* '' */
.uil-expand-left::before {
  content: "";
}

/* '' */
.uil-expand-right::before {
  content: "";
}

/* '' */
.uil-export::before {
  content: "";
}

/* '' */
.uil-exposure-alt::before {
  content: "";
}

/* '' */
.uil-exposure-increase::before {
  content: "";
}

/* '' */
.uil-external-link-alt::before {
  content: "";
}

/* '' */
.uil-eye-slash::before {
  content: "";
}

/* '' */
.uil-eye::before {
  content: "";
}

/* '' */
.uil-facebook-f::before {
  content: "";
}

/* '' */
.uil-facebook-messenger-alt::before {
  content: "";
}

/* '' */
.uil-facebook-messenger::before {
  content: "";
}

/* '' */
.uil-facebook::before {
  content: "";
}

/* '' */
.uil-fahrenheit::before {
  content: "";
}

/* '' */
.uil-fast-mail-alt::before {
  content: "";
}

/* '' */
.uil-fast-mail::before {
  content: "";
}

/* '' */
.uil-favorite::before {
  content: "";
}

/* '' */
.uil-feedback::before {
  content: "";
}

/* '' */
.uil-file-alt::before {
  content: "";
}

/* '' */
.uil-file-blank::before {
  content: "";
}

/* '' */
.uil-file-block-alt::before {
  content: "";
}

/* '' */
.uil-file-bookmark-alt::before {
  content: "";
}

/* '' */
.uil-file-check-alt::before {
  content: "";
}

/* '' */
.uil-file-check::before {
  content: "";
}

/* '' */
.uil-file-contract-dollar::before {
  content: "";
}

/* '' */
.uil-file-copy-alt::before {
  content: "";
}

/* '' */
.uil-file-download-alt::before {
  content: "";
}

/* '' */
.uil-file-download::before {
  content: "";
}

/* '' */
.uil-file-edit-alt::before {
  content: "";
}

/* '' */
.uil-file-exclamation-alt::before {
  content: "";
}

/* '' */
.uil-file-exclamation::before {
  content: "";
}

/* '' */
.uil-file-heart::before {
  content: "";
}

/* '' */
.uil-file-info-alt::before {
  content: "";
}

/* '' */
.uil-file-landscape-alt::before {
  content: "";
}

/* '' */
.uil-file-landscape::before {
  content: "";
}

/* '' */
.uil-file-lanscape-slash::before {
  content: "";
}

/* '' */
.uil-file-lock-alt::before {
  content: "";
}

/* '' */
.uil-file-medical-alt::before {
  content: "";
}

/* '' */
.uil-file-medical::before {
  content: "";
}

/* '' */
.uil-file-minus-alt::before {
  content: "";
}

/* '' */
.uil-file-minus::before {
  content: "";
}

/* '' */
.uil-file-network::before {
  content: "";
}

/* '' */
.uil-file-plus-alt::before {
  content: "";
}

/* '' */
.uil-file-plus::before {
  content: "";
}

/* '' */
.uil-file-question-alt::before {
  content: "";
}

/* '' */
.uil-file-question::before {
  content: "";
}

/* '' */
.uil-file-redo-alt::before {
  content: "";
}

/* '' */
.uil-file-search-alt::before {
  content: "";
}

/* '' */
.uil-file-share-alt::before {
  content: "";
}

/* '' */
.uil-file-shield-alt::before {
  content: "";
}

/* '' */
.uil-file-slash::before {
  content: "";
}

/* '' */
.uil-file-times-alt::before {
  content: "";
}

/* '' */
.uil-file-times::before {
  content: "";
}

/* '' */
.uil-file-upload-alt::before {
  content: "";
}

/* '' */
.uil-file-upload::before {
  content: "";
}

/* '' */
.uil-file::before {
  content: "";
}

/* '' */
.uil-files-landscapes-alt::before {
  content: "";
}

/* '' */
.uil-files-landscapes::before {
  content: "";
}

/* '' */
.uil-film::before {
  content: "";
}

/* '' */
.uil-filter-slash::before {
  content: "";
}

/* '' */
.uil-filter::before {
  content: "";
}

/* '' */
.uil-fire::before {
  content: "";
}

/* '' */
.uil-flask-potion::before {
  content: "";
}

/* '' */
.uil-flask::before {
  content: "";
}

/* '' */
.uil-flip-h-alt::before {
  content: "";
}

/* '' */
.uil-flip-h::before {
  content: "";
}

/* '' */
.uil-flip-v-alt::before {
  content: "";
}

/* '' */
.uil-flip-v::before {
  content: "";
}

/* '' */
.uil-flower::before {
  content: "";
}

/* '' */
.uil-focus-add::before {
  content: "";
}

/* '' */
.uil-focus-target::before {
  content: "";
}

/* '' */
.uil-focus::before {
  content: "";
}

/* '' */
.uil-folder-check::before {
  content: "";
}

/* '' */
.uil-folder-download::before {
  content: "";
}

/* '' */
.uil-folder-exclamation::before {
  content: "";
}

/* '' */
.uil-folder-heart::before {
  content: "";
}

/* '' */
.uil-folder-info::before {
  content: "";
}

/* '' */
.uil-folder-lock::before {
  content: "";
}

/* '' */
.uil-folder-medical::before {
  content: "";
}

/* '' */
.uil-folder-minus::before {
  content: "";
}

/* '' */
.uil-folder-network::before {
  content: "";
}

/* '' */
.uil-folder-plus::before {
  content: "";
}

/* '' */
.uil-folder-question::before {
  content: "";
}

/* '' */
.uil-folder-slash::before {
  content: "";
}

/* '' */
.uil-folder-times::before {
  content: "";
}

/* '' */
.uil-folder-upload::before {
  content: "";
}

/* '' */
.uil-folder::before {
  content: "";
}

/* '' */
.uil-food::before {
  content: "";
}

/* '' */
.uil-football-american::before {
  content: "";
}

/* '' */
.uil-football-ball::before {
  content: "";
}

/* '' */
.uil-football::before {
  content: "";
}

/* '' */
.uil-forecastcloud-moon-tear::before {
  content: "";
}

/* '' */
.uil-forwaded-call::before {
  content: "";
}

/* '' */
.uil-forward::before {
  content: "";
}

/* '' */
.uil-frown::before {
  content: "";
}

/* '' */
.uil-game-structure::before {
  content: "";
}

/* '' */
.uil-game::before {
  content: "";
}

/* '' */
.uil-gift::before {
  content: "";
}

/* '' */
.uil-github-alt::before {
  content: "";
}

/* '' */
.uil-github::before {
  content: "";
}

/* '' */
.uil-glass-martini-alt-slash::before {
  content: "";
}

/* '' */
.uil-glass-martini-alt::before {
  content: "";
}

/* '' */
.uil-glass-martini::before {
  content: "";
}

/* '' */
.uil-glass-tea::before {
  content: "";
}

/* '' */
.uil-glass::before {
  content: "";
}

/* '' */
.uil-globe::before {
  content: "";
}

/* '' */
.uil-gold::before {
  content: "";
}

/* '' */
.uil-google-drive-alt::before {
  content: "";
}

/* '' */
.uil-google-drive::before {
  content: "";
}

/* '' */
.uil-google-hangouts-alt::before {
  content: "";
}

/* '' */
.uil-google-hangouts::before {
  content: "";
}

/* '' */
.uil-google-play::before {
  content: "";
}

/* '' */
.uil-google::before {
  content: "";
}

/* '' */
.uil-graduation-hat::before {
  content: "";
}

/* '' */
.uil-graph-bar::before {
  content: "";
}

/* '' */
.uil-grid::before {
  content: "";
}

/* '' */
.uil-grids::before {
  content: "";
}

/* '' */
.uil-grin-tongue-wink-alt::before {
  content: "";
}

/* '' */
.uil-grin-tongue-wink::before {
  content: "";
}

/* '' */
.uil-grin::before {
  content: "";
}

/* '' */
.uil-grip-horizontal-line::before {
  content: "";
}

/* '' */
.uil-hdd::before {
  content: "";
}

/* '' */
.uil-headphones-alt::before {
  content: "";
}

/* '' */
.uil-headphones::before {
  content: "";
}

/* '' */
.uil-heart-alt::before {
  content: "";
}

/* '' */
.uil-heart-medical::before {
  content: "";
}

/* '' */
.uil-heart-rate::before {
  content: "";
}

/* '' */
.uil-heart-sign::before {
  content: "";
}

/* '' */
.uil-heart::before {
  content: "";
}

/* '' */
.uil-heartbeat::before {
  content: "";
}

/* '' */
.uil-history-alt::before {
  content: "";
}

/* '' */
.uil-history::before {
  content: "";
}

/* '' */
.uil-home-alt::before {
  content: "";
}

/* '' */
.uil-home::before {
  content: "";
}

/* '' */
.uil-horizontal-align-center::before {
  content: "";
}

/* '' */
.uil-horizontal-align-left::before {
  content: "";
}

/* '' */
.uil-horizontal-align-right::before {
  content: "";
}

/* '' */
.uil-horizontal-distribution-center::before {
  content: "";
}

/* '' */
.uil-horizontal-distribution-left::before {
  content: "";
}

/* '' */
.uil-horizontal-distribution-right::before {
  content: "";
}

/* '' */
.uil-hourglass::before {
  content: "";
}

/* '' */
.uil-html3-alt::before {
  content: "";
}

/* '' */
.uil-html3::before {
  content: "";
}

/* '' */
.uil-hunting::before {
  content: "";
}

/* '' */
.uil-image-alt-slash::before {
  content: "";
}

/* '' */
.uil-image-block::before {
  content: "";
}

/* '' */
.uil-image-broken::before {
  content: "";
}

/* '' */
.uil-image-check::before {
  content: "";
}

/* '' */
.uil-image-download::before {
  content: "";
}

/* '' */
.uil-image-edit::before {
  content: "";
}

/* '' */
.uil-image-lock::before {
  content: "";
}

/* '' */
.uil-image-minus::before {
  content: "";
}

/* '' */
.uil-image-plus::before {
  content: "";
}

/* '' */
.uil-image-question::before {
  content: "";
}

/* '' */
.uil-image-redo::before {
  content: "";
}

/* '' */
.uil-image-resize-landscape::before {
  content: "";
}

/* '' */
.uil-image-resize-square::before {
  content: "";
}

/* '' */
.uil-image-search::before {
  content: "";
}

/* '' */
.uil-image-share::before {
  content: "";
}

/* '' */
.uil-image-shield::before {
  content: "";
}

/* '' */
.uil-image-slash::before {
  content: "";
}

/* '' */
.uil-image-times::before {
  content: "";
}

/* '' */
.uil-image-upload::before {
  content: "";
}

/* '' */
.uil-image-v::before {
  content: "";
}

/* '' */
.uil-image::before {
  content: "";
}

/* '' */
.uil-images::before {
  content: "";
}

/* '' */
.uil-import::before {
  content: "";
}

/* '' */
.uil-incoming-call::before {
  content: "";
}

/* '' */
.uil-info-circle::before {
  content: "";
}

/* '' */
.uil-instagram-alt::before {
  content: "";
}

/* '' */
.uil-instagram::before {
  content: "";
}

/* '' */
.uil-intercom-alt::before {
  content: "";
}

/* '' */
.uil-intercom::before {
  content: "";
}

/* '' */
.uil-invoice::before {
  content: "";
}

/* '' */
.uil-italic::before {
  content: "";
}

/* '' */
.uil-jackhammer::before {
  content: "";
}

/* '' */
.uil-java-script::before {
  content: "";
}

/* '' */
.uil-kayak::before {
  content: "";
}

/* '' */
.uil-key-skeleton-alt::before {
  content: "";
}

/* '' */
.uil-key-skeleton::before {
  content: "";
}

/* '' */
.uil-keyboard-alt::before {
  content: "";
}

/* '' */
.uil-keyboard-hide::before {
  content: "";
}

/* '' */
.uil-keyboard-show::before {
  content: "";
}

/* '' */
.uil-keyboard::before {
  content: "";
}

/* '' */
.uil-keyhole-circle::before {
  content: "";
}

/* '' */
.uil-keyhole-square-full::before {
  content: "";
}

/* '' */
.uil-keyhole-square::before {
  content: "";
}

/* '' */
.uil-kid::before {
  content: "";
}

/* '' */
.uil-label-alt::before {
  content: "";
}

/* '' */
.uil-label::before {
  content: "";
}

/* '' */
.uil-lamp::before {
  content: "";
}

/* '' */
.uil-laptop-cloud::before {
  content: "";
}

/* '' */
.uil-laptop::before {
  content: "";
}

/* '' */
.uil-laughing::before {
  content: "";
}

/* '' */
.uil-layer-group-slash::before {
  content: "";
}

/* '' */
.uil-layer-group::before {
  content: "";
}

/* '' */
.uil-layers-alt::before {
  content: "";
}

/* '' */
.uil-layers-slash::before {
  content: "";
}

/* '' */
.uil-layers::before {
  content: "";
}

/* '' */
.uil-left-arrow-from-left::before {
  content: "";
}

/* '' */
.uil-left-arrow-to-left::before {
  content: "";
}

/* '' */
.uil-left-indent-alt::before {
  content: "";
}

/* '' */
.uil-left-indent::before {
  content: "";
}

/* '' */
.uil-left-to-right-text-direction::before {
  content: "";
}

/* '' */
.uil-life-ring::before {
  content: "";
}

/* '' */
.uil-lightbulb-alt::before {
  content: "";
}

/* '' */
.uil-lightbulb::before {
  content: "";
}

/* '' */
.uil-line-alt::before {
  content: "";
}

/* '' */
.uil-line-spacing::before {
  content: "";
}

/* '' */
.uil-line::before {
  content: "";
}

/* '' */
.uil-link-alt::before {
  content: "";
}

/* '' */
.uil-link-broken::before {
  content: "";
}

/* '' */
.uil-link-h::before {
  content: "";
}

/* '' */
.uil-link::before {
  content: "";
}

/* '' */
.uil-linkedin-alt::before {
  content: "";
}

/* '' */
.uil-linkedin::before {
  content: "";
}

/* '' */
.uil-list-ui-alt::before {
  content: "";
}

/* '' */
.uil-list-ul::before {
  content: "";
}

/* '' */
.uil-location-arrow-alt::before {
  content: "";
}

/* '' */
.uil-location-arrow::before {
  content: "";
}

/* '' */
.uil-location-pin-alt::before {
  content: "";
}

/* '' */
.uil-location-point::before {
  content: "";
}

/* '' */
.uil-location::before {
  content: "";
}

/* '' */
.uil-lock-access::before {
  content: "";
}

/* '' */
.uil-lock-alt::before {
  content: "";
}

/* '' */
.uil-lock-open-alt::before {
  content: "";
}

/* '' */
.uil-lock-slash::before {
  content: "";
}

/* '' */
.uil-lock::before {
  content: "";
}

/* '' */
.uil-mailbox-alt::before {
  content: "";
}

/* '' */
.uil-mailbox::before {
  content: "";
}

/* '' */
.uil-map-marker-alt::before {
  content: "";
}

/* '' */
.uil-map-marker-edit::before {
  content: "";
}

/* '' */
.uil-map-marker-info::before {
  content: "";
}

/* '' */
.uil-map-marker-minus::before {
  content: "";
}

/* '' */
.uil-map-marker-plus::before {
  content: "";
}

/* '' */
.uil-map-marker-question::before {
  content: "";
}

/* '' */
.uil-map-marker-shield::before {
  content: "";
}

/* '' */
.uil-map-marker-slash::before {
  content: "";
}

/* '' */
.uil-map-marker::before {
  content: "";
}

/* '' */
.uil-map-pin-alt::before {
  content: "";
}

/* '' */
.uil-map-pin::before {
  content: "";
}

/* '' */
.uil-map::before {
  content: "";
}

/* '' */
.uil-mars::before {
  content: "";
}

/* '' */
.uil-master-card::before {
  content: "";
}

/* '' */
.uil-maximize-left::before {
  content: "";
}

/* '' */
.uil-medal::before {
  content: "";
}

/* '' */
.uil-medical-drip::before {
  content: "";
}

/* '' */
.uil-medical-square-full::before {
  content: "";
}

/* '' */
.uil-medical-square::before {
  content: "";
}

/* '' */
.uil-medical::before {
  content: "";
}

/* '' */
.uil-medium-m::before {
  content: "";
}

/* '' */
.uil-medkit::before {
  content: "";
}

/* '' */
.uil-meeting-board::before {
  content: "";
}

/* '' */
.uil-megaphone::before {
  content: "";
}

/* '' */
.uil-meh-alt::before {
  content: "";
}

/* '' */
.uil-meh-closed-eye::before {
  content: "";
}

/* '' */
.uil-meh::before {
  content: "";
}

/* '' */
.uil-message::before {
  content: "";
}

/* '' */
.uil-metro::before {
  content: "";
}

/* '' */
.uil-microphone-slash::before {
  content: "";
}

/* '' */
.uil-microphone::before {
  content: "";
}

/* '' */
.uil-minus-circle::before {
  content: "";
}

/* '' */
.uil-minus-path::before {
  content: "";
}

/* '' */
.uil-minus-square-full::before {
  content: "";
}

/* '' */
.uil-minus-square::before {
  content: "";
}

/* '' */
.uil-minus::before {
  content: "";
}

/* '' */
.uil-missed-call::before {
  content: "";
}

/* '' */
.uil-mobey-bill-slash::before {
  content: "";
}

/* '' */
.uil-mobile-android-alt::before {
  content: "";
}

/* '' */
.uil-mobile-android::before {
  content: "";
}

/* '' */
.uil-mobile-vibrate::before {
  content: "";
}

/* '' */
.uil-modem::before {
  content: "";
}

/* '' */
.uil-money-bill-stack::before {
  content: "";
}

/* '' */
.uil-money-bill::before {
  content: "";
}

/* '' */
.uil-money-insert::before {
  content: "";
}

/* '' */
.uil-money-stack::before {
  content: "";
}

/* '' */
.uil-money-withdraw::before {
  content: "";
}

/* '' */
.uil-money-withdrawal::before {
  content: "";
}

/* '' */
.uil-moneybag-alt::before {
  content: "";
}

/* '' */
.uil-moneybag::before {
  content: "";
}

/* '' */
.uil-monitor-heart-rate::before {
  content: "";
}

/* '' */
.uil-monitor::before {
  content: "";
}

/* '' */
.uil-moon-eclipse::before {
  content: "";
}

/* '' */
.uil-moon::before {
  content: "";
}

/* '' */
.uil-moonset::before {
  content: "";
}

/* '' */
.uil-mountains-sun::before {
  content: "";
}

/* '' */
.uil-mountains::before {
  content: "";
}

/* '' */
.uil-mouse-alt::before {
  content: "";
}

/* '' */
.uil-mouse::before {
  content: "";
}

/* '' */
.uil-multiply::before {
  content: "";
}

/* '' */
.uil-music-note::before {
  content: "";
}

/* '' */
.uil-music-tune-slash::before {
  content: "";
}

/* '' */
.uil-music::before {
  content: "";
}

/* '' */
.uil-n-a::before {
  content: "";
}

/* '' */
.uil-navigator::before {
  content: "";
}

/* '' */
.uil-nerd::before {
  content: "";
}

/* '' */
.uil-newspaper::before {
  content: "";
}

/* '' */
.uil-ninja::before {
  content: "";
}

/* '' */
.uil-no-entry::before {
  content: "";
}

/* '' */
.uil-notebooks::before {
  content: "";
}

/* '' */
.uil-notes::before {
  content: "";
}

/* '' */
.uil-object-group::before {
  content: "";
}

/* '' */
.uil-object-ungroup::before {
  content: "";
}

/* '' */
.uil-octagon::before {
  content: "";
}

/* '' */
.uil-opera-alt::before {
  content: "";
}

/* '' */
.uil-opera::before {
  content: "";
}

/* '' */
.uil-outgoing-call::before {
  content: "";
}

/* '' */
.uil-package::before {
  content: "";
}

/* '' */
.uil-padlock::before {
  content: "";
}

/* '' */
.uil-paint-tool::before {
  content: "";
}

/* '' */
.uil-palette::before {
  content: "";
}

/* '' */
.uil-panorama-h-alt::before {
  content: "";
}

/* '' */
.uil-panorama-h::before {
  content: "";
}

/* '' */
.uil-panorama-v::before {
  content: "";
}

/* '' */
.uil-paperclip::before {
  content: "";
}

/* '' */
.uil-paragraph::before {
  content: "";
}

/* '' */
.uil-parcel::before {
  content: "";
}

/* '' */
.uil-parking-square::before {
  content: "";
}

/* '' */
.uil-pathfinder-unite::before {
  content: "";
}

/* '' */
.uil-pathfinder::before {
  content: "";
}

/* '' */
.uil-pause-circle::before {
  content: "";
}

/* '' */
.uil-pause::before {
  content: "";
}

/* '' */
.uil-paypal::before {
  content: "";
}

/* '' */
.uil-pen::before {
  content: "";
}

/* '' */
.uil-pentagon::before {
  content: "";
}

/* '' */
.uil-percentage::before {
  content: "";
}

/* '' */
.uil-phone-alt::before {
  content: "";
}

/* '' */
.uil-phone-pause::before {
  content: "";
}

/* '' */
.uil-phone-slash::before {
  content: "";
}

/* '' */
.uil-phone-times::before {
  content: "";
}

/* '' */
.uil-phone-volume::before {
  content: "";
}

/* '' */
.uil-phone::before {
  content: "";
}

/* '' */
.uil-picture::before {
  content: "";
}

/* '' */
.uil-plane-arrival::before {
  content: "";
}

/* '' */
.uil-plane-departure::before {
  content: "";
}

/* '' */
.uil-plane-fly::before {
  content: "";
}

/* '' */
.uil-plane::before {
  content: "";
}

/* '' */
.uil-play-circle::before {
  content: "";
}

/* '' */
.uil-play::before {
  content: "";
}

/* '' */
.uil-plug::before {
  content: "";
}

/* '' */
.uil-plus-circle::before {
  content: "";
}

/* '' */
.uil-plus-square::before {
  content: "";
}

/* '' */
.uil-plus::before {
  content: "";
}

/* '' */
.uil-podium::before {
  content: "";
}

/* '' */
.uil-polygon::before {
  content: "";
}

/* '' */
.uil-post-stamp::before {
  content: "";
}

/* '' */
.uil-postcard::before {
  content: "";
}

/* '' */
.uil-pound-circle::before {
  content: "";
}

/* '' */
.uil-pound::before {
  content: "";
}

/* '' */
.uil-power::before {
  content: "";
}

/* '' */
.uil-prescription-bottle::before {
  content: "";
}

/* '' */
.uil-presentation-check::before {
  content: "";
}

/* '' */
.uil-presentation-edit::before {
  content: "";
}

/* '' */
.uil-presentation-line::before {
  content: "";
}

/* '' */
.uil-presentation-lines-alt::before {
  content: "";
}

/* '' */
.uil-presentation-minus::before {
  content: "";
}

/* '' */
.uil-presentation-play::before {
  content: "";
}

/* '' */
.uil-presentation-plus::before {
  content: "";
}

/* '' */
.uil-presentation-times::before {
  content: "";
}

/* '' */
.uil-presentation::before {
  content: "";
}

/* '' */
.uil-previous::before {
  content: "";
}

/* '' */
.uil-pricetag-alt::before {
  content: "";
}

/* '' */
.uil-print-slash::before {
  content: "";
}

/* '' */
.uil-print::before {
  content: "";
}

/* '' */
.uil-process::before {
  content: "";
}

/* '' */
.uil-processor::before {
  content: "";
}

/* '' */
.uil-pump::before {
  content: "";
}

/* '' */
.uil-puzzle-piece::before {
  content: "";
}

/* '' */
.uil-question-circle::before {
  content: "";
}

/* '' */
.uil-raddit-alien-alt::before {
  content: "";
}

/* '' */
.uil-rainbow::before {
  content: "";
}

/* '' */
.uil-raindrops-alt::before {
  content: "";
}

/* '' */
.uil-raindrops::before {
  content: "";
}

/* '' */
.uil-react::before {
  content: "";
}

/* '' */
.uil-receipt-alt::before {
  content: "";
}

/* '' */
.uil-receipt::before {
  content: "";
}

/* '' */
.uil-record-audio::before {
  content: "";
}

/* '' */
.uil-reddit-alien-alt::before {
  content: "";
}

/* '' */
.uil-redo::before {
  content: "";
}

/* '' */
.uil-refresh::before {
  content: "";
}

/* '' */
.uil-registered::before {
  content: "";
}

/* '' */
.uil-repeat::before {
  content: "";
}

/* '' */
.uil-restaurant::before {
  content: "";
}

/* '' */
.uil-right-indent-alt::before {
  content: "";
}

/* '' */
.uil-right-to-left-text-direction::before {
  content: "";
}

/* '' */
.uil-robot::before {
  content: "";
}

/* '' */
.uil-rope-way::before {
  content: "";
}

/* '' */
.uil-rotate-360::before {
  content: "";
}

/* '' */
.uil-rss-alt::before {
  content: "";
}

/* '' */
.uil-rss-interface::before {
  content: "";
}

/* '' */
.uil-rss::before {
  content: "";
}

/* '' */
.uil-ruler-combined::before {
  content: "";
}

/* '' */
.uil-ruler::before {
  content: "";
}

/* '' */
.uil-sad-cry::before {
  content: "";
}

/* '' */
.uil-sad-crying::before {
  content: "";
}

/* '' */
.uil-sad-dizzy::before {
  content: "";
}

/* '' */
.uil-sad-squint::before {
  content: "";
}

/* '' */
.uil-sad::before {
  content: "";
}

/* '' */
.uil-scaling-left::before {
  content: "";
}

/* '' */
.uil-scaling-right::before {
  content: "";
}

/* '' */
.uil-scenery::before {
  content: "";
}

/* '' */
.uil-schedule::before {
  content: "";
}

/* '' */
.uil-science::before {
  content: "";
}

/* '' */
.uil-screw::before {
  content: "";
}

/* '' */
.uil-scroll-h::before {
  content: "";
}

/* '' */
.uil-scroll::before {
  content: "";
}

/* '' */
.uil-search-alt::before {
  content: "";
}

/* '' */
.uil-search-minus::before {
  content: "";
}

/* '' */
.uil-search-plus::before {
  content: "";
}

/* '' */
.uil-search::before {
  content: "";
}

/* '' */
.uil-selfie::before {
  content: "";
}

/* '' */
.uil-server-alt::before {
  content: "";
}

/* '' */
.uil-server-connection::before {
  content: "";
}

/* '' */
.uil-server-network-alt::before {
  content: "";
}

/* '' */
.uil-server-network::before {
  content: "";
}

/* '' */
.uil-server::before {
  content: "";
}

/* '' */
.uil-servers::before {
  content: "";
}

/* '' */
.uil-servicemark::before {
  content: "";
}

/* '' */
.uil-share-alt::before {
  content: "";
}

/* '' */
.uil-shield-check::before {
  content: "";
}

/* '' */
.uil-shield-exclamation::before {
  content: "";
}

/* '' */
.uil-shield-question::before {
  content: "";
}

/* '' */
.uil-shield-slash::before {
  content: "";
}

/* '' */
.uil-shield::before {
  content: "";
}

/* '' */
.uil-ship::before {
  content: "";
}

/* '' */
.uil-shop::before {
  content: "";
}

/* '' */
.uil-shopping-basket::before {
  content: "";
}

/* '' */
.uil-shopping-cart-alt::before {
  content: "";
}

/* '' */
.uil-shopping-trolley::before {
  content: "";
}

/* '' */
.uil-shovel::before {
  content: "";
}

/* '' */
.uil-shrink::before {
  content: "";
}

/* '' */
.uil-shuffle::before {
  content: "";
}

/* '' */
.uil-shutter-alt::before {
  content: "";
}

/* '' */
.uil-shutter::before {
  content: "";
}

/* '' */
.uil-sick::before {
  content: "";
}

/* '' */
.uil-sigma::before {
  content: "";
}

/* '' */
.uil-sign-alt::before {
  content: "";
}

/* '' */
.uil-sign-in-alt::before {
  content: "";
}

/* '' */
.uil-sign-left::before {
  content: "";
}

/* '' */
.uil-sign-out-alt::before {
  content: "";
}

/* '' */
.uil-sign-right::before {
  content: "";
}

/* '' */
.uil-signal-alt-3::before {
  content: "";
}

/* '' */
.uil-signal-alt::before {
  content: "";
}

/* '' */
.uil-signal::before {
  content: "";
}

/* '' */
.uil-silence::before {
  content: "";
}

/* '' */
.uil-silent-squint::before {
  content: "";
}

/* '' */
.uil-sim-card::before {
  content: "";
}

/* '' */
.uil-sitemap::before {
  content: "";
}

/* '' */
.uil-skip-forward-alt::before {
  content: "";
}

/* '' */
.uil-skip-forward-circle::before {
  content: "";
}

/* '' */
.uil-skip-forward::before {
  content: "";
}

/* '' */
.uil-skype-alt::before {
  content: "";
}

/* '' */
.uil-skype::before {
  content: "";
}

/* '' */
.uil-slack-alt::before {
  content: "";
}

/* '' */
.uil-slack::before {
  content: "";
}

/* '' */
.uil-sliders-v-alt::before {
  content: "";
}

/* '' */
.uil-sliders-v::before {
  content: "";
}

/* '' */
.uil-smile-beam::before {
  content: "";
}

/* '' */
.uil-smile-dizzy::before {
  content: "";
}

/* '' */
.uil-smile-squint-wink-alt::before {
  content: "";
}

/* '' */
.uil-smile-squint-wink::before {
  content: "";
}

/* '' */
.uil-smile-wink-alt::before {
  content: "";
}

/* '' */
.uil-smile-wink::before {
  content: "";
}

/* '' */
.uil-smile::before {
  content: "";
}

/* '' */
.uil-snapchat-alt::before {
  content: "";
}

/* '' */
.uil-snapchat-ghost::before {
  content: "";
}

/* '' */
.uil-snapchat-square::before {
  content: "";
}

/* '' */
.uil-snow-flake::before {
  content: "";
}

/* '' */
.uil-snowflake-alt::before {
  content: "";
}

/* '' */
.uil-snowflake::before {
  content: "";
}

/* '' */
.uil-sort-amount-down::before {
  content: "";
}

/* '' */
.uil-sort-amount-up::before {
  content: "";
}

/* '' */
.uil-sort::before {
  content: "";
}

/* '' */
.uil-sorting::before {
  content: "";
}

/* '' */
.uil-space-key::before {
  content: "";
}

/* '' */
.uil-spade::before {
  content: "";
}

/* '' */
.uil-sperms::before {
  content: "";
}

/* '' */
.uil-spin::before {
  content: "";
}

/* '' */
.uil-sport::before {
  content: "";
}

/* '' */
.uil-square-full::before {
  content: "";
}

/* '' */
.uil-square-shape::before {
  content: "";
}

/* '' */
.uil-square::before {
  content: "";
}

/* '' */
.uil-squint::before {
  content: "";
}

/* '' */
.uil-star-half-alt::before {
  content: "";
}

/* '' */
.uil-star::before {
  content: "";
}

/* '' */
.uil-step-backward-alt::before {
  content: "";
}

/* '' */
.uil-step-backward-circle::before {
  content: "";
}

/* '' */
.uil-step-backward::before {
  content: "";
}

/* '' */
.uil-step-forward::before {
  content: "";
}

/* '' */
.uil-stop-circle::before {
  content: "";
}

/* '' */
.uil-stopwatch-slash::before {
  content: "";
}

/* '' */
.uil-stopwatch::before {
  content: "";
}

/* '' */
.uil-store-alt::before {
  content: "";
}

/* '' */
.uil-store::before {
  content: "";
}

/* '' */
.uil-streering::before {
  content: "";
}

/* '' */
.uil-stretcher::before {
  content: "";
}

/* '' */
.uil-subject::before {
  content: "";
}

/* '' */
.uil-subway-alt::before {
  content: "";
}

/* '' */
.uil-subway::before {
  content: "";
}

/* '' */
.uil-suitcase-alt::before {
  content: "";
}

/* '' */
.uil-suitcase::before {
  content: "";
}

/* '' */
.uil-sun::before {
  content: "";
}

/* '' */
.uil-sunset::before {
  content: "";
}

/* '' */
.uil-surprise::before {
  content: "";
}

/* '' */
.uil-swatchbook::before {
  content: "";
}

/* '' */
.uil-swiggy::before {
  content: "";
}

/* '' */
.uil-swimmer::before {
  content: "";
}

/* '' */
.uil-symbol::before {
  content: "";
}

/* '' */
.uil-sync-exclamation::before {
  content: "";
}

/* '' */
.uil-sync-slash::before {
  content: "";
}

/* '' */
.uil-sync::before {
  content: "";
}

/* '' */
.uil-syringe::before {
  content: "";
}

/* '' */
.uil-table::before {
  content: "";
}

/* '' */
.uil-tablet::before {
  content: "";
}

/* '' */
.uil-tablets::before {
  content: "";
}

/* '' */
.uil-tachometer-fast::before {
  content: "";
}

/* '' */
.uil-tag-alt::before {
  content: "";
}

/* '' */
.uil-tag::before {
  content: "";
}

/* '' */
.uil-tape::before {
  content: "";
}

/* '' */
.uil-taxi::before {
  content: "";
}

/* '' */
.uil-tear::before {
  content: "";
}

/* '' */
.uil-technology::before {
  content: "";
}

/* '' */
.uil-telegram-alt::before {
  content: "";
}

/* '' */
.uil-telegram::before {
  content: "";
}

/* '' */
.uil-telescope::before {
  content: "";
}

/* '' */
.uil-temperature-empty::before {
  content: "";
}

/* '' */
.uil-temperature-half::before {
  content: "";
}

/* '' */
.uil-temperature-minus::before {
  content: "";
}

/* '' */
.uil-temperature-plus::before {
  content: "";
}

/* '' */
.uil-temperature-quarter::before {
  content: "";
}

/* '' */
.uil-temperature-three-quarter::before {
  content: "";
}

/* '' */
.uil-temperature::before {
  content: "";
}

/* '' */
.uil-text-fields::before {
  content: "";
}

/* '' */
.uil-text-size::before {
  content: "";
}

/* '' */
.uil-text-strike-through::before {
  content: "";
}

/* '' */
.uil-text::before {
  content: "";
}

/* '' */
.uil-th-large::before {
  content: "";
}

/* '' */
.uil-th-slash::before {
  content: "";
}

/* '' */
.uil-th::before {
  content: "";
}

/* '' */
.uil-thermometer::before {
  content: "";
}

/* '' */
.uil-thumbs-down::before {
  content: "";
}

/* '' */
.uil-thumbs-up::before {
  content: "";
}

/* '' */
.uil-thunderstorm-moon::before {
  content: "";
}

/* '' */
.uil-thunderstorm-sun::before {
  content: "";
}

/* '' */
.uil-thunderstorm::before {
  content: "";
}

/* '' */
.uil-ticket::before {
  content: "";
}

/* '' */
.uil-times-circle::before {
  content: "";
}

/* '' */
.uil-times-square::before {
  content: "";
}

/* '' */
.uil-times::before {
  content: "";
}

/* '' */
.uil-toggle-off::before {
  content: "";
}

/* '' */
.uil-toggle-on::before {
  content: "";
}

/* '' */
.uil-top-arrow-from-top::before {
  content: "";
}

/* '' */
.uil-top-arrow-to-top::before {
  content: "";
}

/* '' */
.uil-tornado::before {
  content: "";
}

/* '' */
.uil-trademark-circle::before {
  content: "";
}

/* '' */
.uil-trademark::before {
  content: "";
}

/* '' */
.uil-traffic-barrier::before {
  content: "";
}

/* '' */
.uil-trash-alt::before {
  content: "";
}

/* '' */
.uil-trash::before {
  content: "";
}

/* '' */
.uil-trees::before {
  content: "";
}

/* '' */
.uil-triangle::before {
  content: "";
}

/* '' */
.uil-trophy::before {
  content: "";
}

/* '' */
.uil-trowel::before {
  content: "";
}

/* '' */
.uil-truck-case::before {
  content: "";
}

/* '' */
.uil-truck-loading::before {
  content: "";
}

/* '' */
.uil-truck::before {
  content: "";
}

/* '' */
.uil-tumblr-alt::before {
  content: "";
}

/* '' */
.uil-tumblr-square::before {
  content: "";
}

/* '' */
.uil-tumblr::before {
  content: "";
}

/* '' */
.uil-tv-retro-slash::before {
  content: "";
}

/* '' */
.uil-tv-retro::before {
  content: "";
}

/* '' */
.uil-twitter-alt::before {
  content: "";
}

/* '' */
.uil-twitter::before {
  content: "";
}

/* '' */
.uil-umbrella::before {
  content: "";
}

/* '' */
.uil-unamused::before {
  content: "";
}

/* '' */
.uil-underline::before {
  content: "";
}

/* '' */
.uil-university::before {
  content: "";
}

/* '' */
.uil-unlock-alt::before {
  content: "";
}

/* '' */
.uil-unlock::before {
  content: "";
}

/* '' */
.uil-upload-alt::before {
  content: "";
}

/* '' */
.uil-upload::before {
  content: "";
}

/* '' */
.uil-usd-circle::before {
  content: "";
}

/* '' */
.uil-usd-square::before {
  content: "";
}

/* '' */
.uil-user-check::before {
  content: "";
}

/* '' */
.uil-user-circle::before {
  content: "";
}

/* '' */
.uil-user-exclamation::before {
  content: "";
}

/* '' */
.uil-user-hard-hat::before {
  content: "";
}

/* '' */
.uil-user-minus::before {
  content: "";
}

/* '' */
.uil-user-plus::before {
  content: "";
}

/* '' */
.uil-user-square::before {
  content: "";
}

/* '' */
.uil-user-times::before {
  content: "";
}

/* '' */
.uil-user::before {
  content: "";
}

/* '' */
.uil-users-alt::before {
  content: "";
}

/* '' */
.uil-utensils-alt::before {
  content: "";
}

/* '' */
.uil-utensils::before {
  content: "";
}

/* '' */
.uil-vector-square-alt::before {
  content: "";
}

/* '' */
.uil-vector-square::before {
  content: "";
}

/* '' */
.uil-venus::before {
  content: "";
}

/* '' */
.uil-vertical-align-bottom::before {
  content: "";
}

/* '' */
.uil-vertical-align-center::before {
  content: "";
}

/* '' */
.uil-vertical-align-top::before {
  content: "";
}

/* '' */
.uil-vertical-distribute-bottom::before {
  content: "";
}

/* '' */
.uil-vertical-distribution-center::before {
  content: "";
}

/* '' */
.uil-vertical-distribution-top::before {
  content: "";
}

/* '' */
.uil-video-slash::before {
  content: "";
}

/* '' */
.uil-video::before {
  content: "";
}

/* '' */
.uil-visual-studio::before {
  content: "";
}

/* '' */
.uil-vk-alt::before {
  content: "";
}

/* '' */
.uil-vk::before {
  content: "";
}

/* '' */
.uil-voicemail-rectangle::before {
  content: "";
}

/* '' */
.uil-voicemail::before {
  content: "";
}

/* '' */
.uil-volleyball::before {
  content: "";
}

/* '' */
.uil-volume-down::before {
  content: "";
}

/* '' */
.uil-volume-mute::before {
  content: "";
}

/* '' */
.uil-volume-off::before {
  content: "";
}

/* '' */
.uil-volume-up::before {
  content: "";
}

/* '' */
.uil-volume::before {
  content: "";
}

/* '' */
.uil-vuejs-alt::before {
  content: "";
}

/* '' */
.uil-vuejs::before {
  content: "";
}

/* '' */
.uil-wall::before {
  content: "";
}

/* '' */
.uil-wallet::before {
  content: "";
}

/* '' */
.uil-watch-alt::before {
  content: "";
}

/* '' */
.uil-watch::before {
  content: "";
}

/* '' */
.uil-water-drop-slash::before {
  content: "";
}

/* '' */
.uil-water-glass::before {
  content: "";
}

/* '' */
.uil-water::before {
  content: "";
}

/* '' */
.uil-web-grid-alt::before {
  content: "";
}

/* '' */
.uil-web-grid::before {
  content: "";
}

/* '' */
.uil-web-section-alt::before {
  content: "";
}

/* '' */
.uil-web-section::before {
  content: "";
}

/* '' */
.uil-webcam::before {
  content: "";
}

/* '' */
.uil-weight::before {
  content: "";
}

/* '' */
.uil-whatsapp::before {
  content: "";
}

/* '' */
.uil-wheel-barrow::before {
  content: "";
}

/* '' */
.uil-wheelchair-alt::before {
  content: "";
}

/* '' */
.uil-wheelchair::before {
  content: "";
}

/* '' */
.uil-wifi-router::before {
  content: "";
}

/* '' */
.uil-wifi-slash::before {
  content: "";
}

/* '' */
.uil-wifi::before {
  content: "";
}

/* '' */
.uil-wind-moon::before {
  content: "";
}

/* '' */
.uil-wind-sun::before {
  content: "";
}

/* '' */
.uil-wind::before {
  content: "";
}

/* '' */
.uil-window-grid::before {
  content: "";
}

/* '' */
.uil-window-maximize::before {
  content: "";
}

/* '' */
.uil-window-restore::before {
  content: "";
}

/* '' */
.uil-window-section::before {
  content: "";
}

/* '' */
.uil-window::before {
  content: "";
}

/* '' */
.uil-windsock::before {
  content: "";
}

/* '' */
.uil-wrap-text::before {
  content: "";
}

/* '' */
.uil-wrench::before {
  content: "";
}

/* '' */
.uil-yellow::before {
  content: "";
}

/* '' */
.uil-yen-circle::before {
  content: "";
}

/* '' */
.uil-yen::before {
  content: "";
}

/* '' */
.uil-yin-yang::before {
  content: "";
}

/* '' */
.uil-youtube-alt::before {
  content: "";
}

/* '' */
.uil-youtube::before {
  content: "";
}

/* '' */
.custom-switch .custom-control-label {
  cursor: pointer;
}

.vti__dropdown:focus, .vti__dropdown:active {
  outline: none !important;
}

.coupon-section .sidebar-tab-icon, .landing-section .sidebar-tab-icon {
  border: 1px solid #4284f4;
  border-radius: 50%;
}
.coupon-section .template-card, .landing-section .template-card {
  padding-left: 0 !important;
  overflow: auto !important;
  border-color: inherit !important;
  border-left: none !important;
  -webkit-box-shadow: inherit !important;
  box-shadow: none !important;
}
.coupon-section .preview-container .tabs, .landing-section .preview-container .tabs {
  width: 100%;
}
.coupon-section .preview-container .nav-tabs, .landing-section .preview-container .nav-tabs {
  justify-content: center;
}
.coupon-section .preview-container .nav-tabs .nav-item a, .landing-section .preview-container .nav-tabs .nav-item a {
  opacity: 0.6;
}
.coupon-section .preview-container .nav-tabs .nav-item a.active, .landing-section .preview-container .nav-tabs .nav-item a.active {
  background: transparent;
  color: #495057;
  opacity: 1;
}
.coupon-section .preview-container .preview-mockup, .landing-section .preview-container .preview-mockup {
  width: 90%;
  min-height: 600px;
  border: 2px solid #d9dfe6;
  border-radius: 30px;
  position: relative;
  margin: auto;
  margin-bottom: 10px;
}
.coupon-section .preview-container .preview-mockup.mobile, .landing-section .preview-container .preview-mockup.mobile {
  width: 375px;
  min-width: initial;
}
.coupon-section .preview-container .preview-mockup:before, .landing-section .preview-container .preview-mockup:before {
  content: " ";
  border: 2px solid #d9dfe6;
  position: absolute;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  top: -9px;
  left: -10px;
  border-radius: 36px;
}
.coupon-section .preview-container .print-mockup, .landing-section .preview-container .print-mockup {
  width: 90%;
  min-height: 600px;
  border-radius: 30px;
  position: relative;
  margin: auto;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.coupon-section .preview-container .list-devices, .landing-section .preview-container .list-devices {
  text-align: center;
  margin-bottom: 20px;
}
.coupon-section .preview-container .list-devices .device, .landing-section .preview-container .list-devices .device {
  display: inline-block;
  margin: 0px 10px;
  cursor: pointer;
}
.coupon-section .preview-container .list-devices .device.active i, .coupon-section .preview-container .list-devices .device:hover i, .landing-section .preview-container .list-devices .device.active i, .landing-section .preview-container .list-devices .device:hover i {
  opacity: 1;
}
.coupon-section .preview-container .list-devices .device i, .landing-section .preview-container .list-devices .device i {
  font-size: 22px;
  color: rgba(0, 14, 36, 0.87);
  opacity: 0.5;
}

.modal-export-image .modal-body {
  background: #ebebeb;
  display: flex;
  justify-content: center;
}

.left-side-menu-condensed .user-profile {
  padding: 10px 20px;
}

.left-side-menu-condensed .left-side-menu {
  height: calc(100vh - 72px);
  position: fixed;
}
.has-notification .left-side-menu-condensed .left-side-menu {
  height: calc(100vh - 72px - 30px);
}

.left-side-menu-condensed .has-notification .left-side-menu {
  height: calc(100vh - 72px - 30px);
}

.left-side-menu-condensed .has-notification .has-notification .left-side-menu {
  height: calc(100vh - 72px - 30px - 30px);
}

.custom-switch .custom-control-label::before {
  background-color: #ea4335;
  border-color: #ea4335;
}

.custom-switch .custom-control-label::after {
  background-color: #fff;
}

.beta-span {
  font-size: 20px;
  font-weight: bold;
  text-transform: capitalize;
  color: #1e6cf2;
  letter-spacing: 1px;
}

.table-actions-group > .btn {
  border-radius: 0.3rem 0 0 0.3rem;
}
.table-actions-group .dropdown .btn {
  border-radius: 0 0.3rem 0.3rem 0;
}

.cursor-pointer {
  cursor: pointer;
}

.tox-notifications-container {
  display: none;
}

.profile-avatar {
  background-color: #4284f4 !important;
  color: #fff !important;
}

.custom-checkbox-s .custom-checkbox {
  padding-left: 0;
  margin-bottom: 5px;
}
.custom-checkbox-s .custom-control-label:before, .custom-checkbox-s .custom-control-label:after {
  width: 20px;
  height: 20px;
  left: -30px;
  top: 0px;
}
.custom-checkbox-s .custom-control-input {
  width: 20px;
  height: 20px;
  top: -4px;
}

.custom-checkbox-xs .custom-control-label {
  cursor: pointer;
}
.custom-checkbox-xs .custom-control-label:before, .custom-checkbox-xs .custom-control-label:after {
  width: 1rem;
  height: 1rem;
  top: 2px;
}

.custom-radio .custom-control-label {
  cursor: pointer;
}

.left-side-menu-condensed .side-nav-title.two-way svg {
  color: #0F9D58 !important;
  fill: #0F9D58 !important;
}
.left-side-menu-condensed .side-nav-title.two-way.mm-active a {
  border-left-color: #0F9D58 !important;
}

.vue-daterange-picker {
  display: block !important;
}
.vue-daterange-picker .reportrange-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0.45rem 0.75rem;
  border: 0.0625rem solid #e7e7e7;
  height: inherit;
}
.vue-daterange-picker .reportrange-text span {
  padding-left: 10px;
}
.vue-daterange-picker .daterangepicker {
  transform: scale(1);
  opacity: 1;
}
.vue-daterange-picker .daterangepicker .ranges {
  order: 10;
}
.vue-daterange-picker .ranges li.active {
  background-color: #4284f4 !important;
}
.vue-daterange-picker .daterangepicker td.active, .vue-daterange-picker .daterangepicker td.active:hover {
  background-color: #4284f4 !important;
}
.vue-daterange-picker .daterangepicker .btn-primary, .vue-daterange-picker .daterangepicker .btn-success {
  background-color: #4284f4 !important;
}

.textarea-wrapper {
  border: 1px solid #e2e7f1;
  border-radius: 5px;
}
.textarea-wrapper textarea {
  border: none;
}
.textarea-wrapper span.opt-out {
  padding-left: 10px;
  padding-bottom: 2px;
  color: #999;
}
.textarea-wrapper:focus-within {
  border-color: #4284f4;
}

.ql-editor[contenteditable=false] {
  background-color: #e7e7e7;
}

.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #dedede !important;
}

.flag-icon {
  display: inline-block;
  width: 20px;
  height: 15px;
  box-shadow: 0 0 1px 0 #888;
  background-color: #dbdbdb;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #dbdbdb;
}
.flag-icon.us {
  background-image: url("~~~@assets/images/flags/us.jpg");
}
.flag-icon.canada {
  background-image: url("~~~@assets/images/flags/canada.png");
}

.tox-silver-sink {
  display: none !important;
}

.automations-page {
  display: flex;
  flex-direction: row;
}
.automations-page .automation-editor {
  flex: 1 1 auto;
}
.automations-page .automation-editor .trigger-section {
  text-align: center;
}
.automations-page .automation-editor .trigger-section .action-block {
  display: block;
}
.automations-page .automation-editor .trigger-section .new-block.has-blocks {
  padding-bottom: 20px;
}
.automations-page .automation-editor .trigger-section .new-block.has-blocks:before {
  content: " ";
  width: 2px;
  height: 32px;
  background: #9298ad;
  display: block;
  position: absolute;
  bottom: -3px;
  left: calc(50% - 1px);
}
.automations-page .automation-editor .trigger-section .new-block.has-blocks:after {
  content: " ";
  width: 8px;
  height: 8px;
  position: absolute;
  background: #9298ad;
  border-radius: 50%;
  left: calc(50% - 4px);
  bottom: -4px;
  z-index: 2;
}
.automations-page .automation-editor .block-item {
  position: relative;
  text-align: center;
  padding-top: 20px;
  margin-top: -20px;
}
.automations-page .automation-editor .block-item .block-content {
  display: inline-block;
  position: relative;
}
.automations-page .automation-editor .block-item .action-block {
  padding-bottom: 20px;
}
.automations-page .automation-editor .block-item .action-block:before {
  height: 25px;
}
.automations-page .automation-editor .block-item .action-block:after {
  content: " ";
  width: 8px;
  height: 8px;
  position: absolute;
  background: #9298ad;
  border-radius: 50%;
  left: calc(50% - 4px);
  bottom: 17px;
  z-index: 2;
}
.automations-page .automation-editor .block-item .new-block:before {
  content: " ";
  width: 2px;
  height: 32px;
  background: #9298ad;
  display: block;
  position: absolute;
  bottom: -3px;
  left: calc(50% - 1px);
}
.automations-page .automation-editor .block-item .new-block:after {
  bottom: -4px;
}
.automations-page .automation-editor .block-item:hover .block-controls {
  display: inline-block;
}
.automations-page .automation-editor .block-controls {
  display: none;
  position: absolute;
  border: 1px solid #ced3e0;
  padding: 5px 12px;
  border-radius: 5px;
  z-index: 2;
  right: 40px;
  background: #FFF;
  top: -50px;
}
.automations-page .automation-editor .block-controls:before, .automations-page .automation-editor .block-controls:after {
  bottom: -14px;
  left: calc(50% - 6px);
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.automations-page .automation-editor .block-controls:before {
  border-color: rgba(204, 204, 204, 0);
  border-top-color: #ced3e0;
  border-width: 7px;
}
.automations-page .automation-editor .block-controls:after {
  border-color: rgba(136, 183, 213, 0);
  border-top-color: #fff;
  border-width: 7px;
  margin-bottom: 1px;
}
.automations-page .automation-editor .block-controls .btn-action {
  font-size: 18px;
  cursor: pointer;
}
.automations-page .automation-editor .list-blocks .block-item:last-child .action-block:last-child:after, .automations-page .automation-editor .list-blocks .block-item:last-child .action-block:last-child:before {
  display: none;
}
.automations-page .automation-actions {
  width: 300px;
}
.automations-page .action-block {
  text-align: center;
  color: #9298ad;
  padding: 0px 40px 30px;
  position: relative;
}
.automations-page .action-block:before {
  content: " ";
  width: 2px;
  height: 35px;
  background: #9298ad;
  display: block;
  position: absolute;
  bottom: -3px;
  left: calc(50% - 1px);
}
.automations-page .action-block + .action-block {
  padding-top: 0px;
}
.automations-page .action-block + .action-block:before {
  display: none;
}
.automations-page .action-block .common-action {
  user-select: none;
  cursor: pointer;
  color: #1f2129;
  border: 1px solid #9298ad;
  background: #fff;
  padding: 12px 12px;
  min-width: 145px;
  display: inline-block;
  font-weight: 700;
  border-radius: 5px;
}
.automations-page .action-block .common-action.blue {
  background-color: #1e6cf2;
  border-color: #1265f1;
  color: #fff;
}
.automations-page .action-block .common-action.yellow {
  background-color: #e6ce64;
  border-color: #e6ce64;
  color: #fff;
}
.automations-page .action-block .common-action.dark-gray {
  background-color: #4e5468;
  border-color: #4e5468;
  color: #fff;
}
.automations-page .action-block .common-action .sub-label {
  font-weight: normal;
  max-width: 550px;
  padding-top: 10px;
}
.automations-page .action-block .common-action .sub-label b {
  text-transform: uppercase;
}
.automations-page .action-block:hover .block-controls {
  display: inline-block;
}
.automations-page .action-block.if-else-block + .new-block {
  display: none;
}
.automations-page .child-column {
  display: flex;
}
.automations-page .child-column .block-content-column {
  flex: 1 1 50%;
}
.automations-page .child-column .condition-status {
  color: #1265f1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  border: 1px solid #9298ad;
  padding: 12px 12px;
  min-width: 145px;
  display: inline-block;
  font-weight: 700;
  border-radius: 5px;
  margin-bottom: 25px;
}
.automations-page .child-column .condition-status.none {
  background-color: red;
  color: #FFF;
}
.automations-page .child-column .condition-status:after {
  content: " ";
  width: 2px;
  height: 29px;
  background: #9298ad;
  display: block;
  position: absolute;
  bottom: 50px;
  left: calc(50% - 1px);
}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}