.custom-switch {
  .custom-control-label {
    cursor: pointer;
  }
}

.vti__dropdown {
  &:focus,
  &:active {
    outline: none !important;
  }
}

.coupon-section, .landing-section {
  .sidebar-tab-icon {
    border: 1px solid $primary;
    border-radius: 50%;
  }
  .template-card {
    padding-left: 0 !important;
    overflow: auto !important;
    border-color: inherit !important;
    border-left: none !important;
    -webkit-box-shadow: inherit !important;
    box-shadow: none !important;
  }
  .preview-container {
    .tabs {
      width: 100%;
    }
    .nav-tabs {
      justify-content: center;
      .nav-item {
        a {
          opacity: 0.6;
          &.active {
            background: transparent;
            color: #495057;
            opacity: 1;
          }
        }
      }
    }
    .preview-mockup {
      width: 90%;
      min-height: 600px;
      border: 2px solid #d9dfe6;
      border-radius: 30px;
      position: relative;
      margin: auto;
      margin-bottom: 10px;
      &.mobile {
        width: 375px;
        min-width: initial;
      }
      &:before {
        content: ' ';
        border: 2px solid #d9dfe6;
        position: absolute;
        width: calc(100% + 20px);
        height: calc(100% + 20px);
        top: -9px;
        left: -10px;
        border-radius: 36px;
      }
    }
    .print-mockup {
      width: 90%;
      min-height: 600px;
      border-radius: 30px;
      position: relative;
      margin: auto;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .list-devices {
      text-align: center;
      margin-bottom: 20px;
      .device {
        display: inline-block;
        margin: 0px 10px;
        cursor: pointer;
        &.active,
        &:hover {
          i {
            opacity: 1;
          }
        }
        i {
          font-size: 22px;
          color: rgba(0, 14, 36, 0.87);
          opacity: 0.5;
        }
      }
    }
  }
}

.modal-export-image {
  .modal-body {
    background: #ebebeb;
    display: flex;
    justify-content: center;
  }
}

.left-side-menu-condensed .user-profile {
  padding: 10px 20px;
}

.left-side-menu-condensed .left-side-menu {
  height: calc(100vh - #{$topbar-height});
  position: fixed;
  .has-notification & {
    height: calc(100vh - #{$topbar-height} - #{$top-notification-height});
  }
}
.left-side-menu-condensed  .has-notification .left-side-menu {
  height: calc(100vh - #{$topbar-height} - #{$top-notification-height});
}

.left-side-menu-condensed  .has-notification .has-notification  .left-side-menu {
  height: calc(100vh - #{$topbar-height} - #{$top-notification-height} - #{$top-notification-height});
}

.custom-switch .custom-control-label::before {
  background-color: #ea4335;
  border-color: #ea4335;
}
.custom-switch .custom-control-label::after {
  background-color: #fff
}

.beta-span {
  font-size: 20px;
  font-weight: bold;
  text-transform: capitalize;
  color: #1e6cf2;
  letter-spacing: 1px;
}

// .custom-control-input:checked ~ .custom-control-label::before {
//   border-color: #34a853;
//   background-color: #34a853;
// }

.table-actions-group {
  >.btn {
    border-radius: 0.3rem 0 0 0.3rem;
  }
  .dropdown .btn {
    border-radius: 0 0.3rem 0.3rem 0;
  }
}

.cursor-pointer{
  cursor: pointer;
}

.tox-notifications-container
{
  display: none;
}

.profile-avatar {
  background-color: $primary !important;
  color: $white !important;
}

.custom-checkbox-s {
  .custom-checkbox {
    padding-left: 0;
    margin-bottom: 5px;
  }

  .custom-control-label:before, .custom-control-label:after {
    width: 20px;
    height: 20px;
    left: -30px;
    top: 0px;
  }

  .custom-control-input {
    width: 20px;
    height: 20px;
    top: -4px;
  }
}

.custom-checkbox-xs {
  .custom-control-label {
    cursor: pointer;
    &:before, &:after {
      width: 1rem;
      height: 1rem;
      top: 2px;
    }
  }
}

.custom-radio {
  .custom-control-label {
    cursor: pointer;
  }
}

.left-side-menu-condensed .side-nav-title.two-way {
  svg {
    color: #0F9D58 !important;
    fill: #0F9D58 !important;
  }
  &.mm-active {
    a {
      border-left-color: #0F9D58 !important;
    }
  }
}

.vue-daterange-picker {
  display: block !important;

  //make range picker behave as adequate input
  .reportrange-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0.45rem 0.75rem;
    border: .0625rem solid #e7e7e7;
    height: inherit;
    span {
      padding-left: 10px;
    }
  }

  .daterangepicker {
    transform: scale(1);
    opacity: 1;

    //Move ranges list to the right
    .ranges {
      order: 10;
    }
  }
  .ranges li.active {
    background-color: #4284f4!important;
  }
  .daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: #4284f4!important;
  }
  .daterangepicker .btn-primary, .daterangepicker .btn-success {
    background-color: #4284f4!important;
  }
}
.textarea-wrapper {
  border: 1px solid #e2e7f1;
  border-radius: 5px;
  textarea {
    border: none;
  }
  span.opt-out {
    padding-left: 10px;
    padding-bottom: 2px;
    color: #999;
  }
  &:focus-within {
    border-color: #4284f4;
  }
}

.ql-editor[contenteditable=false] {
  background-color: #e7e7e7;
}

.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #dedede !important;
}

.flag-icon {
  display: inline-block;
  width: 20px;
  height: 15px;
  box-shadow: 0 0 1px 0 #888;
  background-color: #dbdbdb;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #dbdbdb;
  &.us {
    background-image: url('~~~@assets/images/flags/us.jpg');
  }
  &.canada {
    background-image: url('~~~@assets/images/flags/canada.png');
  }
}

.tox-silver-sink {
  display: none!important;
}