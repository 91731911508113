




























































































































































































































































































































































































































































































































































.slimscroll-menu {
  height: 100%;
}
.ps > .ps__scrollbar-y-rail {
  width: 8px !important;
  background-color: transparent !important;
}
.ps > .ps__scrollbar-y-rail > .ps__scrollbar-y,
.ps.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail > .ps__scrollbar-y,
.ps > .ps__scrollbar-y-rail:active > .ps__scrollbar-y,
.ps > .ps__scrollbar-y-rail:hover > .ps__scrollbar-y {
  width: 6px !important;
}
.left-side-menu {
  padding-bottom: 10px;
}
.left-side-menu .sidebar-content {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  padding-bottom: 0px;
}
#sidebar-menu {
  flex: 1 1 auto;
  .count-message {
    display: inline-block !important;
    position: absolute;
    background: red;
    right: 0px;
    top: 0;
    color: #FFF;
    z-index: 2;
    padding: 0px 6px;
    border-radius: 5px;
    font-size: 11px;
    font-weight: 600;
    padding-left: 6px !important;
  }
}
